import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";

export default function FinanceWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Office of Deputy Financial Advisor I & II",
      link: "/office-of-deputy-financial-advisor",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Office of Deputy Financial Advisor I & II" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Office of Deputy Financial Advisor I & II</BaseHeading>
              <BaseText fontSize="md">
              Office of Deputy Financial Advisor I & II deals with budgeting, documentation and controlling
                of incoming and outgoing cash flows as well as its actual
                handeling. The wing also focuses on traditional responsibilities
                such as financial accounting and payroll. It also envisages to
                take up some newer roles like management accounting, strategy
                involvement, management information systems, financial analysis
                and the broader range of responsibilities in finance department.
              </BaseText>
            </Flex>
            <WingsAccordion type={"finance"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
