import React, { useEffect } from "react";
import { Flex, Link } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import TabHeader from "../../../components/Common/TabHeader";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import ProceduresAccordion from "../../../components/Procedures/Accordion";
import { getProcedureList } from "../../../utils/API";

import {
  setisClickedOnAskButton
} from "../../../reduxToolkit/slices/galleryImages";

import {  useDispatch } from "react-redux";

function Procedures() {
  const [type, setType] = React.useState("For Business Community");
  const [data1, setData1] = React.useState(null);
  const [data2, setData2] = React.useState(null);
  const dispatch = useDispatch();

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Procedures",
      link: "/procedures",
    },
  ];

  const tabsData = [
    {
      title: "For Business Community",
      type: "For Business Community",
    },
    {
      title: "For Residents of Islamabad",
      type: "For Residents of Islamabad",
    },
  ];

  const getDataForAccordion = async () => {
    const response = await getProcedureList();
    const data = response.data;

    if (data) {
      const businessData = data.filter(
        (item) => item.type === "For Business Community"
      );
      const residentData = data.filter(
        (item) => item.type === "For Residents of Islamabad"
      );

      setData1(businessData);
      setData2(residentData);
    }
  };

  useEffect(() => {
    getDataForAccordion();
  }, []);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Procedures" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <TabHeader tabsData={tabsData} onTabChange={setType} />
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseText>
                Capital Development Authority operates under its standard
                procedures. The procedures listed below are the most frequently
                requested. However, this is not intended to be a complete list
                of operating procedures. These procedures are provided for
                informational purposes only.
              </BaseText>
              <BaseText>
                CDA reserves the right to modify or replace a posted procedure
                or policy at any time without notice. If you do not find any
                procedure here, please{" "}
                <Link 
                  onClick={()=>{dispatch(setisClickedOnAskButton(false))}}
                  href="/contact-us"
                  color="primary"
                  textDecoration="underline"
                >
                  Ask CDA
                </Link>
              </BaseText>
            </Flex>
            <ProceduresAccordion
              type={type}
              data={type === "For Business Community" ? data1 : data2}
            />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}

export default withTranslation()(Procedures);
