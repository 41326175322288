import React, { useEffect } from "react";
import BaseHeading from "../../Base/Heading";
import { Flex, Input, Textarea, Toast } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BaseButton from "../../Base/Button";
import { useState } from "react";
import { askCdaSubmit } from "../../../utils/API";
import "./index.css";

function OnlineQueryForm() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState(false);
  const [body, setBody] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const handleChange = (e, id) => {
    if (id === "name") {
      setFullName(e?.target?.value);
      setBody({ ...body, name: e?.target.value });
    }
    if (id === "email") {
      setEmail(e?.target?.value);
      setBody({ ...body, email: e?.target.value });
    }
    if (id === "query") {
      setMessage(e?.target?.value);
      setBody({ ...body, message: e?.target.value });
    }
    // updateSubmitButtonState();
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setBody({ ...body, phone: value });
    // updateSubmitButtonState();
  };

  const handleSubmit = () => {
    const res = askCdaSubmit(body);
    if (res) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };

  useEffect(() => {
    updateSubmitButtonState();
  }, [fullName, email, phoneNumber]);

  const updateSubmitButtonState = () => {
    setIsSubmitButtonDisabled(() => {
      const isNameEmpty = !fullName.trim();
      const isEmailValid = validateEmail(email);
      const isPhoneNumberEmpty =
        phoneNumber.trim() === "92" || !phoneNumber.trim();
      return isNameEmpty || !isEmailValid || isPhoneNumberEmpty;
    });
  };

  const validateEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Flex flexDirection="column" gap="24px" position="relative">
      <BaseHeading fontSize="lg">Online Form for General Queries</BaseHeading>

      <Flex className="OnlineQueryForm-container">
        <Input
          placeholder="Full Name*"
          type="text"
          id="name"
          border="1px solid var(--chakra-colors-grey2)"
          color="var(--chakra-colors-grey1)"
          _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          onChange={(e) => handleChange(e, "name")}
          value={fullName}
          _focusVisible={{ borderColor: "secondary", boxShadow: "none" }}
          _hover={{ borderColor: "grey1" }}
        />
        <Input
          placeholder="Email Address*"
          type="email"
          id="email"
          onChange={(e) => handleChange(e, "email")}
          value={email}
          border="1px solid var(--chakra-colors-grey2)"
          _focusVisible={{ borderColor: "secondary", boxShadow: "none" }}
          _hover={{ borderColor: "grey1" }}
          color="var(--chakra-colors-grey1)"
          _placeholder={{ color: "var(--chakra-colors-grey1)" }}
        />

        <PhoneInput
          country={"pk"}
          value={phoneNumber}
          onChange={(e) => handlePhoneChange(e)}
          containerClass="containerClass"
          placeholder="Phone*"
          containerStyle={{
            border: "none",
          }}
          inputStyle={{
            border: "1px solid var(--chakra-colors-grey2)",
            width: "100%",
            color: "var(--chakra-colors-grey1)",
            height: "40px",
            borderRadius: "6px",
          }}
          inputClass={"inputClass"}
          buttonStyle={{
            border: "none",
            background: "transparent",
          }}
        />
        <Textarea
          placeholder="Query"
          id="message"
          onChange={(e) => handleChange(e, "query")}
          value={message}
          border="1px solid var(--chakra-colors-grey2)"
          color="var(--chakra-colors-grey1)"
          _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          _focusVisible={{ borderColor: "secondary", boxShadow: "none" }}
          _hover={{ borderColor: "grey1" }}
        />
        {success && (
          <Toast
            title="Query Submitted"
            description="Your query has been submitted successfully"
            status="success"
            isClosable={true}
            onClose={() => setSuccess(false)}
          />
        )}
        <BaseButton
          bgColor="primary"
          color="white"
          width="87px"
          height="40px"
          _hover={{ bgColor: "primary" }}
          onClick={() => handleSubmit()}
          isDisabled={isSubmitButtonDisabled}
        >
          SUBMIT
        </BaseButton>
      </Flex>
    </Flex>
  );
}
export default OnlineQueryForm;
