import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import Projects from "./pages/dataAndResearch/projects";
import BoardMembers from "./pages/aboutus/boardMembers";
import AllHonourableChairman from "./pages/aboutus/AllHonourableChairman";
import CdaBudget from "./pages/dataAndResearch/cdaBudget";
import AboutIslamabad from "./pages/aboutIslamabad/aboutIslamabad";
import Hospitals from "./pages/aboutIslamabad/hospitals";
import Hospitality from "./pages/aboutIslamabad/hospitality";
import BoardMeetings from "./pages/aboutus/boardMeetings";
import HousingSocieties from "./pages/mediaCenter/housingSocieties";
import CdaOrdinance1960 from "./pages/legislation/CdaOrdinance1960";
import LawsandRegulations from "./pages/legislation/lawsandRegulations";
import AuctionNotices from "./pages/mediaCenter/publicNotices/auctionNotices";
import Procedures from "./pages/legislation/Procedures";
import UniversitiesOfIct from "./pages/aboutIslamabad/universitiesOfIct";
import Tenders from "./pages/mediaCenter/procurement/tenders";
import ProcurementPlans from "./pages/mediaCenter/procurement/procurementPlans";
import CdaHistory from "./pages/aboutus/cdaHistory";
import ParksOfIct from "./pages/aboutIslamabad/parksOfIct";
import Adventures from "./pages/aboutIslamabad/adventures";
// import PotohariArtAndCraft from "./pages/aboutIslamabad/potohariArts";
import CdaHospital from "./pages/aboutIslamabad/cdaHospital";
import CdaModelSchool from "./pages/aboutIslamabad/cdaModelSchool";
import FormsAndApplications from "./pages/mediaCenter/formsAndApplications";
import CareersAndJobs from "./pages/mediaCenter/CareersAndJobs";
import NewsReleases from "./pages/mediaCenter/newsReleases";
import OpenAuctionOfPlots from "./pages/mediaCenter/publicNotices/openAuctionOfPlots";
import BallotingSectorC16andI15 from "./pages/mediaCenter/publicNotices/ballotingSectorC-16andI-15";
import IllegalHousingSchemes from "./pages/mediaCenter/publicNotices/illegalHousingSchemes";
import CdaTrainingAcademy from "./pages/aboutus/cdaTrainingAcademy";
// import ParkEnclave from "./pages/dataAndResearch/parkEnclave";
import PageNotFound from "./pages/PageNotFound";
import Gallery from "./pages/mediaCenter/gallery";
import GalleryAllImagesPage from "./pages/GalleryAllImagesPage";
import Faqs from "./pages/mediaCenter/faqs";
import SearchPage from "./pages/searchPage";
import ComplaintManagementSystem from "./pages/complaintManagementSystem";
import PublicNotice from "./pages/mediaCenter/publicNotices/publicNotice";
// import CommercialBuildingProjectStatus from "./pages/dataAndResearch/commercialBuildingProjectStatus";
import ChairmanMessage from "./pages/aboutus/chairmanMessage";
import Career from "./pages/aboutus/careers";
import ContactUs from "./pages/contactUs";
import AdministrationWing from "./pages/wingsAndDirectorates/administration";
import EstateWing from "./pages/wingsAndDirectorates/estate";
import ChairmanSecretariat from "./pages/wingsAndDirectorates/chairman";
import EngineeringWing from "./pages/wingsAndDirectorates/engineering";
import FinanceWing from "./pages/wingsAndDirectorates/finance";
import PlanningAndDesignWing from "./pages/wingsAndDirectorates/planning";
import EnvironmentWing from "./pages/wingsAndDirectorates/environment";
import TechWing from "./pages/wingsAndDirectorates/tech";
import NewsReleaseArticle from "./pages/mediaCenter/newsReleases/newsReleaseArticle";
import SocietyPage from "./pages/mediaCenter/housingSocieties/societyPage";
import PrivateScheme from "./pages/mediaCenter/housingSocieties/privateScheme";
import CdaScheme from "./pages/mediaCenter/housingSocieties/cdaScheme";
import ApplyNow from "./pages/mediaCenter/CareersAndJobs/ApplyNow";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} />
      {/* aboutus */}
      <Route path="board-members" element={<BoardMembers />} />
      <Route path="all-honourable-chairman" element={<AllHonourableChairman />} />
      <Route path="board-meetings" element={<BoardMeetings />} />
      <Route path="cda-training-academy" element={<CdaTrainingAcademy />} />
      <Route path="cda-history" element={<CdaHistory />} />
      <Route path="career" element={<Career />} />

      {/* about islamabad */}
      <Route path="about-islamabad" element={<AboutIslamabad />} />
      <Route path="chairman-cda-message" element={<ChairmanMessage />} />

      <Route path="hospitals" element={<Hospitals />} />
      <Route path="hospitality" element={<Hospitality />} />
      <Route path="universities-of-islamabad" element={<UniversitiesOfIct />} />
      <Route path="destinations-in-islamabad" element={<ParksOfIct />} />
      <Route path="adventures" element={<Adventures />} />
      {/* <Route path="potohari-art-and-craft" element={<PotohariArtAndCraft />} /> */}
      <Route path="cda-hospital" element={<CdaHospital />} />
      <Route path="cda-model-school" element={<CdaModelSchool />} />
      {/* E-Services */}
      <Route
        path="complaint-management-system"
        element={<ComplaintManagementSystem />}
      />
      {/* Legislations */}
      <Route path="procedures" element={<Procedures />} />
      <Route path="cda-ordinance-1960" element={<CdaOrdinance1960 />} />
      <Route path="by-laws-regulations" element={<LawsandRegulations />} />
      {/* media center */}
      <Route path="gallery" element={<Gallery />} />
      <Route path="auction-notices" element={<AuctionNotices />} />
      <Route path="housing-societies" element={<HousingSocieties />} />
      <Route path="housing-societies/:id" element={<SocietyPage />} />
      <Route path="/apply-now" element={<ApplyNow />} />
      <Route
        path="housing-societies/commercial-private/:id"
        element={<PrivateScheme />}
      />
      <Route
        path="housing-societies/commercial-cda/:id"
        element={<CdaScheme />}
      />
      <Route
        path="illegal-housing-schemes"
        element={<IllegalHousingSchemes />}
      />
      <Route
        path="balloting-sector-c16-i15"
        element={<BallotingSectorC16andI15 />}
      />
      <Route path="open-auction-of-plots" element={<OpenAuctionOfPlots />} />
      <Route path="public-notice" element={<PublicNotice />} />
      <Route path="auction-notices" element={<AuctionNotices />} />
      <Route path="news-releases" element={<NewsReleases />} />
      <Route path="/news-releases-article" element={<NewsReleaseArticle />} />
      <Route path="forms-and-applications" element={<FormsAndApplications />} />
      <Route path="careers-and-jobs" element={<CareersAndJobs />} />
      <Route path="tenders" element={<Tenders />} />
      <Route path="procurement-plans" element={<ProcurementPlans />} />

      {/* Data & Research */}
      {/* <Route path="park-enclave" element={<ParkEnclave />} /> */}
      {/* <Route
        path="commercial-building-project-status"
        element={<CommercialBuildingProjectStatus />}
      /> */}
      <Route path="cda-budget" element={<CdaBudget />} />
      <Route path="cda-projects" element={<Projects />} />
      <Route path="galleryImages" element={<GalleryAllImagesPage />} />
      <Route path="faqs" element={<Faqs />} />

      {/* Wings And Directorates */}
      <Route path="/chairman-secretariat" element={<ChairmanSecretariat />} />
      <Route path="/administration-wing" element={<AdministrationWing />} />
      <Route path="/estate-wing" element={<EstateWing />} />
      <Route path="/engineering-wing" element={<EngineeringWing />} />
      <Route path="/office-of-deputy-financial-advisor" element={<FinanceWing />} />
      <Route
        path="/planning-and-design-wing"
        element={<PlanningAndDesignWing />}
      />
      <Route path="/environmental-protection-cell" element={<EnvironmentWing />} />
      <Route path="/tech-wing" element={<TechWing />} />

      {/*Contact Us */}
      <Route path="contact-us" element={<ContactUs />} />

      {/* 404 Not Found */}
      <Route path="*" element={<PageNotFound />} />

      {/* Search page */}
      <Route path="/search" element={<SearchPage />} />
    </>
  )
);
