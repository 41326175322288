import { Box, Flex } from "@chakra-ui/react";
import BaseHeading from "../Base/Heading";
import Card from "../Common/Card";
import "./index.css";

export default function CardSection({ title, data, member, uni, hospital }) {
  return (
    <Box className="hospitalityCardBox">
      {title && <BaseHeading fontSize="xl">{title}</BaseHeading>}

      <Flex
        className="itemCenter"
        rowGap="75px"
        columnGap="23px"
        flexWrap="wrap"
      >
        {data?.map((item, i) => (
          <Card
            key={i}
            data={item}
            member={member}
            uni={uni}
            hospital={hospital}
          />
        ))}
      </Flex>
    </Box>
  );
}
