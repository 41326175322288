import { Flex, Link } from "@chakra-ui/react";
import BaseImage from "../../Base/Image";
import BaseText from "../../Base/Text";

export default function Card({ data, member, uni, hospital }) {
  return (
    <Flex direction="column">
      <BaseImage
        source={
          data?.image_url
            ? data?.image_url
            : data?.image
            ? data?.image
            : member
            ? "noImage.svg"
            : "greyBackground.svg"
        }
        width={member || uni || hospital ? "229px" : ""}
        objectFit={uni ? "contain" : "fill"}
        height={member || uni || hospital ? "270px" : ""}
      />

      <Flex direction="column" gap="3px" paddingTop="16px">
        <BaseText fontSize="md" fontWeight="bold" width="250px">
          {data?.name}
        </BaseText>

        {data?.designation && (
          <BaseText fontSize="sm">{data.designation}</BaseText>
        )}

        <Flex flexDirection="column" gap="4px" paddingTop="8px">
          {data?.email && (
            <BaseText fontSize="sm" color="grey1">
              Email: <a href={`mailto:${data.email}`}>{data.email}</a>
            </BaseText>
          )}
          {data?.primary_phone && (
            <BaseText fontSize="sm" color="grey1">
              Tel:{" "}
              <a href={`tel:${data.primary_phone}`}>{data.primary_phone}</a>
            </BaseText>
          )}
          {data?.secondary_phone && (
            <BaseText fontSize="sm" color="grey1">
              Fax: {data.secondary_phone}
            </BaseText>
          )}
          {data?.address && (
            <BaseText fontSize="sm" color="grey1" width="250px">
              Address: {data.address}
            </BaseText>
          )}
          {data?.website && (
            <BaseText fontSize="sm" color="grey1">
              Website:
              <Link href={data.link} isExternal>
                {data.website}
              </Link>
            </BaseText>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
