import React, { useEffect } from "react";
import "./index.css";
import { Tabs, TabList, Tab } from "@chakra-ui/react";


import { settab } from "../../../reduxToolkit/slices/tabs";
import { useDispatch } from "react-redux";


const TabHeader = ({ tabsData, onTabChange }) => {

  useEffect(()=>{
    if(onTabChange)
     dispatch(settab(tabsData[0].type))
  },[])

  const dispatch = useDispatch();
  return (
    <Tabs
      colorScheme="green"
      onChange={(i) => (onTabChange ? (onTabChange(tabsData[i].type), dispatch(settab(tabsData[i].type))) : "")}
      marginBottom="24px"
      overflowX='auto'
      className="tabsContainer"
    >
      <TabList className="tabList">
        {tabsData.map((tab, index) => (
          <Tab
            key={index}
            color="var(--chakra-colors-grey1)"
            _selected={{
              color: "secondary",
              borderBottom: "2px solid var(--chakra-colors-primary)",
              fontWeight: "bold",
            }}
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>
      {/* <TabPanels>
        {tabsData.map((tab, index) => (
          // <TabPanel key={index} color="secondary" paddingLeft="0px">
          //   {tab.content}
          // </TabPanel>
        ))}
      </TabPanels> */}
    </Tabs>
  );
};

TabHeader.defaultProps = {
  tabsData: [],
};

export default TabHeader;
