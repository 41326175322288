import { withTranslation, useTranslation } from "react-i18next";
import "./index.css";
import { Menu, MenuList, Box } from "@chakra-ui/react";
import MenuItem from "../../Base/MenuItem";
import MenuButton from "../../Base/MenuButton";
import {
  AboutIslamabadRoutes,
  hospitalsRoutes,
  AboutusRoutes,
  EServicesRoutes,
  LegislationRoutes,
  dataReaserchRoutes,
  mediaCenterRoutes,
  publicNoticesRoutes,
  procurementRoutes,
  wingDirectoratesRoutes,
  housingSocietiesSubmenu
} from "../../../utils/constants";

function NavBar() {
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <nav>
      <div style={{ position: "sticky", top: "0" }}>
        <Box className="desktop-header-links">
          <Box
            display="block"
            flexDirection={i18n.language === "ur" ? "row-reverse" : "row"}
            alignItems="center"
            justifyContent="space-between"
            margin="0 auto"
            maxWidth="1440px"
          >
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen" : "menus"}`}
                  >
                    About us
                  </MenuButton>
                  <MenuList>
                    {AboutusRoutes.map((item, i) => (
                      <MenuItem
                        key={i}
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                        padding="0px"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen marginOfMenuItem " : "menus marginOfMenuItem"}`}
                  >
                    Wings & Directorates
                  </MenuButton>
                  <MenuList>
                    {wingDirectoratesRoutes.map((item, i) => (
                      <MenuItem
                        key={i}
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                        padding="0px"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen marginOfMenuItem " : "menus marginOfMenuItem"}`}
                  >
                    E-Services
                  </MenuButton>
                  <MenuList>
                    {EServicesRoutes.map((item, i) => (
                      <MenuItem
                        key={i}
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                        padding="0px"
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>



            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen marginOfMenuItem " : "menus marginOfMenuItem"}`}
                  >
                    Media Center
                  </MenuButton>
                  <MenuList>
                    {mediaCenterRoutes.map((item, i) => {
                      if (item.name === "Public Notices") {
                        return (
                          <Menu key={i}>
                            <MenuButton
                              fontSize="sm"
                              submenu
                              className="subMenus"
                            >
                              {item.name}
                            </MenuButton>
                            <MenuList marginLeft="222px" marginTop="-45px">
                              {publicNoticesRoutes.map((item, i) => (
                                <MenuItem
                                  key={i}
                                  padding="0px"
                                  _focus={{ backgroundColor: "transparent" }}
                                  _hover={{
                                    backgroundColor:
                                      "var(--chakra-colors-grey3)",
                                  }}
                                  link={item.link}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        );
                      }
                      if (item.name === "Procurement") {
                        return (
                          <Menu key={i}>
                            <MenuButton
                              fontSize="sm"
                              submenu
                              className="subMenus"
                            >
                              {item.name}
                            </MenuButton>
                            <MenuList marginLeft="222px" marginTop="-45px">
                              {procurementRoutes.map((item, i) => (
                                <MenuItem
                                  key={i}
                                  padding="0px"
                                  _focus={{ backgroundColor: "transparent" }}
                                  _hover={{
                                    backgroundColor:
                                      "var(--chakra-colors-grey3)",
                                  }}
                                  link={item.link}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        );
                      }

                      if (item.name === "Housing Societies") {
                        return (
                          <Menu key={i}>
                            <MenuButton
                              fontSize="sm"
                              submenu
                              className="subMenus"
                            >
                              {item.name}
                            </MenuButton>
                            <MenuList marginLeft="222px" marginTop="-45px">
                              {housingSocietiesSubmenu.map((item, i) => (
                                <MenuItem
                                  key={i}
                                  padding="0px"
                                  _focus={{ backgroundColor: "transparent" }}
                                  _hover={{
                                    backgroundColor:
                                      "var(--chakra-colors-grey3)",
                                  }}
                                  link={item.link}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        );
                      }
                      return (
                        <MenuItem
                          key={i}
                          padding="0px"
                          _focus={{ backgroundColor: "transparent" }}
                          _hover={{
                            backgroundColor: "var(--chakra-colors-grey3)",
                          }}
                          link={item.link}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen marginOfMenuItem " : "menus marginOfMenuItem"}`}
                  >
                    Legislation
                  </MenuButton>
                  <MenuList>
                    {LegislationRoutes.map((item, i) => (
                      <MenuItem
                        key={i}
                        padding="0px"
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen marginOfMenuItem " : "menus marginOfMenuItem"}`}
                  >
                    Data & Research
                  </MenuButton>
                  <MenuList>
                    {dataReaserchRoutes.map((item, i) => (
                      <MenuItem
                        key={i}
                        padding="0px"
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                        isExternal={item.name === "OPVS"}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>

            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                   className={`${isOpen ? "menus menusOpen" : "menus"}`}
                  >
                    About Islamabad
                  </MenuButton>
                  <MenuList>
                    {AboutIslamabadRoutes.map((item, i) => {

if (item.name === "Hospital of Islamabad") {
  return (
    <Menu key={i}>
      <MenuButton
        fontSize="sm"
        submenu
        className="subMenus"
      >
        {item.name}
      </MenuButton>
      <MenuList marginRight="223px" marginTop="-42px">
        {hospitalsRoutes.map((item, i) => (
          <MenuItem
            key={i}
            padding="0px"
            _focus={{ backgroundColor: "transparent" }}
            _hover={{
              backgroundColor:
                "var(--chakra-colors-grey3)",
            }}
            link={item.link}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

                      
                     return( <MenuItem
                        key={i}
                        padding="0px"
                        _focus={{ backgroundColor: "transparent" }}
                        _hover={{
                          backgroundColor: "var(--chakra-colors-grey3)",
                        }}
                        link={item.link}
                      >
                        {item.name}
                      </MenuItem>)
                        })}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </Box>
      </div>
    </nav>
  );
}

export default withTranslation()(NavBar);