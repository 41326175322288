import React from "react";
import "./style.css";
import BaseText from "../../Base/Text";
import BaseHeading from "../../Base/Heading";
import { Box } from "@chakra-ui/react";

export default function PageHeader({ title, description }) {
  return (
    <Box
      className="headContainer"
      backgroundColor="grey3"
      backgroundImage="url(illustrationPageHeader.svg)"
      backgroundRepeat={"no-repeat"}
      backgroundPosition="center"
    >
      <BaseHeading className="heading-font" fontSize="3xl">
        {title}
      </BaseHeading>

      <BaseText fontSize="md">{description}</BaseText>
    </Box>
  );
}
