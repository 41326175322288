import { withTranslation } from "react-i18next";
import PageHeader from "../../components/Common/PageHeader";
import BreadCrumb from "../../components/Common/Breadcrumb";
import PageWraper from "../../components/Base/PageWraper";
import { Flex } from "@chakra-ui/layout";
import Card from "../../components/ContactUs/Card";
import Map from "../../components/ContactUs/Map";
import GrievanceCommissionerCell from "../../components/ContactUs/grievanceCommissionerCell";
import AskCda from "../../components/ContactUs/AskCda";
import OnlineQueryForm from "../../components/ContactUs/OnlineQueryForm";
import { contactUs } from "../../utils/constants";
import CdaPhoneDirectory from "../../components/ContactUs/CdaPhoneDirectory";
import "./index.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function ContactUs() {
  const location = useLocation();
  const fromFaq = location?.state?.fromFaq;

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Contact Us",
      link: "#",
    },
  ];

  const scrollToFocus = () => {
    const srollVal = document.getElementById("id-focus").offsetTop;
    window.scrollTo({ top: srollVal - 70, behavior: "smooth" });
  };

  useEffect(() => {
    if (fromFaq) {
      scrollToFocus();
    }
  }, [location]);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Contact Us" />

      <PageWraper>
        <Flex flexDirection="column" gap="40px">
          <Flex className="contactUsCardsContainer">
            {contactUs?.map((item, i) => (
              <Card
                key={i}
                title={item?.title}
                location={item?.location}
                time={item?.time}
                day={item?.day}
              />
            ))}
          </Flex>
          <Map heading="CDA Offices location on Map" />
          <CdaPhoneDirectory />
          {/* <GrievanceCommissionerCell /> */}
          <div id="id-focus">
            <AskCda />
          </div>
          <OnlineQueryForm />
        </Flex>
      </PageWraper>
    </>
  );
}

export default withTranslation()(ContactUs);
