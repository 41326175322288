import { Box } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import BaseText from "../../Base/Text";

function Paragraph({ content, ...otherprops }) {
  return (
    <Box {...otherprops}>
      <BaseText fontSize="md" textAlign="justify">
        {content}
      </BaseText>
    </Box>
  );
}

export default withTranslation()(Paragraph);
