import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";

export default function EnvironmentWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Environmental Protection Cell",
      link: "/environmental-protection-cell",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Environmental Protection Cell" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Environmental Protection Cell</BaseHeading>
              <BaseText fontSize="md">
                The major functions of environmental protection cell include protection,
                conservation and management of the city. It also caters for the
                protection and conservation of forests, control of forest fires,
                afforestation of blank areas and development of the entire
                viewpoints and their maintenance.
              </BaseText>
            </Flex>
            <WingsAccordion type={"environment"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
