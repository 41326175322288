import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import BaseText from "../../../components/Base/Text";
import BaseHeading from "../../../components/Base/Heading";
import CustomGallery from "../../../components/Base/CustomGallery";
import { useEffect, useState } from "react";
import BaseLink from "../../../components/Base/Link";
import PageWraper from "../../../components/Base/PageWraper";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import {
  getCoursesTraining,
  getLecturesTraining,
  getProposedTraining,
} from "../../../utils/API";

const imageFileNames = ["002.jpg", "003.jpg", "004.jpg"];

export default function CdaTrainingAcademy() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA Training Academy",
      link: "/cda-training-academy",
    },
  ];
  const [coursesDataLoading, setcoursesDataLoading] = useState(false);
  const [lectureDataLoading, setlectureDataDataLoading] = useState(false);
  const [proposedDataDataLoading, setproposedDataDataLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [lectureData, setLecturesData] = useState([]);
  const [proposedData, setProposedData] = useState([]);
  useEffect(() => {
    const importImages = async () => {
      const imports = await Promise.all(
        imageFileNames.map((fileName) =>
          import(`../../../assets/galleryImages/${fileName}`)
        )
      );

      const urls = imports.map((image, index) => {
        return {
          image: image.default,
          id: index,
          link: image.default,
          name: "Islamabad terrain before development work",
          type: "Islamabad Terrain",
        };
      });
      setImageUrls(urls);
    };

    importImages();
  }, []);

  const fetchCourseData = async () => {
    setcoursesDataLoading(true);
    const course = await getCoursesTraining();
    return course;
  };

  const fetchLectureData = async () => {
    setlectureDataDataLoading(true);
    const lecture = await getLecturesTraining();
    return lecture;
  };

  const fetchProposedData = async () => {
    setproposedDataDataLoading(true);
    const proposed = await getProposedTraining();
    return proposed;
  };

  useEffect(() => {
    fetchCourseData()
      .then((response) =>
        setCoursesData(
          response.map((el, i) => {
            el.id = i + 1 + ".";
            return el;
          })
        )
      )
      .finally(() => setcoursesDataLoading(false));
    fetchLectureData()
      .then((response) =>
        setLecturesData(
          response.map((el, i) => {
            el.id = i + 1 + ".";
            return el;
          })
        )
      )
      .finally(() => setlectureDataDataLoading(false));
    fetchProposedData()
      .then((response) =>
        setProposedData(
          response.map((el, i) => {
            el.id = i + 1 + ".";
            return el;
          })
        )
      )
      .finally(() => setproposedDataDataLoading(false));
  }, []);

  const lecturesKeys = {
    id: "id",
    title: "title",
    period: "period",
  };

  const lecturesHeaders = {
    id: "Sr No.",
    title: "Lecture Title",
    period: "Period",
  };

  const coursesKey = {
    id: "id",
    title: "title",
    period: "period",
  };

  const coursesHeader = {
    id: "Sr No.",
    title: "Lecture Title",
    period: "Period",
  };

  const proposedKey = {
    id: "id",
    title: "title",
    period: "period",
  };

  const proposedHeader = {
    id: "Sr No.",
    title: "Courses",
    period: "Period",
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Training Academy" />

      <PageWraper>
        <Box margin="40px auto">
          <CustomGallery
            images={imageUrls}
            isShowTitleOnImg={true}
            heading={"Islamabad Terrain"}
            title={"Islamabad terrain before development work"}
          />
        </Box>

        <Flex flexDirection="column" gap="24px">
          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">Introduction</BaseHeading>
            <BaseText fontSize="md">trainingIntroduction</BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">Vision</BaseHeading>
            <BaseText fontSize="md">trainingVision</BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">Goal</BaseHeading>
            <BaseText fontSize="md">trainingGoal</BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">Chairman Message</BaseHeading>
            <BaseText fontSize="md">trainingChairmanMessage</BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">
              Executive Director General Message
            </BaseHeading>
            <BaseText fontSize="md">trainingExecutiveMessage</BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="xl">Future Plans:</BaseHeading>
            <BaseText fontSize="md">trainingFuturePlans</BaseText>
            <UnorderedList>
              <Flex direction="column" gap="6px">
                <ListItem>
                  <BaseText fontSize="md">MBA (Management)</BaseText>
                </ListItem>
                <ListItem>
                  <BaseText fontSize="md">
                    MBA (Project Management Management)
                  </BaseText>
                </ListItem>
              </Flex>
            </UnorderedList>
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="48px" marginY="40px">
          <Flex flexDirection="column" gap="32px">
            <BaseHeading fontSize="xl">
              Successfully completed lectures
            </BaseHeading>

            {!lectureDataLoading && (
              <PaginationTable
                loading={false}
                headers={lecturesHeaders}
                dataSet={lectureData}
                keys={lecturesKeys}
                withPagination={false}
              />
            )}
          </Flex>

          <Flex flexDirection="column" gap="32px">
            <BaseHeading fontSize="xl">
              Successfully Completed Training Courses
            </BaseHeading>
            {!coursesDataLoading && (
              <PaginationTable
                loading={false}
                headers={coursesHeader}
                dataSet={coursesData}
                keys={coursesKey}
                withPagination={false}
              />
            )}
          </Flex>

          <Flex flexDirection="column" gap="32px">
            <BaseHeading fontSize="xl">Proposed Courses for 2016</BaseHeading>

            {!proposedDataDataLoading && (
              <PaginationTable
                loading={false}
                headers={proposedHeader}
                dataSet={proposedData}
                keys={proposedKey}
                withPagination={false}
              />
            )}
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="24px">
          <BaseHeading fontSize="xl">Contact us</BaseHeading>
          <Flex flexDirection="column" gap="16px">
            <BaseText fontSize="md">
              3rd Floor, Pak China Center, Islamabad
            </BaseText>
            <BaseLink href="http://www.facebook.com/cda.isb.pk" color="primary">
              CDA Training Academy on Facebook
            </BaseLink>
          </Flex>
        </Flex>
      </PageWraper>
    </>
  );
}
