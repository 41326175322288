// /src/slices/tabsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const changePageSlice = createSlice({
  name: 'changePage',
  initialState: {
    TablepageNo: 1, // Adjusted the naming for clarity
    isLoadTableData: false,
    isClickOnTableLink: false,
    isDataSetOnTable: false,
    isAccordianExpand:false,
    isGalleryPageChange : false,
  },
  reducers: {
    setPageChange: (state, action) => {
      state.TablepageNo = action.payload;
    },
    updateIsLoadTableData:(state, action) => {
        state.isLoadTableData = action.payload;
      },
    setIsClickOnTableLink:(state, action) => {
        state.isClickOnTableLink = action.payload;
      },
    setisDataSetOnTable:(state, action) => {
        state.isDataSetOnTable = action.payload;
      },
    setisAccordianExpand:(state, action) => {
        state.isAccordianExpand = action.payload;
      },
    setisGalleryPageChange:(state, action) => {
        state.isGalleryPageChange = action.payload;
      },
  },
});

export const {  setPageChange, updateIsLoadTableData,setIsClickOnTableLink,setisDataSetOnTable,setisAccordianExpand,setisGalleryPageChange } = changePageSlice.actions;
export const selectPageChange = (state) => state.changePageSlice; // Adjusted the naming here
export default changePageSlice.reducer;
