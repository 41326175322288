import React, { useRef, useEffect } from 'react';

const IntersectionObserverWrapper = ({ children, animationClass, beforeAnimateClass }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(animationClass);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [animationClass]);

  return <div className={beforeAnimateClass} ref={elementRef}>{children}</div>;
};

export default IntersectionObserverWrapper;
