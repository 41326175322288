import { Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import BaseHeading from "../../Base/Heading";
import BaseText from "../../Base/Text";
import Paragraph from "../../Common/Paragraph";

export default function Acadamics() {
  return (
    <Flex gap="24px" flexDirection="column">
      <Flex gap="16px" flexDirection="column">
        <BaseHeading fontSize="xl" marginBottom="8px">
          Academic Calendar
        </BaseHeading>
        <BaseHeading fontSize="lg">Working Schedule</BaseHeading>
        <Paragraph content="For the junior and senior section five working days are observed with Saturdays and Sundays as closed days." />
        <BaseHeading fontSize="md">Summer Schedule (Class I to X):</BaseHeading>
        <Paragraph content="During the summer season, classes from Grade I to X are held from Monday to Tuesday, starting at 8:00 a.m. and concluding at 2:00 p.m. These days constitute full working days, enabling an extensive academic schedule. Futhermore, on Fridays, classes continue from 8:00 a.m. to 12:00 p.m., marking a half working day for students during this season." />
        <BaseHeading fontSize="md">Winter Schedule (Class I to X):</BaseHeading>
        <Paragraph content="In the winter season, the schedule for students in Grade I to X remains consistent with the summer timetable. Classes are conducted from 8:00 a.m. to 2:00 p.m. on full working days (Monday to Tuesday), ensuring a comprehensive learning experience. Similarly, Fridays also maintain a schedule from 8:00 a.m. to 12:00 p.m., making it a half working day for students during the winter months." />
      </Flex>
      <Flex gap="16px" flexDirection="column">
        <BaseHeading fontSize="lg">School Academic Year</BaseHeading>
        <BaseText fontSize="md">
          The school year is divided into two semester:
        </BaseText>
        <UnorderedList fontSize="md" marginTop="-10px">
          <ListItem>1st semester April to October</ListItem>
          <ListItem>2nd semester November to March</ListItem>
          <ListItem>3rd Term January to March</ListItem>
        </UnorderedList>
      </Flex>
    </Flex>
  );
}
