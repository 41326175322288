import { Box, Flex } from "@chakra-ui/react";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import Paragraph from "../../../components/Common/Paragraph";
import BaseText from "../../../components/Base/Text";
import BaseHeading from "../../../components/Base/Heading";
import CustomGallery from "../../../components/Base/CustomGallery";
import { useEffect, useState } from "react";
import PageWraper from "../../../components/Base/PageWraper";

const imageFileNames = ["history-img-1.jpeg", "CDA Office.jpg"];

export default function CdaHistory() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA History",
      link: "/cda-history",
    },
  ];

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const imports = await Promise.all(
        imageFileNames.map((fileName) =>
          import(`../../../assets/galleryImages/${fileName}`)
        )
      );
      const urls = imports.map((image, index) => {
        return {
          image: image.default,
          id: index,
          link: image.default,
          name: "Islamabad terrain before development work",
          type: "Islamabad Terrain",
        };
      });
      setImageUrls(urls);
    };

    importImages();
  }, []);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA History" />

      <PageWraper>
        <Flex flexDirection="column" gap="10px">
          {/* <BaseText fontSize="lg" fontWeight="bold">
            cdaHistoryQuote
          </BaseText> */}
          <Paragraph content="cdaHistory" />
          <Paragraph content="cdaHistory1" />
        </Flex>

        <Box margin="40px auto">
          <BaseHeading fontSize="xl">CDA Historical Pictures</BaseHeading>

          <CustomGallery
            images={imageUrls}
            isShowTitleOnImg={true}
            heading={"Islamabad Terrain"}
            title={"Islamabad terrain before development work"}
          />
        </Box>
      </PageWraper>
    </>
  );
}
