import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import CardSection from "../../../components/CardSection";
import { universities } from "../../../utils/constants";
import PageWraper from "../../../components/Base/PageWraper";

export default function UniversitiesOfIct() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Universities of Islamabad",
      link: "/universities-of-islamabad",
    },
  ];

  // const getUniversitiesData = (members, type) => {
  //   const array = members.filter((item) => item.type === type);
  //   return array;
  // };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Universities of Islamabad" />

      <PageWraper>
        <CardSection
          // title="Public Sector Universities"
          // data={getUniversitiesData(universities, "gov")}
          data={universities}
          uni
        />
        {/* <CardSection
          title="Private Sector Institutions/Universities"
          data={getUniversitiesData(universities, "private")}
          uni
        /> */}
      </PageWraper>
    </>
  );
}
