import React, { useEffect, useState } from "react";
import { Box, Link } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import AccordionComponent from "../../../components/Common/Accordion";
import Separator from "../../../components/Common/Separator";
import BaseHeading from "../../../components/Base/Heading";
import BaseButton from "../../../components/Base/Button";
import "./style.css";
import { getFaqs } from "../../../utils/API";
import CircularLoader from "../../../components/Common/CircularLoader";
import { NavLink } from "react-router-dom";

import {
  setisClickedOnAskButton
} from "../../../reduxToolkit/slices/galleryImages";

import { useSelector, useDispatch } from "react-redux";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, seLoading] = useState(false);


  const isClickedOnAskButton = useSelector(
    (state) => state.gallery.isClickedOnAskButton
  );

  const dispatch = useDispatch();

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "FAQ",
      link: "/Faqs",
    },
  ];

  const fetchData = async () => {
    const data = await getFaqs();
    if (data) {
      seLoading(false);
      setFaqs(data);
    }
  };

  useEffect(() => {
    seLoading(true);
    fetchData();
    isClickedOnAskButton && dispatch(setisClickedOnAskButton(!isClickedOnAskButton))
  }, []);
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader
        title="Frequently Asked Questions"
        description={`Here are some of our most frequently asked questions.`}
      />

      <Box className="mainBox" gap="50px">
        {loading ? (
          <CircularLoader />
        ) : (
          <AccordionComponent faqData={faqs?.data} />
        )}
        <Box className="separatorCss">
          <Separator height="1px" background="var(--chakra-colors-grey3)" />
        </Box>

        <Box className="TitleWithBtn">
          <BaseHeading fontSize={"md"}>
            Can't find an answer to your question?
          </BaseHeading>
          <NavLink
            _hover={{ textDecoration: "none" }}
            to={{ pathname: "/contact-us", fromFaq: true }}
          >
            <BaseButton
              background="primary"
              color="white"
              _hover={{ bgColor: "primary" }}
              onClick={()=>{dispatch(setisClickedOnAskButton(!isClickedOnAskButton))}}
            >
              ASK CDA
            </BaseButton>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};
export default withTranslation()(Faqs);
