import { Table, Thead, Tbody, Tr, Th, Td, Box, Flex } from "@chakra-ui/react";
import "./index.css";
import BaseImage from "../../Base/Image";


export default function DoctorSchedule({ data }) {
  const header = [
    "Departments",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const responsove_Header = ["Departments", "OPD's Day"];
  return (
    <Box className="doctorScheduleContainer">
      <Table
        variant="simple"
        borderRadius="8px"
        className="hideOnDesktopSchedule"
      >
        <Thead bg="grey3" border="1px solid var(--chakra-colors-grey2)">
          <Tr width="100%">
            {responsove_Header.map((i) => (
              <Th
                width="50%"
                color="primary"
                fontSize="md"
                padding="15px 25px"
                border="1px solid var(--chakra-colors-grey2)"
                textAlign="center"
              >
                {i}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((ele) => (
            <Tr width='100%'>
              <Td width='50%' padding="8px 15px" border="1px solid var(--chakra-colors-grey2)">{ele.name}</Td>
              <Td width='50%' padding="8px 15px" border="1px solid var(--chakra-colors-grey2)">
                <Flex flexWrap='wrap' gap='4px' >
              {ele.responsive_Opd_Days?.map((day) =>
<>
<Box borderRadius='4px' padding='5px' background='var(--grey-colors-color-grey-3, #F2F2F2)' color= 'var(--Color---Secondary, #37474F)' font-size= '10.4px' line-height= '17.68px'>
    {day}
      </Box>
</>
)}
</Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Table
        variant="simple"
        borderRadius="8px"
        className="hideOnMobileSchedule"
      >
        <Thead bg="tableHeader" border="1px solid var(--chakra-colors-grey2)">
          <Tr>
            {header.map((i) => (
              <Th
                color="secondary"
                fontSize="md"
                padding="21px 31px 22px 23px"
                // border="1px solid var(--chakra-colors-grey2)"
                textAlign="center"
              >
                {i}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((ele) => (
            <Tr textAlign="center">
              <Td border="1px solid var(--chakra-colors-grey2)">{ele.name}</Td>
              {ele.opdDays?.map((i) =>
                i ? (
                  <Td border="1px solid var(--chakra-colors-grey2)">
                    {/* <Box
                      bg="grey2"
                      width="100px"
                      height="20px"
                      borderRadius="8px"
                    ></Box> */}

                    <BaseImage
                      source="tick-icon.svg"
                      width="33px"
                      height="33px"
                      margin="auto"
                    />
                  </Td>
                ) : (
                  <Td border="1px solid var(--chakra-colors-grey2)"></Td>
                )
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
