import BreadCrumb from "../../components/Common/Breadcrumb";
import PageHeader from "../../components/Common/PageHeader";
import PageWraper from "../../components/Base/PageWraper";

import { withTranslation } from "react-i18next";
import VerticalTabs from "../../components/Common/VerticalTabs";

import ComplaintForm from "../../components/ComplaintForm";
import { Flex } from "@chakra-ui/react";
function ComplaintManagementSystem() {
  const breadcrumbs = [
    { name: "Home", link: "/" },

    {
      name: "Complaint Management System",
      link: "/complaint-management-system",
    },
  ];
  const tabsData = [
    { title: "Register Complaint" },
    { title: "Track Your Complaint" },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Complaint Management System" />
      <PageWraper>
        <Flex gap="90px">
          <VerticalTabs tabsData={tabsData} height="70px" />
          <ComplaintForm />
        </Flex>
      </PageWraper>
    </>
  );
}
export default withTranslation()(ComplaintManagementSystem);
