import React from "react";
import "./style.css";
import { PropTypes } from "prop-types";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function BreadCrumb({ breadcrumbs }) {
  return (
    <Box className="breadcrumbContainer">
      <Breadcrumb
        spacing="8px"
        margin="0 auto"
        maxWidth="1440px"
        separator={<ChevronRightIcon color="grey1" />}
      >
        {breadcrumbs.map((item, index) => {
          return index < breadcrumbs.length - 1 ? (
            <BreadcrumbItem color="primary" fontSize="14px" key={index}>
              <BreadcrumbLink
                _hover={{ textDecoration: "none", cursor: "default" }}
              >
                {item.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem
              isCurrentPage
              color="grey1"
              fontSize="14px"
              key={index}
            >
              <BreadcrumbLink href="#"> {item.name}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Box>
  );
}

BreadCrumb.propTypes = {
  breadcrumbs: PropTypes.array,
};
BreadCrumb.defaultProps = {
  breadcrumbs: [],
};
