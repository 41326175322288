import React, { useEffect, useState } from "react";
// import Pagination from "../../Pagination";
import { Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";
import PdfDownloader from "../../PdfDownloader";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import "./style.css";
import ReactVisibilitySensor from "react-visibility-sensor";
import { stringToHtml } from "../../../../utils/helpers";
import BaseLink from "../../../Base/Link";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import BaseText from "../../../Base/Text";
import {
  updateIsLoadTableData,
  setIsClickOnTableLink,
  setisDataSetOnTable,
} from "../../../../reduxToolkit/slices/changePage";
import { useSelector, useDispatch } from "react-redux";

const renderTableCardComponent = (d, k) => {
  if (k === "subjectTeaching") {
    return `Subject Teaching: ${d[k]}`;
  } else if (k === "lastDegree") {
    return `Last Degree: ${d[k]}`;
  } else if (k === "institutionAttended") {
    return `Institution Attended: ${d[k]}`;
  } else if (k === "bps") {
    return `BPS ${d[k]}`;
  } else if (k === "domicile") {
    return `Domicile: ${d[k]}`;
  } else {
    return stringToHtml(d[k]);
  }
};

function PaginationTable({
  headers,
  headers2,
  keys,
  dataSet,
  downloadableContent = false,
  applyNowEnable = false,
  withPagination = true,
  meetings,
  animateLastCol = false,
  animateSecondLastCol = false,
  newz = false,
  applyNowOnClick = false,
  whiteSpace = false,
  navigation = false,
  viewDetail = false,
  type,
  ...otherprops
}) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const keysSet = keys && Object.keys(keys);
  const headerNames = headers && Object.keys(headers);

  const header2Names = headers2 && Object?.keys(headers2);

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const isClickOnTableLink = useSelector(
    (state) => state.changePage.isClickOnTableLink,
  );

  const isDataSetOnTable = useSelector(
    (state) => state.changePage.isDataSetOnTable,
  );

  const [currentData, setCurrentData] = useState([]);

  const dispatch = useDispatch();
  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    // setPageSize(event.target.value);

    setPageSize(event);
    setActivePage(1);
  };
  const history = useNavigate();

  const handleClick = (headline, description, image) => {
    dispatch(setIsClickOnTableLink(!isClickOnTableLink));
    history("/news-releases-article", {
      state: { headline: headline, description: description, image: image },
    });
  };

  const ApplyNowhandleClick = (id, title, type) => {
    dispatch(setIsClickOnTableLink(!isClickOnTableLink));
    history("/apply-now", {
      state: { id: id, title: title, type: type },
    });
  };

  const handleNavigation = (id, type) => {
    dispatch(setIsClickOnTableLink(!isClickOnTableLink));
    if (type === "Housing") {
      history(`/housing-societies/${id}`, {
        state: { id: id },
      });
    } else if (type === "Private") {
      history(`/housing-societies/commercial-private/${id}`, {
        state: { id: id },
      });
    } else {
      history(`/housing-societies/commercial-cda/${id}`, {
        state: { id: id },
      });
    }
  };

  const renderPdfDownloader = (pdf) => {
    if (pdf?.startsWith("[")) {
      const urlArray = JSON.parse(pdf);
      return (
        <Box className="multi-pdf-conatiner">
          {urlArray?.map((item, index) => (
            <PdfDownloader
              key={index}
              title={meetings ? "Minutes" : "Download"}
              width={"max-content"}
              height={"50.74px"}
              link={item}
            />
          ))}
        </Box>
      );
    }
    return (
      <PdfDownloader
        title={meetings ? "Minutes" : "Download"}
        width={"max-content"}
        height={"50.74px"}
        link={pdf}
      />
    );
  };

  const renderViewDetail = (val) => {
    return (
      <BaseLink fontWeight="bold" color="primary" href={val} isExternal>
        View Detail {">"}
      </BaseLink>
    );
  };

  useEffect(() => {
    setPages(Math.ceil(dataSet?.length / pageSize));
  }, [pageSize, dataSet]);

  useEffect(() => {
    setCurrentData(dataSet);
    dispatch(updateIsLoadTableData(true));
    dispatch(setisDataSetOnTable(!isDataSetOnTable));
  }, [activePage, dataSet, pageSize]);

  return (
    dataSet &&
    dataSet?.length > 0 && (
      <>
        <Box
          borderRadius="8px"
          className="chakraTableCustomClass hideChakraTableOnMobile"
          border="1px solid var(--chakra-colors-grey2)"
          overflow="hidden"
          {...otherprops}
        >
          <Table>
            <Thead bgColor="tableHeader">
              <Tr>
                {headerNames?.map((k, index) =>
                  headers[k] ? (
                    <Th
                      key={index}
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                      textAlign="justify"
                      // borderRight={
                      //   index == headerNames.length - 1
                      //     ? "none"
                      //     : "1px solid var(--chakra-colors-grey2)"
                      // }
                    >
                      {headers[k]}
                    </Th>
                  ) : null,
                )}
              </Tr>
            </Thead>
            <Thead borderY="1px solid var(--chakra-colors-grey2)">
              <Tr>
                {header2Names?.map((k, index) =>
                  headers2[k] ? (
                    <Th
                      key={index}
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                      textAlign="justify"
                      // borderRight={
                      //   index == headerNames.length - 1
                      //     ? "none"
                      //     : "1px solid var(--chakra-colors-grey2)"
                      // }
                    >
                      {headers2[k]}
                    </Th>
                  ) : null,
                )}
              </Tr>
            </Thead>
            <Tbody>
              {currentData ? (
                currentData.map((d, i) => (
                  <Tr
                    onClick={() => navigation && handleNavigation(d.id, type)}
                    key={i}
                    style={currentData?.length === 1 ? { height: "200px" } : {}}
                    bgColor={i % 2 == 0 ? " " : "grey3"}
                    fontWeight={
                      header2Names && currentData[i + 1] == undefined
                        ? "600"
                        : ""
                    }
                  >
                    {keysSet.map((k, i) =>
                      keys[k] ? (
                        <Td
                          key={i}
                          className={
                            (downloadableContent || animateLastCol) &&
                            keysSet[i + 1] === undefined &&
                            d[k] !== undefined
                              ? "PdfTableCell"
                              : "fontSize14-400"
                          }
                          textAlign={
                            // viewDetail &&
                            // keysSet[i + 1] === undefined &&
                            // d[k] !== undefined
                            //   ? "center"
                            // :
                            "justify"
                          }
                          whiteSpace={
                            whiteSpace &&
                            (keysSet[i] == "created_at" ||
                              keysSet[i] == "date" ||
                              keysSet[i] == "tender_due_date") &&
                            d[k] !== undefined
                              ? "nowrap"
                              : ""
                          }
                          borderRight={
                            keysSet[i + 1] === undefined
                              ? ""
                              : "1px solid var(--chakra-colors-grey2)"
                          }
                          cursor={
                            (newz || applyNowOnClick) &&
                            keysSet[i + 1] === undefined
                              ? "pointer"
                              : navigation
                                ? "pointer"
                                : "default"
                          }
                          _hover={{
                            textDecoration: `${
                              newz && keysSet[i + 1] === undefined
                                ? "underline"
                                : "none"
                            }`,
                            color: `${
                              newz && keysSet[i + 1] === undefined ? "blue" : ""
                            }`,
                          }}
                          onClick={() => {
                            newz &&
                              keysSet[i + 1] === undefined &&
                              handleClick(
                                d?.headline,
                                d?.description,
                                d?.picture,
                              );
                            applyNowOnClick &&
                              ApplyNowhandleClick(d?.id, d?.title, d?.type);
                          }}
                        >
                       {/*end of td start   */}




                          {/* keysSet[i + 1] === undefined  is for the last column in row to make it undefined . mean this is for make it last column 
d[k] !== undefined  is check that property and value is exist or not . if exist so it become true . you should put in your key object the right property which exist in APi */}
                          {(downloadableContent ||
                            applyNowEnable ||
                            animateLastCol ||
                            viewDetail) &&
                          keysSet[i + 1] === undefined &&
                          d[k] !== undefined ? (
                            animateLastCol ? (
                              <CountUp
                                end={d[k].slice(0, d[k].indexOf(" "))}
                                suffix={d[k].slice(d[k].indexOf(" "))}
                                start={viewPortEntered ? null : 0}
                              >
                                {({ countUpRef, start }) => (
                                  <ReactVisibilitySensor
                                    active={!viewPortEntered}
                                    onChange={(isVisible) => {
                                      if (isVisible) {
                                        setViewPortEntered(true);
                                      }
                                    }}
                                    delayedCall
                                  >
                                    <span ref={countUpRef} />
                                  </ReactVisibilitySensor>
                                )}
                              </CountUp>
                            ) : downloadableContent ? (
                              renderPdfDownloader(d[k])
                            ) : applyNowEnable ? (
                              <>
                                <Flex alignItems="center" gap="8px">
                                  <BaseText fontSize="md" fontWeight="bold" color="primary">
                                    APPLY NOW
                                  </BaseText>
                                  <ArrowForwardIcon
                                    h={5}
                                    w={5}
                                    backgroundColor="transparent"
                                    _hover=""
                                    color="var(--chakra-colors-primary)"
                                  />
                                </Flex>
                              </>
                            ) : d[k] !== "" && d[k] ? (
                              renderViewDetail(d[k])
                            ) : null
                          ) : animateSecondLastCol &&
                            keysSet[i + 2] === undefined &&
                            d[k] !== undefined ? (
                            <CountUp
                              end={d[k].slice(0, d[k].indexOf(" "))}
                              suffix={d[k].slice(d[k].indexOf(" "))}
                              start={viewPortEntered ? null : 0}
                            >
                              {({ countUpRef, start }) => (
                                <ReactVisibilitySensor
                                  active={!viewPortEntered}
                                  onChange={(isVisible) => {
                                    if (isVisible) {
                                      setViewPortEntered(true);
                                    }
                                  }}
                                  delayedCall
                                >
                                  <span ref={countUpRef} />
                                </ReactVisibilitySensor>
                              )}
                            </CountUp>
                          ) : (
                            <>{stringToHtml(d[k])}</>
                          )}


                        </Td>
                      ) : null,
                    )}
                  </Tr>
                ))
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </Box>
        {/* {withPagination && (
          <Pagination
            // className="hideChakraTableOnMobile"
            activePage={activePage}
            pageSize={pageSize}
            pages={pages}
            handlePageSelect={(event) => handlePageSelect(event)}
            handlePageSize={(event) => handlePageSize(event)}
            marginTop="50px"
          />
        )} */}
        {/* responsive */}
        <Box className="hideChakraCardsOnDesktop">
          <Box className="ChakraCardsOnDesktop">
            {dataSet ? (
              dataSet?.map((d, index) => (
                <Box key={index} className="ChakraCardsOnDesktopInner">
                  {keysSet.map((k, i) =>
                    keys[k] ? (
                      <Box
                        key={i}
                        className={
                          applyNowEnable
                            ? `mobileCol  ChakraCardsOnDesktopInner-${k}`
                            : ` ChakraCardsOnDesktopInner-${k}`
                        }
                        onClick={() => {
                          newz &&
                            keysSet[i + 1] === undefined &&
                            handleClick(
                              d?.headline,
                              d?.description,
                              d?.picture,
                            );

                            applyNowOnClick &&
                            ApplyNowhandleClick(d?.id, d?.title, d?.type);
                        }}
                        style={{
                          alignSelf: applyNowEnable ? " start" : "center",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >

                        {downloadableContent &&
                        keysSet[i + 1] === undefined &&
                        d[k] !== undefined ? (
                          renderPdfDownloader(d[k])
                        ) : (
                          <>
                          {applyNowEnable &&
                            keysSet[i + 1] === undefined &&
                            d[k] !== undefined ? (
                              <>
                                <Flex cursor={"pointer"} alignItems="center" gap="8px">
                                  <BaseText fontWeight="bold" color="primary">
                                    APPLY NOW
                                  </BaseText>
                                  <ArrowForwardIcon
                                      className="arrowIcon"
                                      h={5}
                                      w={5}
                                    backgroundColor="transparent"
                                    _hover=""
                                    color="var(--chakra-colors-primary)"
                                  />
                                </Flex>
                              </>
                            ) 
                            :
                            
                            applyNowEnable && k === "due_date"  ?  <>
                              <>   <Flex alignItems="center" gap="8px"> 
                              
                              <BaseText fontWeight="bold" color="secondary">
                                    due date:
                                  </BaseText>

                                  {renderTableCardComponent(d, k)}
                              
                               </Flex> 
                              
                              
                              
                               </>
                            </>
                      
                            
                            :
                            <>{renderTableCardComponent(d, k)}</>
                            
                            }
                         
                          </>
                        )}

                      
                      </Box>
                    ) : null,
                  )}
                </Box>
              ))
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </>
    )
  );
}

export default PaginationTable;
