import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PageWraper from "../../../../components/Base/PageWraper";
import BaseText from "../../../../components/Base/Text";
import BaseHeading from "../../../../components/Base/Heading";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { htmlParser } from "../../../../utils/helpers";
import BaseImage from "../../../../components/Base/Image";
function NewsReleaseArticle() {
  const location = useLocation();
  const data = location?.state ? location?.state : null;

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "News Releases",
      link: "/news-releases",
    },
    {
      name: "News Releases Article",
      link: "/news-releases-article",
    },
  ];

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="News Release Article" />
      <PageWraper>
        <Box display="flex" flexDirection="column" gap="14px">
          <BaseHeading fontSize="lg" color="secondary">
            {data?.headline}
          </BaseHeading>
          {data?.image && (
            <BaseImage
              source={data?.image}
              borderRadius="8px"
              width="fit-content"
            />
          )}
          <BaseText fontSize="md" color="secondary" textAlign="justify">
            {htmlParser(data?.description)}
          </BaseText>
        </Box>
      </PageWraper>
    </>
  );
}

export default withTranslation()(NewsReleaseArticle);
