import { Link, MenuItem } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export default function BaseMenuItem({
  link,
  isExternal,
  children,
  height,
  ...otherprops

}) {
  return (
    <MenuItem style={{minHeight:"35px"}} {...otherprops}>

      <Link
        width="100%"
        href={link}
        isExternal={isExternal}
        padding="5px 4px 5px 14px"
        _hover={{ textDecoration: "none" }}
      >
        <Trans>{children}</Trans>
      </Link>
    </MenuItem>
  );
}
