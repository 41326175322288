import { Flex, Link } from "@chakra-ui/react";
import React from "react";
import BaseHeading from "../../Base/Heading";
import "./index.css";
import NewsAndUpdateCard from "./NewsAndUpdateCard";
export default function NewsAndUpdates({
  heading,
  headingFontSize,
  data,
  descFontSize,
  seeMore = false,
  ...otherProps
}) {
  return (
    <Flex
      className="NewsUpatesContainer"
      flexDirection="column"
      gap="40px"
      {...otherProps}
    >
      <BaseHeading fontSize={headingFontSize ? headingFontSize : "2xl"}>
        {heading}
      </BaseHeading>

      <Flex flexDirection="column" gap="24px">
        {data?.slice(0, 4)?.map((item, i) => (
          <NewsAndUpdateCard
            key={i}
            item={item}
            seeMore={seeMore}
            descFontSize={descFontSize}
          />
        ))}
      </Flex>

      {seeMore ? (
        <Link
          href="/news-releases"
          color="primary"
          fontWeight="600"
          fontSize="lg"
          _hover={{ textDecoration: "none" }}
        >
          READ MORE
        </Link>
      ) : (
        <></>
      )}
    </Flex>
  );
}
