import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import "./i18n/config";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import NavBar from "./components/Common/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import Footer from "./components/Common/Footer";
import { router } from "./routes";
import Headerbar from "./components/Common/Headerbar";
import { useState } from "react";

import AccessibiltySidePanel from './components/Common/AccessibilitySidePanel'
import { pathInfo } from "./utils/constants";
import { useLocation } from 'react-router-dom';

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <ChakraProvider theme={theme}>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <div className="ab">
//         <Headerbar />
//         <div style={{ position: "sticky", top: "0", zIndex: "99" }}>
//           <NavBar />
//         </div>
//         <RouterProvider router={router} />
//         <Footer />
//         </div>
//       <AccessibiltySidePanel />
  
//       </PersistGate>
//     </Provider>
//   </ChakraProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



const Main = () => {
   const [isShowAccessibilty, setIsShowAccessibilty] = useState(false);
  //  const location =Route();

  useEffect(() => {
    const pathName = window.location.pathname;
    const defaultTitle = 'Capital Development Authority, Islamabad – Official Website';

    // Retrieve title and description from pathInfo based on the current pathname
    const { title, description } = pathInfo[pathName] || {};

    // Set title and description
    document.title = title || defaultTitle;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description || '');
    }
  }, []);

  const handleTurnOff = (value) => {
    setIsShowAccessibilty(value);
  };

  // Your JSX structure remains the same
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <div className={isShowAccessibilty ? 'showAccessibilty' : ''}>
            <Headerbar />
            <div style={{ position: "sticky", top: "0", zIndex: "99" }}>
              <NavBar />
            </div>
            <RouterProvider router={router} />
            <Footer />
          </div>
          
          <AccessibiltySidePanel  onTurnOff={handleTurnOff} />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
};

// Use ReactDOM.render to render the App component

 const root = ReactDOM.createRoot(document.getElementById("root"));
 root.render(
  <React.StrictMode>
      <Main />

    {/* <Main /> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();