// /src/slices/globalSearch.js
import { createSlice } from "@reduxjs/toolkit";

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState: {
    searchData: [],
  },
  reducers: {
    setGlobalSearch: (state, action) => {
      state.searchData = action.payload;
    },
  },
});

export const { setGlobalSearch } = globalSearchSlice.actions;
export const selectData = (state) => state.globalSearch.searchData;
export default globalSearchSlice.reducer;
