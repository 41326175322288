import { Button, Flex, Link, Tooltip } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import BaseImage from "../../../components/Base/Image";
import { ellipsis } from "../../../utils/helpers";
import BaseHeading from "../../Base/Heading";
import BaseText from "../../../components/Base/Text";

function PdfDownloader({ title, fileSize, link, width, fileIndex, ...otherprops }) {
  if (link)
    return (
      // <Tooltip
      //   hasArrow
      //   placement="bottom"
      //   label={title}
      //   bg="grey3"
      //   color="black"
      // >
      <Flex width={'100%'} align={'center'}>
        {fileIndex && <BaseText fontSize="lg" fontWeight="bold" marginRight="10px"> {`${fileIndex}.`} </BaseText>}
        <Link
          href={link}
          isExternal
          title="new"
          color="secondary"
          _hover={{ color: "white" }}
          width={width}
        >
          <Button
            variant="outline"
            gap="12px"
            bgColor="primary_bg"
            _hover={{ bgColor: "primary", color: "white" }}
            padding="14px"
            width={width}
            {...otherprops}
          >
            <BaseImage source="pdf.svg" width="28.41px" height="28.41" />
            <BaseHeading
              color="inherit"
              fontSize="md"
              style={{
                textWrap: "balance",
                textAlign: "left",
              }}
            >
              {ellipsis(title)}
            </BaseHeading>
            <BaseHeading
              color="primary"
              fontSize="md"
              className="PdfDownloadersize"
            >
              {fileSize}
            </BaseHeading>
          </Button>
        </Link>
      </Flex>
      // </Tooltip>
    );
  return (
    <Button
      variant="outline"
      bgColor="primary_bg"
      _hover={{ bgColor: "primary_bg", cursor: "default" }}
      padding="25px"
      gap="12px"
      width={width}
      justifyContent="left"
    >
      <BaseImage source="pdf.svg" width="28.41px" height="28.41" />
      <BaseHeading color="inherit" fontSize="md">
        File Not Found
      </BaseHeading>
    </Button>
  );
}

export default withTranslation()(PdfDownloader);
