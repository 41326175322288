import { withTranslation } from "react-i18next";
import Paragraph from "../../../components/Common/Paragraph";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import CardSection from "../../../components/CardSection";
import PageWraper from "../../../components/Base/PageWraper";
import { Flex } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { getMemberData,getAllHonourableChairmanData } from "../../../utils/API";
import { Box } from "@chakra-ui/react";
import BaseHeading from "../../../components/Base/Heading";
import BaseText from "../../../components/Base/Text";
import "./style.css";
import CircularLoader from "../../../components/Common/CircularLoader";

function BoardMembers() {
  const [allHonourableChairmanData, setAllHonourableChairmanData] = useState([]);

  const [loading, setLoading] = useState(false);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      link: "#",
    },
    {
      name: "All Honourable Chairman",
      link: "/board-members",
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    const data = await getAllHonourableChairmanData();

    setAllHonourableChairmanData(data)

  };

  useEffect(() => {
    fetchData().then((res) => setLoading(false));
  }, []);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="All Honourable Chairman" />

      <PageWraper>
        {loading ? (
          <CircularLoader />
        ) : (
          <Box className="membersCardWrapper itemCenter AllHonourableChairmanWrapper">
            {/* <CardSection title="Chairman CDA" data={allHonourableChairmanData} member /> */}
            <CardSection
              title="All Honourable Chairman"
              data={allHonourableChairmanData}
              member
            />
          </Box>
        )}
 
      </PageWraper>
    </>
  );
}

export default withTranslation()(BoardMembers);
