import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import {
  auctionNoticesRelatedLinks,
  CdaOrdinanceRelatedInfo,
} from "../../../../utils/constants";
import { useEffect, useState } from "react";
import BaseHeading from "../../../../components/Base/Heading";
import TabHeader from "../../../../components/Common/TabHeader";
import ReleateLinksAndInfo from "../../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../../components/Base/PageWraper";
import BaseText from "../../../../components/Base/Text";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";
import { getPublicNotices } from "../../../../utils/API";
import CircularLoader from "../../../../components/Common/CircularLoader";
import { convertToPKT, htmlParser  } from "../../../../utils/helpers";
import Pagination from "../../../../components/Common/Pagination";
import { Box } from "@chakra-ui/react";
import Searchbar from "../../../../components/Common/Searchbar";


function Tenders() {
  const [tab, setTab] = useState("active");
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Procurement",
      link: "#",
    },
    {
      name: "Tenders",
      link: "/tenders",
    },
  ];

  const keys = {
    description: "description",
    tender_due_date: "tender_due_date",
    // valid_date: "valid_date",
    pdf: "pdf",
  };

  const headers = {
    description: "Description",
    tender_due_date: "Due Date",
    // valid_date: "Expiry Date",
    pdf: "View Ad",
  };

  const tabsData = [
    {
      title: "Active Tenders",
      content:
        "Capital Development Auhtority procures all its goods and services in accordance with PPRA Procurement Policy, all PPRA codes of practice and guidelines, and the Authority's Procurement Policy.",
      type: "active",
    },
    {
      title: "Archived Tenders",
      content:
        "Capital Development Auhtority procures all its goods and services in accordance with PPRA Procurement Policy, all PPRA codes of practice and guidelines, and the Authority's Procurement Policy.",
      type: "archive",
    },
  ];

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    setPageSize(event);
    setActivePage(1);
  };

  const fetchData = async () => {
    setTableLoading(true);
    const res = await getPublicNotices("tender", tab, pageSize, activePage);
    return res;
  };

  const convertDate = (data) => {
    data?.map((el) => {
      el.tender_due_date = el?.tender_due_date
        ? convertToPKT(el?.tender_due_date)
        : "";
      el.valid_date = el?.valid_date ? convertToPKT(el?.valid_date) : "";
      // el.summery = el.summery + "\n" + el.description;
    });

    return data;
  };

  const handleTabChange = (value) => {
    setTab(value);
    setPageSize(10);
    setActivePage(1);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
    fetchData()
      .then((response) => {
        setData(convertDate(response?.data));
        setPages(Math.ceil(response?.total / pageSize));
      })
      .finally(() => setTableLoading(false));
    }
  }, [tab, activePage, pageSize,searchQuery]);



  // const handleSearch = () => {
  //   if (searchQuery.trim() === "") {
  //     return;
  //   }
  //   const filteredData = data?.filter((item) =>
  //     item?.description?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  //   );
    
  //   setData(filteredData);
  // };


  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    
    const formattedSearchQuery = searchQuery.trim(); // Trim search query

    const filteredData = data?.filter((item) => {
      // const headlineMatch = item?.headline?.toLowerCase()?.includes(formattedSearchQuery?.toLowerCase());
      const descriptionMatch =  item?.description?.toLowerCase()?.includes(searchQuery?.toLowerCase())

      let createdAtMatch = ( htmlParser(item?.tender_due_date)?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || htmlParser(item?.tender_due_date?.replace(/<sup>.*?<\/sup>/g, '').trim())?.toLowerCase()?.includes(searchQuery?.toLowerCase()) );
      
      const numericalCreatedAt = item?.tender_due_date?.match(/\d+/)?.[0]; // Extract numerical part from tender_due_date
        if(createdAtMatch && searchQuery?.toLowerCase() === "20" ){
          createdAtMatch = numericalCreatedAt && numericalCreatedAt.includes(formattedSearchQuery)
        }

      return descriptionMatch || createdAtMatch;
    });

    setPages(Math.ceil(filteredData?.length/pageSize))
  
    setData(filteredData);
  }
  

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Tenders" />
      <PageWraper>
        <TabHeader
          tabsData={tabsData}
          onTabChange={(e) => handleTabChange(e)}
        />
        <BaseText fontSize={"md"} margin="24px 0px 32px 0px">
          Capital Development Auhtority procures all its goods and services in
          accordance with PPRA Procurement Policy, all PPRA codes of practice
          and guidelines, and the Authority's Procurement Policy.
        </BaseText>

        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={handleSearch}
        />
        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading && data && data?.length > 0 ? (
          <>
            <PaginationTable
              loading={false}
              headers={headers}
              marginTop="33px"
              dataSet={data}
              keys={keys}
              downloadableContent
              whiteSpace
            />
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              pages={pages}
              handlePageSelect={(event) => handlePageSelect(event)}
              handlePageSize={(event) => handlePageSize(event)}
              marginTop="50px"
            />
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}
        <ReleateLinksAndInfo
          releatedLinks={auctionNoticesRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(Tenders);
