import { Box, GridItem } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PdfDownloader from "../../../../components/Common/PdfDownloader";
import "./style.css";

import {
  CdaOrdinanceRelatedInfo,
  CdaOrdinanceRelatedLinks,
} from "../../../../utils/constants";
import ReleateLinksAndInfo from "../../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../../components/Base/PageWraper";
import { getBallotingData } from "../../../../utils/API";
import { useEffect, useState } from "react";
import CircularLoader from "../../../../components/Common/CircularLoader";
import { kebabToCapitalize } from "../../../../utils/helpers";
import Searchbar from "../../../../components/Common/Searchbar";


function ProcurementPlans() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [searchQuery, setSearchQuery] = useState("");
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Procurement",
      link: "#",
    },
    {
      name: "Procurement Plans",
      link: "/procurement-plans",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const res = await getBallotingData("procurement");

    setData(res?.data);
  };

  useEffect(() => {
    fetchData().then((res) => {
      setLoading(false);
    });
  }, []);


  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchData().then((res) => {
        setLoading(false);
      })
    }
  }, [searchQuery]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = data?.filter((item) =>
      item?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setData(filteredData);

  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Procurement Plans" />
      <PageWraper>
      <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={ handleSearch}
        />
        {loading ? (
          <CircularLoader />
        ) : (
          <Box className="downloadWraperProcurement" marginTop={'20px'}>
            {data?.map((item, i) => (
              <GridItem colSpan={1} key={i}>
                <PdfDownloader
                  title={kebabToCapitalize(item?.title)}
                  fileSize={item?.fileSize}
                  height="50.74px"
                  width="100%"
                  justifyContent="left"
                  link={item?.attachment}
                  fileIndex={i+1}
                />
              </GridItem>
            ))}
          </Box>
        )}

        <ReleateLinksAndInfo
          releatedLinks={CdaOrdinanceRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(ProcurementPlans);
