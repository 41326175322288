// /src/slices/gallerySlice.js
import { createSlice } from '@reduxjs/toolkit';

const gallerySlice = createSlice({
  name: 'galleryImages',
  initialState: {
    galleryAllImages: [], // Adjusted the naming for clarity
    isClickedOnAskButton : false,
  },
  reducers: {
    setGalleryImages: (state, action) => {
      state.galleryAllImages = action.payload;
    },
    setisClickedOnAskButton:(state, action) => {
      state.isClickedOnAskButton = action.payload;
    },
  },
});

export const { setGalleryImages,setisClickedOnAskButton } = gallerySlice.actions;
export const selectGalleryData = (state) => state.gallerySlice; // Adjusted the naming here
export default gallerySlice.reducer;
