import { Box, Flex } from "@chakra-ui/react";
import BaseHeading from "../../../../components/Base/Heading";
import PageHeader from "../../../../components/Common/PageHeader";
import PageWraper from "../../../../components/Base/PageWraper";
import { useEffect, useState } from "react";
import { getAreaDataByID } from "../../../../utils/API";
import { useLocation } from "react-router-dom";
import BaseLink from "../../../../components/Base/Link";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";
import DataTable from "../../../../components/Common/simpledataTable/DataTable";
import CircularLoader from "../../../../components/Common/CircularLoader";
import BaseImage from "../../../../components/Base/Image";
import BaseButton from "../../../../components/Base/Button";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import BaseText from "../../../../components/Base/Text";
import "./index.css";

export default function SocietyPage() {
  const location = useLocation();
  const dataID = location?.state ? location?.state : null;

  const [tableLoading, setTableLoading] = useState(false);
  const [areaData, setAreaData] = useState(null);
  const [areaDatatable, setAreaDatatable] = useState(null);
  const [tableConfigdata, settableConfigdata] = useState(null);
  const [areaName, setAreaName] = useState("");
  const [areaPlan, setAreaPlan] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");

  const keys = {
    Name: "Project Data",
    Detail: "Detail",
    Attachments: "Attachments",
  };

  const headers = {
    Name: "Project Data",
    Detail: "Detail",
    Attachments: "Attachments",
  };

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Housing Societies",
      link: "/housing-societies",
    },
    {
      name: "Private Housing Society",
      link: "/housing-societies/:id",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getAreaDataByID(dataID.id);
    const data = response.data;

    const societyTable = [
      { Name: "Zone", Detail: data?.zone, Attachments: "" },
      { Name: "Location", Detail: data?.location, Attachments: "" },
      { Name: "Area", Detail: data?.area, Attachments: "" },
      {
        Name: "Residential Plots",
        Detail: data?.plat_description,
        Attachments: data?.plot,
      },
      { Name: "Phone", Detail: data?.phone, Attachments: "" },
      { Name: "NOC", Detail: data?.nocDescription, Attachments: data?.noc },
      { Name: "Address", Detail: data?.address, Attachments: "" },
      {
        Name: "Layout Plan (LOP)",
        Detail: data?.locDscription,
        Attachments: data?.loc,
      },
    ];

    const getDetailOrDefault = (property, defaultValue = "--") => {
      return data !== undefined &&
        data[property] !== undefined &&
        data[property] !== null
        ? data[property]
        : defaultValue;
    };
    const tableConfig = [
      [
        { Name: "Zone", detail: getDetailOrDefault("zone"), Attachments: "" },
        {
          Name: "Location",
          detail: getDetailOrDefault("location"),
          Attachments: "",
        },
        { Name: "Area", detail: getDetailOrDefault("area"), Attachments: "" },
        { Name: "Phone", detail: getDetailOrDefault("phone"), Attachments: "" },
        {
          Name: "Address",
          detail: getDetailOrDefault("address"),
          Attachments: "",
        },
      ],
      [
        {
          Name: "Residential Plots",
          detail: getDetailOrDefault("plat_description"),
          Attachments: data?.plot,
        },
        {
          Name: "NOC",
          detail: getDetailOrDefault("nocDescription"),
          Attachments: data?.noc,
        },
        {
          Name: "Layout Plan (LOP)",
          detail: getDetailOrDefault("locDscription"),
          Attachments: data?.loc,
        },
      ],
    ];

    setLastUpdate(data?.last_updated ? data.last_updated : "--");
    settableConfigdata(tableConfig);
    setAreaName(data?.name);
    setAreaPlan(JSON.parse(data?.layoutPlan));
    setAreaData(societyTable);
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Private Housing Societies" />
      <PageWraper>
        <BaseHeading fontSize="xl">{areaName}</BaseHeading>
        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading && areaData && areaData?.length > 0 ? (
          <>
            <DataTable
              loading={false}
              headers={headers}
              marginTop="33px"
              tableConfig={tableConfigdata}
            />

            {/* <PaginationTable
              loading={false}
              headers={headers}
              marginTop="33px"
              dataSet={areaData}
              keys={keys}
              viewDetail={true}
            /> */}
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}

        {areaPlan?.length && areaPlan?.length > 0 ? (
          <>
            <Box className="ChakraCardMap">
              <BaseHeading fontSize="lg" marginTop="">
                Layout Plan
              </BaseHeading>

              <Flex alignItems="center" flexWrap="wrap" gap="30px">
                {areaPlan.map((item, index) => (
                  <>
                    <Box className="mapBox">
                      <Flex
                        width="500px"
                        height="201px"
                        marginTop="30px"
                        position="relative"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <BaseButton className="ViewPlanBtn" zIndex="1">
                          <BaseLink
                            isExternal
                            href={item}
                            style={{ textDecoration: "none" }}
                          >
                            View Plan
                          </BaseLink>
                        </BaseButton>
                        <BaseImage
                          source={item}
                          alt="layout plan"
                          width="100%"
                          height="100%"
                          position="absolute"
                          top="0"
                          bottom="0"
                          left="0"
                          right="0"
                          objectFit="cover"
                          opacity="0.8"
                          fallbackSrc="/generic-file.png"
                        />
                      </Flex>
                      <BaseHeading
                        textAlign="center"
                        fontSize="lg"
                        marginTop="16px"
                      >
                        Plan {index === 0 ? "A" : "B"}
                      </BaseHeading>
                    </Box>
                  </>
                ))}
              </Flex>
            </Box>
            <BaseText
              fontSize="md"
              className="updatedtext"
            >{`Last Updated on ${lastUpdate}`}</BaseText>
          </>
        ) : null}
      </PageWraper>
    </>
  );
}
