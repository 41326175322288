import PageWraper from "../../../components/Base/PageWraper";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import TabHeader from "../../../components/Common/TabHeader";
import BaseText from "../../../components/Base/Text";
import BaseHeading from "../../../components/Base/Heading";
import Card from "../../../components/Base/Card";
import BaseImage from "../../../components/Base/Image";
import adventuresSticker from "../../../assets/Adventures/visitor-safety.jpg";
import guideMap from "../../../assets/Adventures/guideMap.svg";
import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import CustomGallery from "../../../components/Base/CustomGallery";
import MapCardSection from "../../../components/Base/MapCardSection";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import "./style.css";
// Other imports...

import galleryData from "./data.json";
import mapData from "./mapData.json";
import { climbingData, trialData } from "../../../utils/constants";
import { getStaticImagesObject } from "../../../utils/helpers";

export default function Adventures() {
  const [tab, setTab] = useState("hiking");
  const [imageUrls, setImageUrls] = useState([]);
  const [hikingImagesUrls, setHikingImagesUrls] = useState([]);
  const [rockClimbingImagesUrls, setRockClimbingImagesUrls] = useState([]);
  const [allMapData, setAllMapData] = useState([]);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Adventures",
      link: "/adventures",
    },
  ];
  const hikingImageFileNames = ["margalla-banner.jpg"];

  const rockClimbingImageFileNames = [
    "rock-climbing-slider-01.jpg",
    "rock-climbing-slider-03.jpg",
    "rock-climbing-slider-02.jpg",
    "rock-climbing-slider-04.jpg",
  ];

  useEffect(() => {
    const importImages = async () => {
      const importsH = await Promise.all(
        hikingImageFileNames.map((fileName) =>
          import(`../../../assets/galleryImages/${fileName}`)
        )
      );
      const urlsH = importsH.map((image, index) =>
        getStaticImagesObject(image.default, index)
      );
      setHikingImagesUrls(urlsH);

      const importsRC = await Promise.all(
        rockClimbingImageFileNames.map((fileName) =>
          import(`../../../assets/galleryImages/${fileName}`)
        )
      );
      const urlsRC = importsRC.map((image, index) =>
        getStaticImagesObject(image.default, index)
      );
      setRockClimbingImagesUrls(urlsRC);
    };

    importImages();
  }, []);

  const tabsData = [
    {
      title: "Margalla Hills Hiking Trails",
      content: "",
      type: "hiking",
    },
    {
      title: "Rock Climbing",
      content: "",
      type: "climbing",
    },
  ];

  const importImages = async () => {
    const imports = await Promise.all(
      galleryData.map((item) =>
        import(`../../../assets/galleryImages/${item.url}`)
      )
    );

    const imagesWithInfo = galleryData.map((item, index) => ({
      url: imports[index].default,
      title: item.title,
      heading: item.heading,
    }));

    setImageUrls(imagesWithInfo);
  };
  const importMapData = async () => {
    const mapDataInfo = mapData.map((item) => ({
      image: guideMap,
      title: item.title,
      link: item.link,
    }));

    setAllMapData(mapDataInfo);
  };

  useEffect(() => {
    if (imageUrls.length === 0) {
      // Call importImages only when imageUrls is empty
      importImages();
    }
    importMapData();
  }, [imageUrls]);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Adventures" />
      <PageWraper>
        <TabHeader tabsData={tabsData} onTabChange={setTab} />
        {tab === "hiking" || tab === "climbing" ? (
          <>
            <Box margin="40px auto">
              <CustomGallery
                images={
                  tab == "hiking" ? hikingImagesUrls : rockClimbingImagesUrls
                }
                isShowTitleOnImg={false}
                heading={""}
                title={""}
                isShowthumbnail={false}
              />
            </Box>

            <BaseHeading fontSize="xl">
              {tab === "hiking"
                ? "Margalla Hills Hiking Trails"
                : "Rock Climbing in Margalla Hills - Safety & Conservation"}
            </BaseHeading>
            <BaseText
              lineHeight="28px"
              fontSize="md"
              margin="24px 0px 32px 0px"
              textAlign="justify"
            >
              {tab === "hiking"
                ? "The capital city of Pakistan, Islamabad is located in the northwest of the country on the Potohar Plateau. This area has been significant in history for being part of the crossroads of Rawalpindi and the North West Frontier Province. The city was built in 1960 to replace Karachi as the Pakistani capital, a role it has held since 1963. Due to Islamabad's proximity to Rawalpindi, they are considered sister cities. Compared to other cities in the country, Islamabad is a clean, spacious and quiet city with lots of greenery. The site of the city has a history of going back to the earliest human habitations in Asia. This area has seen the first settlement of Aryans from Central Asia, ancient caravans passing from Central Asia, and the massive armies of Tamerlane and Alexander. To the north of the city you will find the Margalla Hills. Hot summers, monsoon rains and cold winters with sparse snowfall in the hills almost summarises the climate of this area. Islamabad also has a rich wildlife ranging from wild boars to leopards."
                : "Rock climbing in Margalla Hills offers an exciting yet safe experience when approached with the right precautions and respect for the environment. Despite the perceived risk, advancements in safety measures and equipment have made this sport more accessible and less daunting, aking to learning to swim or trying gymnastics.<br/>Islamabad stands out for the accessibility and quality of climbing opportunities right on its doorstep. With over 50 bolted lines presently available, future developments aim to increase this number significantly in the coming years."}
            </BaseText>
            <BaseHeading marginBottom="32px" fontSize="xl">
              {tab === "hiking"
                ? "Popular Hiking Trails in Margalla Hills"
                : "Established Climbing Routes In Margalla Hills"}
            </BaseHeading>
            <Card
              cardData={imageUrls}
              data={tab === "hiking" ? trialData : climbingData}
            />

            {tab === "hiking" ? (
              <>
                <MapCardSection data={allMapData} />
                <Box className="containerFlex">
                  <Box className="containerLeft">
                    <BaseHeading
                      className="head1"
                      fontSize="lg"
                      fontWeight="bold"
                    >
                      Looking after the environment
                    </BaseHeading>
                    <BaseText
                      className="cardDetail"
                      fontSize="md"
                      color="secondary"
                      textAlign="justify"
                    >
                      The capital city of Pakistan, Islamabad is located in the
                      northwest of the country on the Potohar Plateau. This area
                      has been significant in history for being a part of the
                      crossroads of Rawalpindi and KPK. The city was built in
                      1960 to replace Karachi as Pakistan's capital, which it
                      has been since 1963. Due to Islamabad's proximity to
                      Rawalpindi, they are considered twin cities.
                    </BaseText>
                  </Box>
                  <Box className="divider"></Box>
                  <Box className="containerRight">
                    <BaseImage
                      className="cardImg"
                      source={adventuresSticker}
                      width="100%"
                      height="auto"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="rockclimbing-pdf-container">
                <PdfDownloader
                  title="CA Guide to Monkey Business - A Guide to Climbing in Margalla 2012 edition"
                  width="50%"
                  height="60.74px"
                  link="pdf/Monkey-Business -Margalla-climbing-2012-edition.pdf"
                />
              </Box>
            )}
          </>
        ) : (
          <></>
        )}
      </PageWraper>
    </>
  );
}
