import axios from "axios";
import { BASE_URL } from "../config";

export const getMemberData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/board-members`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAllHonourableChairmanData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/chairman`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getChairmanData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/chairman-message`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAllJobs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/getAllJobs`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};


// export const applyForJobApi = async (body) => {

//   console.log("body",body)
//   // try {
//   //   const response = await axios.post(`${BASE_URL}/api/v1/job-application`, body);
//   //   return response;
//   // } catch (error) {
//   //   console.error("Error:", error);
//   // }
// };

export const applyForJobApi = async (formData) => {


  try {
    const response = await axios.post(`${BASE_URL}/api/v1/job-application`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it in the component
  }
};


export const getBoardMeetings = async (year, activePage, pageSize) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/meeting/list${
        year ? `?year=${year}&page=${activePage}&per_page=${pageSize}` : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getFaqs = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/faq/list`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getProjects = async (type) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/projects/${type}`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAreaData = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/getAllLegalSocieties/private`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getPrivateData = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/getAllLegalSocieties/privateScheme`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getCdaData = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/getAllLegalSocieties/cdaSector`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getAreaDataByID = async (id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/getSpecificLegalSociety/${id}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getBugetReceipts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/budget-receipts`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getBugetExpenditures = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/budget-expenditures`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getLecturesTraining = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/lecture-training`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getCoursesTraining = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/course-training`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getProposedTraining = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/proposed-training`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getOrdinance = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/ordinance/list`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getBallotingData = async (type) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/balloting_sector/list?type=${type}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const askCdaSubmit = async (body) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/askcda/submit`, body);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getPublicNotices = async (type, status, pageSize, activePage) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/public_notices/list?type=${type}&status=${status}&page=${activePage}&per_page=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getGalleryData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/gallery/list`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getHospitalData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/get-hospitals`);
    return response?.data?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getNewsReleases = async (status, activePage, pageSize) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/news/list?status=${status}&page=${activePage}&per_page=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getHousingSocieties = async (pageSize, activePage) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/housingScheme-list?page=${activePage}&per_page=${pageSize}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getPreviousBudget = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/budget-receipts/pdf`);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getHomePageBanner = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/slide-banner/list/banner`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getHomePageSlidrImage = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/slide-banner/list/home_slider`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getFormsApplications = async (heading) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/application/list${heading ? `?type=${heading}` : ""}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getSearchData = async (searchQuery) => {

  try {
    const response = await axios.get('https://www.googleapis.com/customsearch/v1', {
      params: {
        // created from cda account
        key: 'AIzaSyB1meWtxPDY3o2SkyxtXoF4CUUi_AO17gk',
        
         // created from cda account
        cx: '80c9a02fc620041cf',
        q: searchQuery
      }
    });
    return response

  } catch (error) {
    console.error('Error searching:', error);
  }
};

export const getProcedureList = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/procedure/list`);
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
