import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, MenuButton } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export default function BaseMenuButton({
  select,
  submenu,
  children,
  ...otherprops
}) {
  return (
    <MenuButton
      fontSize="md"
      as={Button}
      rightIcon={
        !submenu ? (
          <ChevronDownIcon
            marginLeft="-8px"
            width={select ? "1.6em" : "1.2em"}
            height={select ? "1.6em" : "1.2em"}
            color={select && "primary"}
          />
        ) : (
          <ChevronRightIcon marginLeft="-8px" width="1.2em" height="1.2em" />
        )
      }
      {...otherprops}
    >
      <Trans>{children}</Trans>
    </MenuButton>
  );
}
