import BaseHeading from "../../Base/Heading";
import { Box, Flex } from "@chakra-ui/react";
import MapComponent from "../../Base/BaseMap";
import { useCallback, useState } from "react";
import BaseImage from "../../Base/Image";

import { locations } from "../../../utils/constants";
const Map = ({ heading }) => {
  const [googleMapsApi, setGoogleMapsApi] = useState(null);
  const handleApiLoaded = useCallback((map, maps) => {
    setGoogleMapsApi({ map, maps });
  }, []);

  const defaultZoom = 12;
  const center = { lat: 33.6964437, lng: 72.946817 };

  const Marker = (e) => {
    return (
      <Box display="inline-block">
        <BaseImage
          width="30px"
          height="30px"
          source="map-marker.svg"
          alt="pin"
        />
        <BaseHeading fontSize="sm" color="primary">
          {e?.text}
        </BaseHeading>
      </Box>
    );
  };

  return (
    <Flex flexDirection="column" gap="36px">
      <BaseHeading fontSize="xl">{heading}</BaseHeading>
      <div style={{ height: "81vh", width: "100%" }} className="leaflet-container">
        <MapComponent
          defaultCenter={center}
          defaultZoom={defaultZoom}
          mapOptions={{
            zoomControl: false,
            disableDefaultUI: true,
            scrollwheel: false,
            disableDoubleClickZoom: true,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {locations.map((item, index) => (
            <Marker
              key={index}
              lat={item.latitude}
              lng={item.longitude}
              text={item.name}
            />
          ))}
        </MapComponent>
      </div>
    </Flex>
  );
};

export default Map;
