import { CircularProgress } from "@chakra-ui/react";

export default function CircularLoader({ ...otherprops }) {
  return (
    <CircularProgress
      isIndeterminate
      color="primary"
      display="flex"
      justifyContent="center"
      thickness="10px"
      {...otherprops}
    />
  );
}
