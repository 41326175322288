import { Box, Flex } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import BaseHeading from "../../Base/Heading";
import BaseLink from "../../Base/Link";
import "./style.css";

function ReleateLinksAndInfo({ releatedLinks, releatedInfo }) {
  return (
    <Box display="flex" flexDirection="column" gap="24px" marginTop="40px">
      <Flex flexDirection="column">
        <BaseHeading color="secondary" fontSize="xl">
          Related Links
        </BaseHeading>
        <Box className="related-links-container">
          {releatedLinks.map((item, i) => (
            <BaseLink
              key={i}
              href={item.link}
              _hover={{ textDecoration: "none" }}
              isExternal
              bgColor="primary_bg"
              className="relatedLink"
            >
              {item.name}
            </BaseLink>
          ))}
        </Box>
      </Flex>
      <Flex flexDirection="column">
        <BaseHeading color="secondary" fontSize="xl">
          Related Information
        </BaseHeading>
        <Box className="related-links-container">
          {releatedInfo.map((item, i) => (
            <BaseLink
              key={i}
              href={item.link}
              _hover={{ textDecoration: "none" }}
              className="relatedLink"
              bgColor="primary_bg"
            >
              {item.name}
            </BaseLink>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

export default withTranslation()(ReleateLinksAndInfo);
