import { Badge, Box, Flex } from "@chakra-ui/react";
import BaseImage from "../../../Base/Image";
import BaseHeading from "../../../Base/Heading";
import BaseText from "../../../Base/Text";
import Separator from "../../../Common/Separator";
import { useNavigate } from "react-router-dom";
import IntersectionObserverWrapper from "../../../../utils/IntersectionObserver/IntersectionObserverWrapper";
import { ellipsis, htmlParser } from "../../../../utils/helpers";

const NewsAndUpdateCard = ({ item, seeMore, descFontSize }) => {
  const history = useNavigate();
  const handleClick = (headline, description, image) => {
    if (seeMore) {
      history("/news-releases-article", {
        state: { headline: headline, description: description, image: image },
      });
    }
  };

  return (
    <IntersectionObserverWrapper
      beforeAnimateClass="initialOpacityZeroBeforeAnimate"
      animationClass="fadeUPAnimation"
    >
      <Box className="news-updates-wraper">
        {seeMore && (
          <Box >
            <BaseImage
              source={item?.picture || "newz-default.svg"}
              width="160px"
              height="120px"
              borderRadius="8px"
            />
          </Box>
        )}
        <Flex flexDirection="column" gap="16px" className="NewsUpdatesInner">
          <Flex alignItems="center" gap="16px">
            <BaseText fontSize="md">
              {htmlParser(item?.date) ||
                htmlParser(item?.created_at) ||
                "-- -- ----"}
            </BaseText>
            <Badge
              borderRadius="8px"
              border="1.458px solid var(--chakra-colors-grey3)"
              background="var(--chakra-colors-primary_bg)"
              width="max-content"
              padding="10px"
              textTransform="capitalize"
            >
              <BaseText fontSize="md" fontWeight="500" color="primary">
                {item?.badge || "News Release"}
              </BaseText>
            </Badge>
          </Flex>
          {!seeMore && <BaseHeading fontSize="lg">{item?.title}</BaseHeading>}
          <BaseText
            fontSize={descFontSize ? descFontSize : "lg"}
            cursor={seeMore ? "pointer" : "default"}
            _hover={{
              textDecoration: `${seeMore ? "underline" : "none"}`,
              color: `${seeMore ? "blue" : ""}`,
            }}
            onClick={() => {
              handleClick(item?.headline, item?.description, item?.picture);
            }}
          >
            {item?.headline ? ellipsis(item?.headline) : item?.description}
          </BaseText>
        </Flex>
      </Box>
      <Separator
        width="100%"
        height="1px"
        marginTop="24px"
        marginBottom="8px"
        background="var(--chakra-colors-grey2)"
      />
    </IntersectionObserverWrapper>
  );
};

export default NewsAndUpdateCard;
