import { Menu, MenuList } from "@chakra-ui/react";

import BaseMenuButton from "../MenuButton";
import BaseMenuItem from "../MenuItem";
import "./index.css";
export default function BaseSelect({ options, selectedOption, onSelect }) {
  const handleOptionClick = (option) => {
    onSelect(option);
  };

  return (
    <Menu>
      <BaseMenuButton className="selectMenu" select>
        {selectedOption || "Select an option"}
      </BaseMenuButton>
      <MenuList height="200px" overflowY="scroll">
        {options.map((item) => (
          <BaseMenuItem
            _focus={{ backgroundColor: "transparent" }}
            _hover={{ backgroundColor: "var(--chakra-colors-grey3)" }}
            link={item?.link}
            onClick={() => handleOptionClick(item)}
          >
            {item}
          </BaseMenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
