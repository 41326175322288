import React, { useState } from "react";
import {
  Flex,
  Box,
  UnorderedList,
  ListItem,
  OrderedList,
  Link,
  Input,
  Textarea,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PageWraper from "../../../components/Base/PageWraper";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import TabHeader from "../../../components/Common/TabHeader";
import BaseHeading from "../../../components/Base/Heading";
import Paragraph from "../../../components/Common/Paragraph";
import BaseText from "../../../components/Base/Text";
import BaseImage from "../../../components/Base/Image";
import BaseSelect from "../../../components/Base/BaseSelect";
import BaseButton from "../../../components/Base/Button";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import NewsAndUpdates from "../../../components/Home/NewsAndUpdates";
import Departments from "../../../components/CdaHospital/Departments";
import DoctorSchedule from "../../../components/CdaHospital/DoctorSchedule";
import "./index.css";
import {
  countries,
  departments,
  doctorSchedule,
} from "../../../utils/constants";
import { publicNotices } from "../../../utils/constants";

const hospitalStats = [
  {
    title: "2022 Annual Statistics Reports",
    link: "pdf/Annual_Report_2022.pdf",
  },
  {
    title: "2021 Annual Statistics Reports",
    link: "pdf/Annual_Report_2021.pdf",
  },
  {
    title: "2020 Annual Statistics Reports",
    link: "pdf/Annual_Report_2020.pdf",
  },
  {
    title: "2019 Annual Statistics Reports",
    link: "pdf/Annual_Report_2019.pdf",
  },
  {
    title: "2018 Annual Statistics Reports",
    link: "pdf/Annual_Report_2018.pdf",
  },
  {
    title: "2017 Annual Statistics Reports",
    link: "pdf/Annual_Report_2017.pdf",
  },
];

const downloads = [
  {
    title: "NOC (Retired, Pension, Death, Repatration Cases)",
    link: "pdf/Family_pension_deathcase_noc.pdf",
  },
  {
    title: "Application for Reimbursement of Medical Charges",
    link: "pdf/Medical_charges_reimbursement.pdf",
  },
  {
    title: "Application for Medical Card (Capital Hospital, CDA)",
    link: "pdf/Medical Card Form.pdf",
  },
];

export default function CdaHospital() {
  const [type, setType] = useState("about");
  const [selectedCountry, setSelectedCountry] = useState("Country");
  const [selectedCity, setSelectedCity] = useState("City");
  const [selectedZip, setSelectedZip] = useState("Zip/ Postal Code");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleSelect = (option) => {
    setSelectedCountry(option);
    setSelectedCity(option);
    setSelectedZip(option);
  };
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA Hospital",
      link: "/cda-hospital",
    },
  ];

  const tabsData = [
    {
      title: "About CDA Hospital",
      type: "about",
    },
    {
      title: "Departments",
      type: "departments",
    },
    {
      title: "Services",
      type: "services",
    },
    {
      title: "Public Notices",
      type: "public-notices",
    },
    {
      title: "Doctor Schedule",
      type: "doctor-schedule",
    },
    {
      title: "Contact Us",
      type: "contact-us",
    },
  ];

  const pageContent = () => {
    switch (type) {
      case "about":
        return (
          <Flex gap="40px" flexDirection="column">
            <Flex gap="24px" flexDirection="column">
              <BaseHeading size="md">About Us</BaseHeading>
              <Flex gap="16px" flexDirection="column">
                <Paragraph content="cdaHospitalAboutPara1" />
                <Paragraph content="cdaHospitalAboutPara2" />
                <Paragraph content="cdaHospitalAboutPara3" />
                <Paragraph content="cdaHospitalAboutPara4" />
              </Flex>
              <BaseImage
                className="cdaHospitalAbout-img"
                source="cda-hospital-about.png"
                height="629px"
              />
            </Flex>

            <Flex gap="24px" flexDirection="column">
              <BaseHeading size="md">Mission and Vision</BaseHeading>
              <Flex gap="16px" flexDirection="column">
                <Paragraph content="cdaHospitalMissionPara1" />
                <Paragraph content="cdaHospitalMissionPara2" />
              </Flex>
            </Flex>

            <Flex gap="40px" flexDirection="column">
              <BaseHeading size="md">Organogram</BaseHeading>
              <BaseImage
                className="cdaHospitalAbout-img"
                source={"cdaHospitalOrganogram.svg"}
                height="699px"
                alignSelf="center"
              />
            </Flex>
            <Flex gap="24px" flexDirection="column">
              <BaseHeading size="md">Hospital Stats</BaseHeading>
              <Flex gap="16px" flexDirection="column">
                <BaseText fontSize="md">
                  Following are the yearly statistics of Capital Hospital .
                </BaseText>
                <Grid className="cda-hospital-download-container">
                  {hospitalStats?.map((i, index) => {
                    return (
                      <GridItem colSpan={1}>
                        <PdfDownloader
                          title={i.title}
                          width="100%"
                          height={"50.74px"}
                          link={i.link}
                          justifyContent="left"
                          fileIndex={index+1}
                        />
                      </GridItem>
                    );
                  })}
                </Grid>
              </Flex>
            </Flex>
          </Flex>
        );

      case "departments":
        return <Departments data={departments} />;

      case "services":
        return (
          <Flex gap="16px" flexDirection="column">
            <BaseText fontSize="md">
              Following is the list of services providing by Capital Hospital
            </BaseText>

            <OrderedList fontSize="xl" listStylePosition="inside" margin="0px">
              <Flex flexDirection="column" gap="40px">
                <ListItem fontSize="xl" fontWeight="bold">
                  OPD & Treatment Service{" "}
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Outdoor consultation and treatment services are provided
                      in Capital Hospital in the following specialities.
                    </BaseText>
                    <BaseText fontSize="md">
                      <Flex className="service-list-container">
                        <UnorderedList width="100%">
                          <ListItem>Bariatric Surgery</ListItem>
                          <ListItem>ENT</ListItem>
                          <ListItem>Bariatric Surgery</ListItem>
                          <ListItem>Nephrology</ListItem>
                          <ListItem>Paediatric Surgery</ListItem>
                          <ListItem>Spinal Surgery</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Cardiac Surgery</ListItem>
                          <ListItem>Endocrinology</ListItem>
                          <ListItem>Gynae and Obstetrics</ListItem>
                          <ListItem>Neuro Surgery</ListItem>
                          <ListItem>Paediatric </ListItem>
                          <ListItem>Urology</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Dental Surgery</ListItem>
                          <ListItem>Eye </ListItem>
                          <ListItem>Maxillofacial</ListItem>
                          <ListItem>Orthopaedics</ListItem>
                          <ListItem>Plastic Surgery</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Dermatology</ListItem>
                          <ListItem>General Surgery</ListItem>
                          <ListItem>Medicine</ListItem>
                          <ListItem>Paediatric Surgery</ListItem>
                          <ListItem>Psychiatry</ListItem>
                        </UnorderedList>
                      </Flex>
                    </BaseText>
                  </Flex>
                </ListItem>
                <ListItem fontSize="xl" fontWeight="bold">
                  Indoor Service
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Outdoor consultation and treatment services are provided
                      in Capital Hospital in the following specialities.
                    </BaseText>
                    <BaseText fontSize="md">
                      <Flex className="service-list-container">
                        <UnorderedList width="100%">
                          <ListItem>Medical and Cardiac Ward</ListItem>
                          <ListItem>CCU (Coronary Care Unit)</ListItem>
                          <ListItem>Officer Medical Ward</ListItem>
                          <ListItem>Surgical & Urology Ward</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Ortho Ward</ListItem>
                          <ListItem>Eye and ENT Ward</ListItem>
                          <ListItem>Obs. Ward</ListItem>
                          <ListItem>Gynae Ward</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Officers Surgical Wards</ListItem>
                          <ListItem>Neonatology unit</ListItem>
                          <ListItem>Children ward</ListItem>
                          <ListItem>Casualty Short Stay ward</ListItem>
                        </UnorderedList>

                        <UnorderedList width="100%">
                          <ListItem>Dermatology Ward</ListItem>
                          <ListItem>ICU Ward</ListItem>
                          <ListItem>Psychiatry</ListItem>
                          <ListItem>Nephrology</ListItem>
                        </UnorderedList>
                      </Flex>
                    </BaseText>
                  </Flex>
                </ListItem>
                <ListItem fontSize="xl" fontWeight="bold">
                  Surgical Service
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following surgical service are provided in Capital
                      Hospital
                    </BaseText>

                    <BaseText fontSize="md">
                      <Flex className="service-list-container">
                        <UnorderedList width="100%">
                          <ListItem>
                            All General Surgery procedures including Laproscopic
                            Surgery
                          </ListItem>
                          <ListItem>
                            All Orthopaedic operations including Joint
                            Replacement and Arthoscopy
                          </ListItem>
                          <ListItem>
                            All Urological Surgical Procedures
                          </ListItem>
                          <ListItem>
                            All Gynaecological and Obstetric surgical procedures
                            and laproscopic surgery
                          </ListItem>
                          <ListItem>
                            All ENT Surgical procedures including Microsurgery,
                            Rigid Endoscopy and flexible nasopharyngscopy etc.
                          </ListItem>
                          <ListItem>
                            All Opthalmic Surgical procedures including Phaco.
                          </ListItem>
                          <ListItem>Cochlear Implant</ListItem>
                        </UnorderedList>
                      </Flex>
                    </BaseText>
                  </Flex>
                </ListItem>
                <ListItem fontSize="xl" fontWeight="bold">
                  Investigative Service
                  <Flex
                    flexDirection="column"
                    gap="16px"
                    marginTop="24px"
                    className="service-list-container"
                  >
                    <BaseText fontSize="md">
                      Investiagtive service are provided in Capital Hospital the
                      department that offers the services are:
                    </BaseText>
                    <BaseText fontSize="md">
                      <Flex
                        flexWrap="wrap"
                        gap="50px"
                        className="service-list-container"
                      >
                        <Box className="service-list-container">
                          <BaseHeading fontSize="md">Radiology</BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem>
                              Plain X-Rays and contrast studies
                            </ListItem>
                            <ListItem>Orthopentomogram Facility (OPG)</ListItem>

                            <ListItem> Ultrasound General</ListItem>

                            <ListItem> Ultrasound Gynae and Obs.</ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">
                            Path Laboratory/Blood Bank
                          </BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> Pathology</ListItem>
                            <ListItem> Microbiology</ListItem>

                            <ListItem> BioChemistry </ListItem>

                            <ListItem> Haematology</ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">Cardiology</BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> ECG</ListItem>
                            <ListItem>ETT</ListItem>

                            <ListItem> ECHO</ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">
                            Imaging Radiology
                          </BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> CT Scan </ListItem>
                            <ListItem> MRI </ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">
                            ENT/OTOLARYNGOLOGY
                          </BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> Pure Tone Audiometry</ListItem>
                            <ListItem> Tympanometry </ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">
                            Gasteroenterology
                          </BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> Endoscopy </ListItem>
                          </UnorderedList>
                        </Box>

                        <Box>
                          <BaseHeading fontSize="md">Nephrology</BaseHeading>
                          <UnorderedList width="100%">
                            <ListItem> Dialysis </ListItem>
                          </UnorderedList>
                        </Box>
                      </Flex>
                    </BaseText>
                  </Flex>
                </ListItem>
              </Flex>
            </OrderedList>
          </Flex>
        );

      case "public-notices":
        return (
          <>
            <NewsAndUpdates
              descFontSize="md"
              headingFontSize="lg"
              heading="Public Notices"
              data={publicNotices}
            />
            <Flex direction="column" gap="24px">
              <BaseHeading fontSize="xl">Download Form</BaseHeading>
              <Grid className="cda-hospital-download-container">
                {downloads?.map((item, index) => (
                  <GridItem colSpan={1}>
                    <PdfDownloader
                      title={item?.title}
                      width="100%"
                      height={"50.74px"}
                      link={item?.link}
                      justifyContent="left"
                      fileIndex={index+1}
                    />
                  </GridItem>
                ))}
              </Grid>
            </Flex>
          </>
        );

      case "doctor-schedule":
        return <DoctorSchedule data={doctorSchedule} />;

      case "contact-us":
        return (
          <Flex direction="column" gap="45px">
            <Flex direction="column" gap="16px">
              <BaseHeading fontSize="lg">
                Suggestions and Recommendations
              </BaseHeading>
              <BaseText fontSize="md">
                To make a suggestion or recommendation, use the contact
                information given below. Alternatively, fill in the form given
                below and press 'submit'.
              </BaseText>
            </Flex>

            <Flex direction="column" gap="16px">
              <BaseHeading fontSize="lg">Contact Information </BaseHeading>
              <Box>
                <Flex gap="3px" alignItems="center">
                  <BaseHeading fontSize="md">Address:</BaseHeading>
                  <BaseText fontSize="md">
                    CDA Hospital , St# 31 ,G-6/2, Islamabad
                  </BaseText>
                </Flex>

                <Flex gap="3px" alignItems="center">
                  <BaseHeading fontSize="md">Tel:</BaseHeading>
                  <BaseText fontSize="md">
                    <a href={`tel:+92-51-9221334-9`}>+92-51-9221334-9</a>
                  </BaseText>
                </Flex>

                <Flex gap="3px" alignItems="center">
                  <BaseHeading fontSize="md">Fax:</BaseHeading>
                  <BaseText fontSize="md">
                    <a href={`tel:+92-51-9224377`}>+92-51-9224377</a>
                  </BaseText>
                </Flex>

                <Flex gap="3px" alignItems="center">
                  <BaseHeading fontSize="md">Email:</BaseHeading>
                  <a
                    href={`mailto:webmaster.ch@cda.gov.pk`}
                    style={{ fontSize: "14px" }}
                  >
                    webmaster.ch@cda.gov.pk
                  </a>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="column" gap="24px">
              <BaseHeading fontSize="lg">Sender's Information </BaseHeading>
              <Flex
                flexDirection="column"
                gap="27px"
                width="70%"
                className="contact-us-responsive-full-width"
              >
                <Flex gap="80px" className="contact-us-responsive">
                  <Flex gap="24px" width="100%" flexDirection="column">
                    <Input
                      placeholder="Full Name*"
                      type="text"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                    <PhoneInput
                      country={"pk"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      containerClass="containerClass"
                      placeholder="Phone"
                      containerStyle={{
                        border: "none",
                      }}
                      inputStyle={{
                        border: "1px solid var(--chakra-colors-grey2)",
                        width: "100%",
                        color: "var(--chakra-colors-grey1)",
                        height: "40px",
                        borderRadius: "6px",
                      }}
                      inputClass={"inputClass"}
                      buttonStyle={{
                        border: "none",
                        background: "transparent",
                      }}
                    />
                    <Input
                      type="text"
                      placeholder="Address 2"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                    <BaseSelect
                      options={countries}
                      selectedOption={selectedCountry}
                      onSelect={handleSelect}
                    />
                    <Textarea
                      placeholder="Suggestions/ Recommendations*"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                  </Flex>
                  <Flex gap="24px" width="100%" flexDirection="column">
                    <Input
                      placeholder="Email Address*"
                      type="email"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                    <Input
                      placeholder="Address 1"
                      type="text"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />

                    <Input
                      placeholder="City"
                      type="text"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                    <Input
                      placeholder="Zip/ Postal Code"
                      type="text"
                      border="1px solid var(--chakra-colors-grey2)"
                      _focusVisible={{
                        borderColor: "secondary",
                        boxShadow: "none",
                      }}
                      _hover={{ borderColor: "grey1" }}
                      color="var(--chakra-colors-grey1)"
                      _placeholder={{ color: "var(--chakra-colors-grey1)" }}
                    />
                  </Flex>
                </Flex>

                <BaseButton
                  bgColor="primary"
                  color="white"
                  width="87px"
                  height="40px"
                  _hover={{ bgColor: "primary" }}
                >
                  SUBMIT
                </BaseButton>
              </Flex>
            </Flex>
          </Flex>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Hospital" />
      <PageWraper>
        <Flex gap="40px" flexDirection="column">
          <TabHeader tabsData={tabsData} onTabChange={setType} />
          {pageContent()}
        </Flex>
      </PageWraper>
    </>
  );
}
