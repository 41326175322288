import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import BaseImage from "../../Base/Image";
import BaseText from "../../Base/Text";

export default function MapCard({ data }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Flex direction="column" width="300px">
      <Modal isOpen={showModal} size="3xl">
        <ModalOverlay />
        <ModalContent padding="30px" height="600px">
          <CloseIcon
            marginLeft="auto"
            onClick={() => setShowModal(false)}
            fontSize="lg"
            cursor="pointer"
          />
          <ModalBody overflowY="scroll">
            <BaseImage source={data?.link} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex direction="column" gap="3px" paddingTop="16px">
        <BaseText
          textAlign="center"
          marginBottom="32px"
          fontSize="md"
          fontWeight="bold"
        >
          {data?.title}
        </BaseText>
      </Flex>
      <BaseImage
        source={data?.image ? data.image : "greyBackground.svg"}
        width="100%"
        height="240px"
        cursor="pointer"
        onClick={() => setShowModal(!showModal)}
      />
    </Flex>
  );
}
