import { Box, Flex, Link } from "@chakra-ui/react";
import BaseButton from "../../components/Base/Button";
import BaseHeading from "../../components/Base/Heading";
import BaseImage from "../../components/Base/Image";
import BaseText from "../../components/Base/Text";
import "./index.css";
export default function PageNotFound() {
  return (
    <Box className="wraper404">
      <BaseImage source="404.jpg" className="image404" />
      <Flex flexDirection="column" gap="18px">
        <BaseHeading fontSize="3xl" lineHeight="57.987px">
          Sorry we could not find the
          <br /> page!
        </BaseHeading>
        <BaseText
          fontSize="16.24px"
          color="grey1"
          fontWeight="600"
          lineHeight="27.608px"
        >
          The page you are looking for might have been removed or
          <br /> had its name changed or is temporarily unavailable.
        </BaseText>
        <BaseButton
          color="white"
          bgColor="primary"
          className="back-to-home-btn"
          _hover={{ bgColor: "primary" }}
        >
          <Link href="/" _hover={{ textDecoration: "none" }}>
            BACK to HOMEPAGE
          </Link>
        </BaseButton>
      </Flex>
    </Box>
  );
}
