import { Grid, GridItem } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import Paragraph from "../../../components/Common/Paragraph";
import "./style.css";

import CircularLoader from "../../../components/Common/CircularLoader";
import BaseText from "../../../components/Base/Text";
import PageWraper from "../../../components/Base/PageWraper";
import { getFormsApplications } from "../../../utils/API";
import { useEffect, useState } from "react";
import { Box} from "@chakra-ui/react";
import BaseHeading from "../../../components/Base/Heading";

import Searchbar from "../../../components/Common/Searchbar";
function LawsandRegulations() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Legislation",
      link: "#",
    },
    {
      name: "By-Laws & Regulations",
      link: "/by-laws-regulations",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const res = await getFormsApplications("by_laws_and_regulations");
    setData(res?.data);
  };

  useEffect(() => {
    fetchData().then((res) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchData().then((res) => {
        setLoading(false);
      })
    }
  }, [searchQuery]);
  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = data?.filter((item) =>
      item?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setData(filteredData);

  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="By-Laws & Regulations" />
      <PageWraper>
      <Box  marginBottom={'20px'}>
        <Paragraph content="lawsandRegulationsPara" />
        </Box>
        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={ handleSearch}
        />
            
        {loading ? (
          <CircularLoader />
        ) : (data?.length > 0) && (data !== undefined) ? (
          <Grid className="downloadWraper laws-download-container " >
            {data
              ?.slice()
              ?.reverse()
              ?.map((item, i) => (
                <GridItem colSpan={1} key={i}>
                  <PdfDownloader
                    title={item?.title}
                    fileSize={item?.fileSize}
                    height={"auto"}
                    minHeight={"50.74px"}
                    width="100%"
                    justifyContent="left"
                    link={item?.attachment}
                    fileIndex={i+1}
                  />
                </GridItem>
              ))}
          </Grid>
        )
        : ( <Box margin="140px auto" width="max-content"> <BaseHeading fontSize="2xl" color="secondary"> Information not available </BaseHeading> </Box> )
        }
        <BaseText color="secondary" textAlign="right" marginTop="32px">
          Last Updated on Jan 24th, 2023
        </BaseText>
      </PageWraper>
    </>
  );
}

export default withTranslation()(LawsandRegulations);
