import BaseText from "../../Base/Text";
import BaseHeading from "../../Base/Heading";
import { Flex } from "@chakra-ui/react";
import Paragraph from "../../Common/Paragraph";

function AskCda() {
  return (
    <Flex flexDirection="column" gap="24px">
      <BaseHeading fontSize="xl">Ask CDA</BaseHeading>
      <Paragraph content="Public Grievance and Complaints Cell has been established for both Capital Development Authority, Islamabad and Metropolitan Corporation Islamabad. The Cell is headed by Chief Complaint Officer. Following land lines are available to receive public queries/complaints from 8:30 am to 4:30 pm on working days (Monday - Friday)." />

      <BaseText fontSize="md">
        Phone: <a href="tel:051-9253016">051-9253016</a> and{" "}
        <a href="tel:051-9252962">051-9252962</a>
      </BaseText>
      <BaseText fontSize="md">
        If you have any queries, let us know and we will get back to you as soon
        as possible.
      </BaseText>
      <BaseText fontSize="md">
        Incase of file attachment you may email directly to &nbsp;
        <a
          href="mailto:support@cda.com"
          style={{ color: "var(--chakra-colors-primary)", fontWeight: "600" }}
        >
         cdacares@cda.gov.pk
        </a>
      </BaseText>
    </Flex>
  );
}
export default AskCda;
