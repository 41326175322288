import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import "./style.css";
import BaseHeading from "../../components/Base/Heading";
import BaseText from "../../components/Base/Text";
import GalleryModal from "./GalleryModal";
import { useSelector,useDispatch } from "react-redux";

import {setisGalleryPageChange} from "../../reduxToolkit/slices/changePage"

const GalleryAllImagesPage = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [screenSize, setScreenSize] = useState("");
  const galleryallImages = useSelector(
    (state) => state.gallery.galleryAllImages
  );

  useEffect(() => {
    setImageUrls(galleryallImages);
    // const importImages = async () => {
    //   const imports = await Promise.all(
    //     galleryData.map((item) =>
    //       import(`../../assets/galleryImages/${item.url}`)
    //     )
    //   );

    //   const urls = imports.map((image) => image.default);
    //   setImageUrls(urls);
    // };

    // importImages();

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 870);
      setScreenSize(window.innerWidth);
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [galleryallImages]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl.image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="gallery-container">
      {imageUrls.map((item, index) => (
        <div className="image-card" key={index}>
          {index % 3 === 0 || isSmallScreen ? (
            <Box
              className="full-width main-container"
              onClick={() => handleImageClick(item)}
            >
              <img className="Half-widthImg" src={item.image} alt="img" />
              {item.name || item.description ? (
                <Box className="iconAboveImgContainerAllImages">
                  {item.name && (
                    <BaseHeading
                      fontSize="md"
                      color="secondary"
                      marginBottom="4px"
                    >
                      {item.name}
                    </BaseHeading>
                  )}
                  {item.description && (
                    <BaseText
                      fontSize="md"
                      color="secondary"
                      paddingBottom="30px"
                    >
                      {item.description}
                    </BaseText>
                  )}
                </Box>
              ) : null}
            </Box>
          ) : (
            <>
              {index % 3 === 1 ? (
                <div className="half-width main-container">
                  <>
                    <Box
                      className="leftSideImgDiv"
                      onClick={() => handleImageClick(item)}
                    >
                      <img
                        className="Half-widthImg"
                        src={item.image}
                        alt="img"
                      />
                      {item.name || item.description ? (
                        <Box className="iconAboveImgContainerAllImages">
                          {item.name && (
                            <BaseHeading
                              fontSize="md"
                              color="secondary"
                              marginBottom="4px"
                            >
                              {item.name}
                            </BaseHeading>
                          )}
                          {item.description && (
                            <BaseText
                              fontSize="md"
                              color="secondary"
                              paddingBottom="30px"
                            >
                              {item.description}
                            </BaseText>
                          )}
                        </Box>
                      ) : null}
                    </Box>
                    {imageUrls[index + 1] && (
                      <Box
                        className="rightSideImgDiv"
                        onClick={() => handleImageClick(imageUrls[index + 1])}
                      >
                        <img
                          className="Half-widthImg"
                          src={imageUrls[index + 1].image}
                          alt="img"
                        />
                        {imageUrls[index + 1].name ||
                        imageUrls[index + 1].description ? (
                          <Box className="iconAboveImgContainerAllImages">
                            {imageUrls[index + 1].name && (
                              <BaseHeading
                                fontSize="md"
                                color="secondary"
                                marginBottom="4px"
                              >
                                {imageUrls[index + 1].name}
                              </BaseHeading>
                            )}
                            {imageUrls[index + 1].description && (
                              <BaseText
                                fontSize="md"
                                color="secondary"
                                paddingBottom="30px"
                              >
                                {imageUrls[index + 1].description}
                              </BaseText>
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    )}
                  </>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      ))}

      <GalleryModal
        isSmallScreen={isSmallScreen}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        imageUrl={selectedImage}
      />
    </div>
  );
};

export default GalleryAllImagesPage;
