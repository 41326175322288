import {
  Box,
  Flex,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import Paragraph from "../../../components/Common/Paragraph";
import CustomGallery from "../../../components/Base/CustomGallery";
import { useEffect, useState } from "react";
import BaseHeading from "../../../components/Base/Heading";
import PageWraper from "../../../components/Base/PageWraper";
import BaseText from "../../../components/Base/Text";
import MasterPlan from "../../../components/MasterPlan";
import { data, climatedata } from "./data";

import PdfDownloader from "../../../components/Common/PdfDownloader";
import PaginationTable from "../../../components/Common/Table/PaginationTable";

import { getGalleryData } from "../../../utils/API";
import { useDispatch } from "react-redux";
import { setGalleryImages } from "../../../reduxToolkit/slices/galleryImages";
import "./index.css";
export default function AboutIslamabad() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "About Islamabad",
      link: "/about-islamabad",
    },
  ];

  const [imageUrls, setImageUrls] = useState([]);

  const dispatch = useDispatch();
  const fetchData = async () => {
    const res = await getGalleryData();

    if (res) {
      setImageUrls(res?.data.history);
      dispatch(setGalleryImages(res?.data.history));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const keys = {
    title: "Total Area",
    period: "906.50 sq. km",
  };
  const climateKeys = {
    seasons: "Seasons",
    highAvg: "Avg. High",
    lowAvg: "Avg. Low",
  };

  const headers = {
    title: "Total Area",
    period: "906.50 sq. km",
  };
  const headersClimate = {
    seasons: "Seasons",
    highAvg: "Avg. High",
    lowAvg: "Avg. Low",
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Islamabad - The Beautiful" />

      <PageWraper>
        <Paragraph content="about-islamabad" />

        <Box margin="40px auto">
          <BaseHeading fontSize="xl">
            Historical Pictures of Islamabad
          </BaseHeading>

          <CustomGallery
            images={imageUrls}
            isShowTitleOnImg={true}
            heading={"Islamabad Terrain"}
            title={"Islamabad terrain before development work"}
          />
        </Box>

        <Flex flexDirection="column" gap="32px">
          <BaseHeading fontSize="xl">Facts & Statistics</BaseHeading>

          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px">
              <BaseHeading fontSize="lg">Location</BaseHeading>
              <BaseText fontSize="md">
                14 kms north east of Rawalpindi on the north eastern fringe of
                the Potohar plateau of the province of Punjab.
              </BaseText>
            </Flex>

            <Flex flexDirection="column" gap="16px">
              <BaseHeading fontSize="lg">Map Reference</BaseHeading>
              <BaseText fontSize="md">
                Northern latitudes 33&deg; 49' and longtitudes 72&deg; 24' east
                of Greenwhich.
              </BaseText>
            </Flex>

            <Flex flexDirection="column" gap="16px">
              <BaseHeading fontSize="lg">Altitude</BaseHeading>
              <BaseText fontSize="md">
                Lying at altitudes ranging from 457 to 610 metres.
              </BaseText>
            </Flex>

            <Flex flexDirection="column" gap="16px">
              <BaseHeading fontSize="lg">Area</BaseHeading>
              <BaseText fontSize="md" textAlign="justify">
                Islamabad, located 14 kilometers northeast of Rawalpindi on the
                Potohar plateau, spans an area of 906.50 square kilometers. The
                city is divided into five major zones; administrative,
                diplomatic, residential, educational, industrial, commercial.
                Zone IV being the largest and Zone I as the primary developed
                residential area, split into alphabetically labeled sectors
                covering approximately 2 km × 2 km each.
              </BaseText>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="48px" marginY="40px">
          {/* <CustomTable columns={column1} data={data1} /> */}
          <PaginationTable
            loading={false}
            headers={headers}
            dataSet={data}
            keys={keys}
            withPagination={false}
            animateLastCol
          />
        </Flex>

        <Flex flexDirection="column" gap="16px">
          <BaseHeading fontSize="lg">Climate</BaseHeading>
          <BaseText fontSize="md" textAlign="justify">
            Islamabad features an atypical version of a humid subtropical
            climate, with hot, humid summers accompanied by a monsoon season
            followed by cool winters.
          </BaseText>
        </Flex>
        <Flex flexDirection="column" gap="48px" marginY="40px">
          {/* <CustomTable columns={column2} data={data2} /> */}
          <PaginationTable
            loading={false}
            headers={headersClimate}
            dataSet={climatedata}
            keys={climateKeys}
            withPagination={false}
            animateLastCol
            animateSecondLastCol
          />
        </Flex>

        <Flex flexDirection="column" gap="28px">
          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="lg">Rainfall</BaseHeading>
            <BaseText fontSize="md" textAlign="justify">
            Average yearly rainfall 1143 milimetres.
            </BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="lg">Humidity</BaseHeading>
            <BaseText fontSize="md" textAlign="justify">
            Average yearly humidity: 55%
            </BaseText>
          </Flex>

          <Flex flexDirection="column" gap="16px">
            <BaseHeading fontSize="lg">Altitude</BaseHeading>
            <UnorderedList
              //   listStylePosition="inside"
              fontSize="md"
              textAlign="justify"
            >
              <ListItem>
                Islamabad is Pakistan's most diverse metropolis in terms of the
                city's population makeup.
              </ListItem>
              <ListItem>
                It has the largest expatriate and foreigner population in the
                city. The reasons are simple: its inviting and reasonably
                temperate climate, lush green scenery and excellent basic
                infrastructure.
              </ListItem>
              <ListItem>
                The city is also a stopping point for tourists who desire to
                proceed to the northern areas of the country for trekking,
                hiking, adventure sports and mountaineering.
              </ListItem>
              <ListItem>
                As the city has grown into a major business and commerce centre,
                it has attracted a large highly skilled workforce from other
                major cities including Karachi, Lahore and Quetta.
              </ListItem>
              <ListItem>
                All of the country's diplomatic ties are maintained and
                exercised from Islamabad, as all the major embassies, consulates
                and missions are operating from the city, including the foreign
                office.
              </ListItem>
            </UnorderedList>
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="16px" marginTop="52px">
          <BaseHeading fontSize="xl">
            Islamabad Master Plan - Background
          </BaseHeading>
          <BaseText fontSize="md" textAlign="justify">
            Following Pakistan's formation in 1947, the need for a distinctive
            capital city led to the establishment of Islamabad, a project aimed
            at creating a centralised administrative hub isolated from Karachi's
            commercial activities. In 1958, a commission selected a site
            northeast of Rawalpindi, focusing on location, climate, defence
            needs, aesthetics, and natural beauty. It's master plan was designed
            by Doxiadis Associates. They envisioned a grid-based layout facing
            the Margalla Hills, with long-term plans to encompass Rawalpindi and
            extend westward from the Grand Trunk road.
            <br /> Initially, the metropolitan area comprised Islamabad,
            National Park, Rawalpindi, and the Cantonment. However, subsequent
            ordinances separated Rawalpindi and its Cantonment from Islamabad's
            master plan in 1963.
            <br />
            Efforts to revise the master plan in 1986 and 2005 didn't receive
            the approval of Federal Cabinet. In 2010, amendments to the ICT
            zoning regulations subdivided Zone-4 into four zones following a
            supreme court order.
            <br />
            The establishment of the Islamabad Local Government Act in 2015 led
            to the division of the city into 50 Union Councils, managed by the
            Metropolitan Corporation Islamabad.
          </BaseText>
        </Flex>

        {/* <BaseImage
          source="islamabadMap.png"
          alt="islamabad map"
          width="100%"
          height="857px"
          marginTop="32px"
        /> */}
        <Box>
          <MasterPlan width="100%" height="857px" margintop="32px" />
        </Box>

        <Flex flexDirection="column" gap="24px" marginTop="72px">
          <BaseHeading fontSize="lg">
            Reports and Minutes of the Meetings
          </BaseHeading>

          <OrderedList
            listStylePosition="inside"
            margin="0px"
            textAlign="justify"
          >
            <Flex flexDirection="column" gap="24px">
              <ListItem fontSize="md" fontWeight="bold">
                Review of Master Plan of Islamabad (2020-2040) prepared by
                Federal Commission (Interim Report)
                <Box className="reports-minutes-container">
                  <BaseText fontSize="md">
                    In exercise of powers conferred by Section 51 of the Capital
                    Development Authority Ordinance, 1960 (No. XXIII of 1960),
                    read with section 11 thereof, the Capital Development
                    Authority has been pleased to notify with immediate effect
                    till further orders following Interim Report prepared by the
                    Commission constituted by the Federal Government of Pakistan
                    and approved by the Federal Cabinet.
                  </BaseText>
                  <PdfDownloader
                    title="Interim Report on the review of Master Plan of Islamabad (2020-2040)"
                    width="50%"
                    height={"auto"}
                    minHeight={"50.74px"}
                    justifyContent="start"
                    link="pdf/masterPlanInterimReport2020.pdf"
                  />
                </Box>
              </ListItem>

              <ListItem fontSize="md" fontWeight="bold">
                Minutes of the pre-bid meeting on "Review of the exisiting
                Master Plan of Islamabad (2020-2040) held on 03-11-2020"
                <Box className="reports-minutes-container">
                  <PdfDownloader
                    title="Minutes of the pre-bid meeting on Review of the exisiting Master Plan of Islamabad (2020-2040) held on 03-11-2020"
                    height={"auto"}
                    minHeight={"50.74px"}
                    width="50%"
                    justifyContent="start"
                    link="pdf/minutes_prebid_2020.pdf"
                  />
                </Box>
              </ListItem>
            </Flex>
          </OrderedList>
        </Flex>
      </PageWraper>
    </>
  );
}
