import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
  Box,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  DrawerCloseButton,
} from "@chakra-ui/react";
import BaseImage from "../../../Base/Image";
import {
  AboutIslamabadRoutes,
  AboutusRoutes,
  EServicesRoutes,
  LegislationRoutes,
  dataReaserchRoutes,
  mediaCenterRoutes,
  publicNoticesRoutes,
  procurementRoutes,
  wingDirectoratesRoutes,
  hospitalsRoutes,
  housingSocietiesSubmenu
} from "../../../../utils/constants";

import "./index.css";

const MobileNavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        backgroundColor="var(--chakra-colors-primary)"
        color="white"
        onClick={onOpen}
      >
        <HamburgerIcon w={5} h={5} />
      </Button>
      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Link href="/" display="block" width="max-content">
              <BaseImage source="logo.svg" width="115px" height="64.49px" />
            </Link>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            {/* drawer Content */}
            <Box display="flex" alignItems="center">
              <Accordion
                width="100%"
                allowMultiple
                allowToggle
                className="mobile-nav-drawer-content-container"
              >
                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>About us</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {AboutusRoutes.map((item, i) => (
                      <Link href={item.link} key={i}>
                        {item.name}
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>Wings & Directorates</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {wingDirectoratesRoutes.map((item, i) => (
                      <Link href={item.link} key={i}>
                        {item.name}
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>E-Services</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {EServicesRoutes.map((item, i) => (
                      <Link key={i} href={item.link}>
                        {item.name}
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>Media Center</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {mediaCenterRoutes.map((item, i) => {
                      if (item.name === "Public Notices") {
                        return (
                          <AccordionItem key={i}>
                            <AccordionButton
                              justifyContent="space-between"
                              className="mobile-menu-btn"
                            >
                              <Box>{item.name}</Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel className="mobile-menu-link-list">
                              {publicNoticesRoutes.map((item, i) => (
                                <Link key={i} href={item.link}>
                                  {item.name}
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }
                      if (item.name === "Procurement") {
                        return (
                          <AccordionItem key={i}>
                            <AccordionButton
                              justifyContent="space-between"
                              className="mobile-menu-btn"
                            >
                              <Box>{item.name}</Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel className="mobile-menu-link-list">
                              {procurementRoutes.map((item, i) => (
                                <Link key={i} href={item.link}>
                                  {item.name}
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }

                      if (item.name === "Housing Societies") {
                        return (
                          <AccordionItem key={i}>
                            <AccordionButton
                              justifyContent="space-between"
                              className="mobile-menu-btn"
                            >
                              <Box>{item.name}</Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel className="mobile-menu-link-list">
                              {housingSocietiesSubmenu.map((item, i) => (
                                <Link key={i} href={item.link}>
                                  {item.name}
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }
                      return (
                        <Link key={i} href={item.link}>
                          {item.name}
                        </Link>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>Legislation</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {LegislationRoutes.map((item, i) => (
                      <Link key={i} href={item.link}>
                        {item.name}
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>Data & Research</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {dataReaserchRoutes.map((item, i) => (
                      <Link key={i} href={item.link}>
                        {item.name}
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton
                    justifyContent="space-between"
                    className="mobile-menu-btn"
                  >
                    <Box>About Islamabad</Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel className="mobile-menu-link-list">
                    {/* {AboutIslamabadRoutes.map((item, i) => (
                      
                      
                      <Link key={i} href={item.link}>
                        {item.name}
                      </Link>
                    ))} */}




{AboutIslamabadRoutes.map((item, i) => {
                      if (item.name === "Hospital of Islamabad") {
                        return (
                          <AccordionItem key={i}>
                            <AccordionButton
                              justifyContent="space-between"
                              className="mobile-menu-btn"
                            >
                              <Box>{item.name}</Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel className="mobile-menu-link-list">
                              {hospitalsRoutes.map((item, i) => (
                                <Link key={i} href={item.link}>
                                  {item.name}
                                </Link>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }
                  
                      return (
                        <Link key={i} href={item.link}>
                          {item.name}
                        </Link>
                      );
                    })}





                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
            {/* drawer Content */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNavBar;
