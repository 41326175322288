import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import {
  auctionNoticesRelatedLinks,
  CdaOrdinanceRelatedInfo,
} from "../../../../utils/constants";
import Searchbar from "../../../../components/Common/Searchbar";
import React, { useEffect, useState } from "react";
import TabHeader from "../../../../components/Common/TabHeader";
import BaseText from "../../../../components/Base/Text";
import ReleateLinksAndInfo from "../../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../../components/Base/PageWraper";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";
import { getPublicNotices } from "../../../../utils/API";
import BaseHeading from "../../../../components/Base/Heading";
import CircularLoader from "../../../../components/Common/CircularLoader";
import Pagination from "../../../../components/Common/Pagination";
import { Box } from "@chakra-ui/react";

function AuctionNotices() {
  const [tab, setTab] = useState("active");
  const [auctionData, setAuctionData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Public Notices",
      link: "#",
    },
    {
      name: "Auction Notices",
      link: "/auction-notices",
    },
  ];

  const keys = {
    summery: "summery",
    pdf: "pdf",
  };

  const headers = {
    summery: "Description",
    pdf: "View Ad",
  };

  const tabsData = [
    {
      title: "Active Auctions",
      content:
        "Capital Development Authority carry out auctions in accordance with prescribed rules and policy.",
      type: "active",
    },
    {
      title: "Archive Auctions",
      content: "",
      type: "archive",
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    const res = await getPublicNotices("auction", tab, pageSize, activePage);
    return res;
  };

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    // setPageSize(event.target.value);

    setPageSize(event);
    setActivePage(1);
  };
  const handleTabChange = (value) => {
    setTab(value);
    setPageSize(10);
    setActivePage(1);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
      fetchData()
        .then((response) => {
          let ans = [];

          response?.data?.map((el) => {
            el.summery = el?.summery || el?.description || "...";
            ans.push(el);
          });
          setAuctionData(ans);

          setPages(Math.ceil(response?.total / pageSize));
        })
        .finally(() => setTableLoading(false));
    }
  }, [tab, searchQuery, pageSize, activePage]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = auctionData?.filter((item) =>
      item?.summery?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setPages(Math.ceil(filteredData?.length/pageSize))
    setAuctionData(filteredData);
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Auction Notices" />
      <PageWraper>
        <TabHeader
          tabsData={tabsData}
          onTabChange={(e) => handleTabChange(e)}
        />
        {tab === "active" ? (
          <BaseText fontSize={"md"} margin="24px 0px 32px 0px">
            Capital Development Authority carry out auctions in accordance with
            prescribed rules and policy.
          </BaseText>
        ) : (
          <></>
        )}
        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          handleSearch={handleSearch}
        />
        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading && auctionData && auctionData?.length > 0 ? (
          <>
            <PaginationTable
              loading={false}
              headers={headers}
              dataSet={auctionData}
              keys={keys}
              marginTop="33px"
              downloadableContent
            />
            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              pages={pages}
              handlePageSelect={(event) => handlePageSelect(event)}
              handlePageSize={(event) => handlePageSize(event)}
              marginTop="50px"
            />
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}

        <ReleateLinksAndInfo
          releatedLinks={auctionNoticesRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(AuctionNotices);
