import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationComponent = ({ type, message, position, customStyle }) => {
  useEffect(() => {
    if (type && message) {
      const options = {
        position: position || "top-right",
        style: customStyle || {}, // Custom styles for the notification
      };

      switch (type) {
        case "success":
          toast.success(message, options);
          break;
        case "error":
          toast.error(message, options);
          break;
        case "warning":
          toast.warning(message, options);
          break;
        default:
          break;
      }
    }
  }, [type, message, position, customStyle]);

  return <ToastContainer />;
};

export default NotificationComponent;