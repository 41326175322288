import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import CardSection from "../../../components/CardSection";

import PageWraper from "../../../components/Base/PageWraper";
import { useEffect, useState } from "react";
import { getHospitalData } from "../../../utils/API";

export default function Hospitals() {
  // const [govetData, setGovetData] = useState([]);
  const [privateData, setPrivateData] = useState([]);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Hospitals",
      link: "/hospitals",
    },
  ];

  const fetchData = async () => {
    const res = await getHospitalData();
    // // const govetHospital = res?.data?.filter(
    // //   (item) => item.hospital_type === "government"
    // // );
    // // setGovetData(govetHospital);
    // // const privateHospital = res?.data?.filter(
    // //   (item) => item.hospital_type === "private"
    // // );
    // setPrivateData(privateHospital);
    setPrivateData(res.data)
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Hospitals" />

      <PageWraper>
        {/* <CardSection title="Government Hospitals" data={govetData} hospital /> */}
        <CardSection 
        // title="Private Hospitals" 
        data={privateData} hospital />
      </PageWraper>
    </>
  );
}
