import React, { memo } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { MAPS_API_KEY } from "../../../config";

const MapComponent = ({
  children,
  mapOptions,
  defaultCenter,
  defaultZoom = 11,
  satelliteView = false,
  ...otherProps
}) => {
  const mapsApiKey = MAPS_API_KEY;
  const defaultProps = {
    center: defaultCenter,
    zoom: defaultZoom,
  };

  const defaultMapOptions = (maps) => {
    return {
      // zoomControl: !isMDScreen,
      // zoomControlOptions: {
      //   // position: google.maps.ControlPosition.RIGHT_CENTER,
      //   position: google.maps.ControlPosition.RIGHT_BOTTOM,
      // },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      //  mapTypeControl: satelliteView,

      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      ...mapOptions,
    };
  };

  return (
    <GoogleMapReact
      options={defaultMapOptions}
      bootstrapURLKeys={{ key: mapsApiKey, authReferrerPolicy: "origin" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      yesIWantToUseGoogleMapApiInternals
      {...otherProps}
    >
      {children}
    </GoogleMapReact>
  );
};

MapComponent.propTypes = {
  defaultCenter: PropTypes.node.isRequired,
  defaultZoom: PropTypes.node.isRequired,
};
MapComponent.defaultProps = {
  children: <></>,
};

export default memo(MapComponent);
