import React from "react";
import "./style.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Image
} from "@chakra-ui/react";


const GalleryModal = ({ isOpen, onClose, imageUrl,  isSmallScreen }) => {
  return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image className={isSmallScreen? "imgONSmallScreen" : ""} src={imageUrl} alt="Image" width="100%" height="99vh" />
          </ModalBody>
        </ModalContent>
      </Modal>

  );
};

export default GalleryModal;
