import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";

export default function EngineeringWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Engineering Wing",
      link: "/engineering-wing",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Engineering Wing" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Engineering Wing</BaseHeading>
              <BaseText fontSize="md">
                Engineering wing is involved in all the electrical and
                mechanical works of the city. It also undertakes all new
                construction works which are either self-financed by CDA or
                funded by any other government department/office. The wing is
                responsible for the production, treatment, conduction and
                distribution of potable water from different surfaces as well as
                groundwater resources to the end-user. The wing also looks after
                the maintenance of roads, footpaths, drainage systems and
                landscaping of commercial areas of the city.
              </BaseText>
            </Flex>
            <WingsAccordion type={"engineering"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
