import { Flex,Box} from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import TabHeader from "../../../components/Common/TabHeader";

import { useEffect, useState } from "react";

import BaseHeading from "../../../components/Base/Heading";
import PageWraper from "../../../components/Base/PageWraper";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import BaseText from "../../../components/Base/Text";
import { getProjects } from "../../../utils/API";
import CircularLoader from "../../../components/Common/CircularLoader";
import { formatNumber } from "../../../utils/helpers";

import Searchbar from "../../../components/Common/Searchbar";

function Projects() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA Projects",
      link: "/cda-projects",
    },
  ];

  
  const [searchQuery, setSearchQuery] = useState("");


  const [tab, setTab] = useState("completed");
  const [projectData, setProjectData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const tabsData = [
    {
      title: "Completed Projects",
      content: "Tab content for Completed Projects",
      type: "completed",
    },
    {
      title: "Projects in Progress",
      content: "Tab content for Projects in Progress",
      type: "in_progress",
    },
    {
      title: "Future Planned Projects",
      content: "Tab content for Future Planned Projects",
      type: "in_future",
    },
  ];


  const keys = {
    id: "id",
    description: "description",
    cost: "cost",
  };

  const headers = {
    id: "Sr#",
    description: "Description",
    cost: "Project Cost",
  };

  const fetchData = async () => {
    setTableLoading(true);
    const data = await getProjects(tab);
    return data;
  };
  

  // const convertCurrency = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "PKR",
  // });


  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
      fetchData()
      .then((response) =>
        setProjectData(
          response.map((el, i) => {
            el.cost = formatNumber(el?.cost);
            el.id = i + 1 + ".";
            return el;
          })
        )
      )
      .finally(() => setTableLoading(false));
    }
  }, [searchQuery,tab]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = projectData?.filter((item) =>
      item?.description?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setProjectData(filteredData);
  };


  const handleTabChange = (value) => {
    setTab(value);;
    setSearchQuery("");
  };
  // const renderMessage = "Loading RWR leads...";

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Projects" />
      <PageWraper gap="40px">
        <TabHeader tabsData={tabsData} 
        
         onTabChange={(e) => handleTabChange(e)} 
        //  onTabChange={setTab}
         />
        <Flex gap="33px" flexDirection="column"> 
          <BaseHeading fontSize="xl">
            {tab === "completed"
              ? "Major Projects Completed in Five Years"
              : tab === "in_progress"
              ? "Major Projects in progress"
              : "Future Planned Projects"}
          </BaseHeading>

          <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={handleSearch}
        />

          {tableLoading ? (
            <CircularLoader />
          ) : projectData?.length > 0 ? (
            <PaginationTable
              loading={tableLoading}
              headers={headers}
              dataSet={projectData}
              keys={keys}
            />
          ): (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}
        </Flex>
        <BaseText color="secondary" textAlign="right" marginTop="32px">
          Last Updated on Jan 24th, 2023
        </BaseText>
      </PageWraper>
    </>
  );
}

export default withTranslation()(Projects);
