import { useState, useEffect } from "react";


export function ellipsis(item) {
  return item.length > 55 ? `${item.substring(0, 54)}...` : item;
}

export function ellipsisLong(item) {
  return item.length > 155 ? `${item.substring(0, 154)}...` : item;
}

export const getAlbumbType = (item) => {
  if (item === "current") {
    return "Islamabad Through Pictures";
  }
  if (item === "history") {
    return "Islamabad’s Historical Photos";
  }
  if (item === "tree") {
    return "Chinese PM Li Keqiang's Tree Plantation at Shakarparian";
  }
  return item;
};

export const stringToHtml = (str) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: str
          ?.toString()
          ?.split("\\n")
          ?.join("")
          ?.replace(/&nbsp;/g, " "),
      }}
    ></div>
  );
};

export const htmlParser = (str) => {
  return (
    str && str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
  );
};

//export csv

export const exportToCSV = (array) => {
  const zones = Object.keys(array);
  const zonesData = Object.values(array);

  const csvContent =
    "SR#,Zone Number" +
    zones?.map(
      (zoneNumber, i) =>
        `\n,${zoneNumber}\n` +
        zonesData[i]?.map((row, index) => `${index + 1},"${row}"`).join("\n")
    );

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "Illegal Housing Schemes.csv";
  document.body.appendChild(a);
  a.click();
  document?.body?.removeChild(a);
};

export const exportToCSVHousingSchemes = (array) => {
  const csvContent =
    "Name,Approval Status,NOC Status\n" +
    array
      .map(
        (scheme) =>
          `"${scheme.Name}",${scheme.LOP || "Not Known"},${
            scheme.NOC || "Not Available"
          }`
      )
      .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "HousingSchemes.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// getScreenWidth.js
export const useScreenWidth = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 870);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 870);
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run this effect only once on mount

  return { isSmallScreen, screenSize };
};

export const getStaticImagesObject = (url, id, type, name) => {
  return {
    image: url,
    id,
    link: url,
    name,
    type,
  };
};

export const capitalizeFirstLetter = (string) => {
  if (string && string.length > 1)
    return string.charAt(0).toUpperCase() + string.slice(1);
  return "";
};

export const getSpacedCamelCase = (name) => {
  const value = name?.replace(/([a-z])([A-Z])/g, "$1 $2");
  return value;
};

export const camelCaseToKebabCase = (token) => {
  try {
    return getSpacedCamelCase(token)
      ?.toLowerCase()
      ?.split(" ")
      ?.join("-")
      ?.trim();
  } catch (err) {
    console.log(err);
    return token;
  }
};

export function kebabToCapitalize(str) {
  // Replace hyphens and underscores with spaces
  var stringWithSpaces = str.replace(/[-_]/g, " ");

  // Split the string into an array of words
  var words = stringWithSpaces.split(" ");

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together with spaces
  var capitalizedString = capitalizedWords.join(" ");

  return capitalizedString;
}

export const formatNumber = (number) => {
  // Convert the number to a float with two decimal places
  const formattedNumber = parseFloat(number).toFixed(2);

  // Check if the number is in billions (B) range
  if (Math.abs(number) >= 1e9) {
    return "PKR " + (formattedNumber / 1e9).toFixed(2) + "(Bn)";
  }
  // Check if the number is in millions (M) range and less than 1 billion
  else if (Math.abs(number) >= 1e6 && Math.abs(number) < 1e9) {
    return "PKR " + (formattedNumber / 1e6).toFixed(2) + "(M)";
  }
  // If the number is less than a million but greater than or equal to 1000, display it as thousands (K)
  else if (Math.abs(number) >= 1e3) {
    return "PKR " + (formattedNumber / 1e3).toFixed(2) + "(K)";
  }
  // If the number is less than a thousand, no conversion needed
  else {
    return "PKR " + formattedNumber;
  }
};

export const getOrdinal = (number) => {
  const lastDigit = number % 10;
  const suffix =
    lastDigit === 1
      ? "<sup>st</sup>"
      : lastDigit === 2
      ? "<sup>nd</sup>"
      : lastDigit === 3
      ? "<sup>rd</sup>"
      : "<sup>th</sup>";
  if (number >= 11 && number <= 13) {
    return number + "<sup>th</sup>";
  }
  return number + suffix;
};

export const convertToPKT = (gmtDateString, meeting) => {
  // Create a new Date object from the GMT date string
  const gmtDate = new Date(gmtDateString);
  if (meeting) {
    gmtDate.setDate(gmtDate.getDate());
  }
  // Adjust the time zone offset for Pakistan Standard Time (PKT)
  const pktDate = new Date(gmtDate.getTime() + (5 * 60 + 0) * 60000);
  // Options for formatting the date (excluding day)
  const optionsWithoutDay = {
    month: "short",
    year: "numeric",
  };
  // Get the formatted date without the day
  const formattedDateWithoutDay = pktDate.toLocaleDateString(
    "en-PK",
    optionsWithoutDay
  );

  // Get the day from the date
  const day = pktDate.getDate();
  // Append the day with suffix to the formatted date
  const formattedDate = getOrdinal(day) + " " + formattedDateWithoutDay;

  return formattedDate;
};

export const formateNumber = (number) => {
  const formattedNumber = Number(number)?.toLocaleString("en-US", {
    style: "currency",
    currency: "PKR",
  });

  if( formattedNumber=== 'PKRNaN')
  {
    return "PKR 0"
  }else{
    return formattedNumber;
  }
  
};
