import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import CardSection from "../../../components/CardSection";
import { hospitality } from "../../../utils/constants";
import PageWraper from "../../../components/Base/PageWraper";

export default function Hospitality() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Hospitality",
      link: "/hospitality",
    },
  ];

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Hospitality" />
      <PageWraper>
        <CardSection data={hospitality} />
      </PageWraper>
    </>
  );
}
