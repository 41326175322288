import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";

import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";

export default function ChairmanSecretariat() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Chairman Secretariat",
      link: "/chairman-secretariat",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Chairman Secretariat" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              {/* <BaseHeading fontSize="xl">Chairman Secretariat</BaseHeading> */}
              {/* <BaseText fontSize="md">
                The wing is responsible for the preparation of Islamabad Land
                Disposal Regulation along with the opening of new sectors in
                Islamabad. The wing deals with all matters relating to
                allotment/transfer of plots & property under the Land Disposal
                Regulations. It caters for the allotment of residential plots
                through ballot and open auction. The wing also handles the legal
                matters pertaining to the Authority.
              </BaseText> */}
            </Flex>
            <WingsAccordion type={"chairman"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
