import { Flex } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import { stringToHtml } from "../../utils/helpers";
import BaseHeading from "../Base/Heading";
import BaseLink from "../Base/Link";
import BaseText from "../Base/Text";
import "./index.css";
function SearchCard({ data, ...otherProps }) {
  return (
    <>
      {data?.map((item, i) => (
        <Flex {...otherProps} key={i} className="search-card-container">
          <BaseHeading fontSize="lg">{item?.title}</BaseHeading>
          <BaseText fontSize="md" textAlign="justify">
            {/* {stringToHtml(item?.description)} */}
            {stringToHtml(item?.htmlSnippet
)}
          </BaseText>
          <BaseLink
            color="primary"
            fontSize="md"
            fontWeight="600"
            href={item?.link}
            marginTop="8px"
          >
            READ MORE
          </BaseLink>
        </Flex>
      ))}
    </>
  );
}
 export default withTranslation()(SearchCard);


 

// import React, { useState } from 'react';

// const SearchableContent = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [searchResults, setSearchResults] = useState([]);

//   const handleSearch = () => {
//     const elements = document.querySelectorAll('h1, h2, p, div');
//     const results = [];

//     elements.forEach(element => {
//       const text = element.innerText.toLowerCase();
//       if (text.includes(searchTerm.toLowerCase())) {
//         results.push({ text, element });
//       }
//     });

//     setSearchResults(results);
//   };

//   const handleInputChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const scrollToResult = (element) => {
//     element.scrollIntoView({ behavior: "smooth", block: "start" });
//   };

//   return (
//     <div>
//       <input type="text" value={searchTerm} onChange={handleInputChange} />
//       <button onClick={handleSearch}>Search</button>
//       <hr />
//       {searchResults.map((result, index) => (
//         <div key={index}>
//           <p>Search result {index + 1}:</p>
//           <p>{result.text}</p>
//           <button onClick={() => scrollToResult(result.element)}>Go to Result</button>
//         </div>
//       ))}
//       {searchResults.length === 0 && searchTerm && <p>No results found.</p>}
//     </div>
//   );
// };

// export default SearchableContent;
