import React, { useState } from "react";
import "./index.css";
import { MapContainer, TileLayer } from "react-leaflet";
import WMSLayers from "./WMSLayers";
import { WMSTileLayer } from "react-leaflet";
import { useMapEvents } from 'react-leaflet/hooks'

const position = [33.6853, 73.0451];
const mapStyle = { height: "80vh" };

const Leaflet = ({ ...props }) => {
  const [leafletZoom,setLeafletZoom] = useState(10);
  return (
    <div id="map" {...props}>
      <MapContainer
        center={position}
        style={mapStyle}
        minZoom={9}
        maxZoom={18}
        zoom={10}
        attributionControl={false}
      >

<MapEvents
                            setCentroidHandle={(c)=>{}}
                            setMapzoomHandle={(zoom)=>{setLeafletZoom(zoom)}}
                            setMaphookHandle={(maph)=>{
                               // setMaphook(maph)
                            }}
                        />
        {(leafletZoom >2 && leafletZoom <= 10) ? (
          <WMSTileLayer
          key={1}
          url={"https://geoserver.cda.gov.pk/geoserver/CDA/wms"}
          version="1.1.0"
          layers={"CDA:zones"}
          transparent="false"
          opacity="0.7"
          format="image/png"
          zIndex={10}
          maxZoom={23}
        />
        ):(
<WMSTileLayer
           key={2}
           url={"https://geoserver.cda.gov.pk/geoserver/CDA/wms"}
           version="1.1.0"
           layers={"CDA:housing_scheme"}
           transparent="false"
           opacity="0.7"
           format="image/png"
           zIndex={10}
           maxZoom={23}
         />
        )}
      
        <WMSLayers plotfilter={"all"} />

        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" zIndex={"0"} />
      </MapContainer>
    </div>
  );
};

const MapEvents = (props)=>{

  const map = useMapEvents({
      zoomend: ()=>{
          console.log(map.getZoom(),'map.getZoom()')
          props.setMapzoomHandle(map.getZoom());
      },
      click : (e)=>{
          props.setCentroidHandle(e.latlng);
      }
  });
  props.setMaphookHandle(map);
  return null
}

export default Leaflet;