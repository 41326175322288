import { Box, Flex, Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseHeading from "../../../components/Base/Heading";
import BaseText from "../../../components/Base/Text"
import { sortBy } from "../../../utils/constants";

import BaseMenuButton from "../../../components/Base/MenuButton";
import "./style.css";

import PageWraper from "../../../components/Base/PageWraper";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import { getAllJobs } from "../../../utils/API";
import { useEffect, useState } from "react";
import { convertToPKT, getOrdinal } from "../../../utils/helpers";
import CircularLoader from "../../../components/Common/CircularLoader";
import Pagination from "../../../components/Common/Pagination";

function BoardMeetings() {
  const currentDate = new Date();

  const [allJobsData, setAllJobsData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedSortByOrder, setSelectedSortByOrder] = useState(
    // `${currentDate.getFullYear()}`
    'Most Recent'
  );
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Careers",
      link: "/careers-and-jobs",
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    // const data = await getBoardMeetings(selectedSortByOrder, activePage, pageSize);
    const data = await getAllJobs(2023, activePage, pageSize);
    return data;
  };

  const handleChange = (e) => {
    if (selectedSortByOrder !== e?.target?.innerHTML) {
      setSelectedSortByOrder(e?.target?.innerHTML);
      setPageSize(10);
      setActivePage(1);
    }
  };



  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    setPageSize(event);
    setActivePage(1);
  };

  // ${i?.order ? i?.order : ""}

  useEffect(() => {
    fetchData()
      .then((response) => {
        let final = response?.map((i, index) => {
          i.date =
            i?.is_feature === "true"
              ? "Special Meeting"
              : convertToPKT(i?.date, "meeting");
          i.summery = `${i?.summery} ${i?.year}`;
          return i;
        });
        setAllJobsData(final);
        setPages(Math.ceil(response?.length / pageSize));
      })
      .finally(() => setTableLoading(false));
  }, [selectedSortByOrder, activePage, pageSize]);

  const keys = {
    title : "title",
    type: "type",
    due_date: "due_date",
    id: "id",
  };

  // const headers = {
  //   date: "Date",
  //   summery: "Minutes of Meeting",
  //   pdf: "Download",
  // };


  const headers = {
    jobTitle : "Job Title",
    jobDescription: "Job Description",
    dueDate: "Due Date",
    details : "Details ",

  };
  // const keys = {
  //   name: "Name",
  //   bps: "BPS",
  //   subjectTeaching: "Subject Teaching",
  //   lastDegree: "last Degree ",
  //   institutionAttended: "Institution Attended",
  //   domicile: "Domicile",
  // };
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Careers And Jobs" />
      <PageWraper>
        <Flex className="pageWrapperInner"  flexDirection="column">

        <Flex className="header">
          <Flex gap="15px" flexDirection="column" alignItems="baseline">
          <BaseHeading className="jobHeading"  fontSize="2xl" color="secondary">
              Job Openings
            </BaseHeading>

            <BaseHeading fontWeight="normal" fontSize="md" color="secondary">
            Please click on "Apply Now" to proceed with Job application.
            </BaseHeading>


            <Flex>


<BaseText  className="cdaEmailLink" style={{whiteSpace: "normal"}}  fontSize={"md"}>
For any query, please email at
<a
href="mailto:jobs@cda.gov.pk"
style={{ color: "#4EA858", fontWeight: "600" }}
>
&nbsp;jobs@cda.gov.pk
</a>
</BaseText>
</Flex>
          </Flex>
          <Flex className="sortBySection" gap="24px" alignItems="center">
            <BaseHeading className="sortBy"  color="secondary">
              Sort by
            </BaseHeading>
            <Menu>
              <BaseMenuButton className="selectOrderBy"  variant="outlined">
                {selectedSortByOrder}
              </BaseMenuButton>
              <MenuList  height="101px" overflow="scroll">
                {sortBy
                  ?.slice()
                  ?.reverse()
                  ?.map((item) => (
                    <MenuItem
                      _focus={{ backgroundColor: "transparent" }}
                      _hover={{ backgroundColor: "var(--chakra-colors-grey3)" }}
                      key={item}
                      onClick={(e) => handleChange(e)}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </Flex>
          </Flex>



          {tableLoading ? (
            <CircularLoader />
          ) : !tableLoading && allJobsData?.length > 0 ? (
            <>
              <PaginationTable
                loading={false}
                headers={headers}
                dataSet={allJobsData}
                keys={keys}
                applyNowEnable 
                withPagination={false}
                applyNowOnClick
                meetings
              />
              {/* <Pagination
                activePage={activePage}
                pageSize={pageSize}
                pages={pages}
                handlePageSelect={(event) => handlePageSelect(event)}
                handlePageSize={(event) => handlePageSize(event)}
                marginTop="50px"
              /> */}
            </>
          ) : (
            <Box margin="140px auto" width="max-content">
              <BaseHeading fontSize="2xl" color="secondary">
              Information not available
              </BaseHeading>
            </Box>
          )}
        </Flex>
      </PageWraper>
    </>
  );
}

export default withTranslation()(BoardMeetings);
