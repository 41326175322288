import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Box,
} from "@chakra-ui/react";
import BaseHeading from "../../Base/Heading";
import BaseText from "../../Base/Text";
import Card from "../../Common/Card";
import BaseImage from "../../Base/Image";

export default function Departments({ data }) {
  return (
    <Flex gap="16px" flexDirection="column">
      <BaseText fontSize="md">
        Following is the list of departments working in Capital Hospital.
      </BaseText>
      <Accordion allowToggle>
        <Flex gap="16px" flexDirection="column">
          {data.map((i) => (
            <AccordionItem
              border="1px solid var(--chakra-colors-grey3)"
              borderRadius="8px"
            >
              <AccordionButton paddingY="24px" paddingX="16px">
                <Box textAlign="left" marginRight="14px">
                  <BaseImage source={i.icon} width="32px" height="32px" />
                </Box>

                <Box as="span" flex="1" textAlign="left">
                  <BaseHeading fontSize="lg">{i.title}</BaseHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex gap="24px" direction="column">
                  <Flex gap="16px" direction="column">
                    <BaseHeading fontSize="md">Description</BaseHeading>
                    <BaseText fontSize="md">{i.description}</BaseText>
                  </Flex>
                  <Flex gap="16px" direction="column">
                    <BaseHeading fontSize="md">Key Persons</BaseHeading>

                    <Flex gap="50px" flexWrap="wrap">
                      {i.keyPersons.map((person) => (
                        <Card data={person} />
                      ))}
                    </Flex>

                    {/* <UnorderedList>
                      <Flex gap="16px" direction="column">
                        {i.keyPersons.map((el) => (
                          <ListItem>
                            <BaseText fontSize="md">{el}</BaseText>
                          </ListItem>
                        ))}
                      </Flex>
                    </UnorderedList> */}
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>
    </Flex>
  );
}
