import BaseText from "../../Base/Text";
import { Link } from "@chakra-ui/react";
export default function ShowDownloadLink({ title }) {
  return (
    <BaseText fontSize="sm" textAlign="right">
      {title}
      <Link color="primary" href="/forms-and-applications">
        &nbsp;Click Here
      </Link>
    </BaseText>
  );
}
