import { Flex } from "@chakra-ui/react";
import { facuityData, facultyData } from "../../../utils/constants";
import BaseHeading from "../../Base/Heading";

import PaginationTable from "../../Common/Table/PaginationTable";

export default function Faculty() {
  const headers = {
    name: "Name",
    bps: "BPS",
    subjectTeaching: "Subject Teaching",
    lastDegree: "last Degree ",
    institutionAttended: "Institution Attended",
    domicile: "Domicile",
  };
  const keys = {
    name: "Name",
    bps: "BPS",
    subjectTeaching: "Subject Teaching",
    lastDegree: "last Degree ",
    institutionAttended: "Institution Attended",
    domicile: "Domicile",
  };
  return (
    <Flex gap="24px" flexDirection="column">
      <BaseHeading fontSize="lg">Faculty and Staff</BaseHeading>
      <PaginationTable
        loading={false}
        headers={headers}
        dataSet={facultyData}
        keys={keys}
      />
    </Flex>
  );
}
