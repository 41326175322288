import { Box } from "@chakra-ui/react";
import React from "react";
import { WMSTileLayer, useMapEvents } from "react-leaflet";

function WMSLayers(props) {
  const [zoomLevel, setZoomLevel] = React.useState(10); // initial zoom level provided for MapContainer

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });
  const geoserverbase = "https://cdaadmin.graana.rocks/geoserver/CDA/wms";
  const geoserverStore = "CDA:";
  const layers = [
    { name: "zones", zoomstart: 9, zoomend: 11.7 },
    { name: "housing_scheme", zoomstart: 12, zoomend: 14 },
  ];

  return (
    <>
      {layers.map((obj, i) => (
        <Box key={i}>
          {zoomLevel >= obj.zoomstart && zoomLevel <= obj.zoomend ? (
            <WMSTileLayer
              url={geoserverbase}
              version="1.1.0"
              layers={geoserverStore + obj.name}
              transparent="true"
              opacity="0.7"
              format="image/png"
              zIndex={10}
              maxZoom={23}
            />
          ) : zoomLevel >= 17 ? (
            <>
              {props.plotfilter === "all" && (
                <>
                  <WMSTileLayer
                    url={geoserverbase}
                    version="1.1.0"
                    layers={geoserverStore + "plot"}
                    transparent="true"
                    opacity="0.4"
                    format="image/png"
                    zIndex={10}
                    maxZoom={23}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      ))}
    </>
  );
}

export default WMSLayers;
