import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import "./style.css";

import CircularLoader from "../../../components/Common/CircularLoader";
import {
  CdaOrdinanceRelatedInfo,
  CdaOrdinanceRelatedLinks,
} from "../../../utils/constants";
import ReleateLinksAndInfo from "../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../components/Base/PageWraper";
import BaseHeading from "../../../components/Base/Heading";
import { getFormsApplications } from "../../../utils/API";
import { useEffect, useState } from "react";
import { kebabToCapitalize } from "../../../utils/helpers";
import Searchbar from "../../../components/Common/Searchbar";

function FormsAndApplications() {
  const [originalData, setOriginalData] = useState([]); // Store the original data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTemp, setDataTemp] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Legislation",
      link: "#",
    },
    {
      name: "Forms and Applications",
      link: "/forms-and-applications",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const res = await getFormsApplications();
    setLoading(false);
    delete res?.data["by_laws_and_regulations"];
    setOriginalData(res?.data); // Save the original data
    setData(res?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    handleSearch(); // Trigger search when searchQuery changes
  }, [searchQuery]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      // setData(originalData); // Reset data to original data if search query is empty
      setData(originalData); // Reset data to original data if search query is empty
      return;
    }

    const filteredData = {};
    // Iterate through each key-value pair in the originalData object
    Object.entries(originalData).forEach(([key, value]) => {
      // Check if the category name matches the search query
      if (key.toLowerCase().includes(searchQuery.toLowerCase())) {
        // If the category name matches, add the whole array to the filtered data
        filteredData[key] = value;
      } else {
        // Otherwise, filter the array based on the provided search query
        const filteredItems = value.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        // If any matches are found, add them to the filteredData object
        if (filteredItems.length > 0) {
          filteredData[key] = filteredItems;
        }
      }
    });

    setDataTemp(filteredData);
  };


  const handleClick = () => {
    setData(dataTemp);
  };

  const values = Object.values(data);
  const keys = Object.keys(data);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Forms and Applications" />
      <PageWraper>

      <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={ handleClick}
        />
        {loading ? (
          <CircularLoader />
        ) : (
          <Flex direction="column" gap="24px" marginTop={'20px'}>
            {values?.length > 0 &&
              values?.map((el, index) => {
                return (
                  <Flex  direction="column" gap="16px" key={index}>
                    <BaseHeading fontSize="xl">
                      {kebabToCapitalize(keys[index])}
                    </BaseHeading>

                    <Grid className="downloadContainer form-app-download-container">
                      {el?.map((i, index) => (
                        <GridItem colSpan={1} key={index}>
                          <PdfDownloader
                            title={kebabToCapitalize(i?.title)}
                            fileSize={i?.fileSize}
                            height="50.74px"
                            width="100%"
                            justifyContent="left"
                            link={i?.attachment}
                            fileIndex={index+1}
                          />
                        </GridItem>
                      ))}
                    </Grid>
                  </Flex>
                );
              })}
          </Flex>
        )}
        <ReleateLinksAndInfo
          releatedLinks={CdaOrdinanceRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(FormsAndApplications);
