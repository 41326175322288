import PageWraper from "../../components/Base/PageWraper";
import Searchbar from "../../components/Common/Searchbar";
import { withTranslation } from "react-i18next";
import BaseHeading from "../../components/Base/Heading";
import BaseText from "../../components/Base/Text";
import { Box, Flex } from "@chakra-ui/react";
import SearchCard from "../../components/SearchCard";
import { useEffect, useState } from "react";
import { getSearchData } from "../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalSearch } from "../../reduxToolkit/slices/globalSearch";
import CircularLoader from "../../components/Common/CircularLoader";

import Siteurls from "./urls.json"

function SearchPage() {
  const query = useSelector((state) => state?.globalSearch?.searchData);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [found, setFound] = useState("noSearch");
  const [loading, setLoading] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false); // New state variable
  const [HeadsearchInitiated, setHeadSearchInitiated] = useState(false); // New state variable
  const dispatch = useDispatch();

  const fetchData = async (query) => {
    setLoading(true);
    const res = await getSearchData(query);
    setLoading(false); // Set loading to false when API call is completed
    return res?.data;
  };

  const handleSearch = () => {
    setSearchInitiated(true); // Set search initiated to true when search is triggered
  };


  useEffect(() => {
    const currentHost = window.location.origin;


    if (searchInitiated) { // Check if search is initiated
      setSearchInitiated(false); // Reset search initiated flag
      fetchData(searchQuery)
        // .then((res) => {
        //
        //   const modifiedItems = res?.items?.map(item => {
        //     // Parse the link to extract the path
        //     const url = new URL(item.link);
        //
        //
        //     console.log("data",url.pathname)
        //
        //     // Construct a new URL with the desired origin
        //     const newURL = `${currentHost}${url.pathname}`;
        //     // Update the link in the item
        //     item.link = newURL;
        //     return item;
        //   })
        //   setData(modifiedItems);
        //   // Modify links in the respons
        //   if (res?.items?.length > 0) {
        //     setFound("found");
        //   } else {
        //     setFound("notFound");
        //   }
        // })


          .then((res) => {
            const modifiedItems = res?.items?.filter(item => {
              // Parse the link to extract the path
              const url = new URL(item.link);
              const pathname = url.pathname;
              // Check if the pathname exists in the Siteurls array
              return Siteurls.includes(pathname);
            }).map(item => {
              // Parse the link to extract the path
              const url = new URL(item.link);
              // Construct a new URL with the desired origin
              const newURL = `${currentHost}${url.pathname}`;
              // Update the link in the item
              item.link = newURL;
              return item;
            });

            setData(modifiedItems);

            // Modify links in the response
            if (modifiedItems.length > 0) {
              setFound("found");
            } else {
              setFound("notFound");
            }
          })


        .catch((error) => {
          console.error("Error fetching data:", error);
          setFound("notFound");
          setData([]); // Clear data in case of error
        });
    }
  }, [searchInitiated]); // Depend on searchInitiated



  useEffect(() => {
    if (query !== "") {
      setSearchQuery(query);
      setSearchInitiated(true);
      // dispatch(setGlobalSearch(""));
    }
  }, [query]);

  const handleChange = (e) => {
    setFound("noSearch");
    setSearchQuery(e.target.value);
  };


  return (
    <PageWraper>
      <Flex gap="32px" flexDirection="column">
        {found !== "noSearch" ? (
          <BaseHeading color="grey1" fontSize="2xl" display="flex">
            Showing results for &nbsp;
            <BaseText
              color="secondary"
              fontWeight="semi-bold"
            >{`“${searchQuery}”`}</BaseText>
          </BaseHeading>
        ) : (
          <></>
        )}

        <Searchbar
          value={searchQuery}
          onChange={(e) => handleChange(e)}
          handleSearch={handleSearch}
        />

        {loading ? (
          <CircularLoader />
        ) : data?.length > 0 ? (
          <SearchCard data={data} />
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
              No Records Found
            </BaseHeading>
          </Box>
        )}
      </Flex>
    </PageWraper>
  );
}

export default withTranslation()(SearchPage);
