import { Heading } from "@chakra-ui/react";
import { Trans, withTranslation } from "react-i18next";

function BaseHeading({ children, color, ...otherprops }) {
  return (
    <Heading {...otherprops} color={color || "secondary"}  style={{ whiteSpace: "normal" }} >
      <Trans>{children}</Trans>
    </Heading>
  );
}

export default withTranslation()(BaseHeading);
