import {
  Box,
  Flex,
  GridItem,
  ListItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseHeading from "../../../components/Base/Heading";
import { useEffect, useState } from "react";

import PageHeader from "../../../components/Common/PageHeader";
import Paragraph from "../../../components/Common/Paragraph";
import PageWraper from "../../../components/Base/PageWraper";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import TabHeader from "../../../components/Common/TabHeader";
import "./index.css";
import {
  getBugetExpenditures,
  getBugetReceipts,
  getPreviousBudget,
} from "../../../utils/API";
import CircularLoader from "../../../components/Common/CircularLoader";
import { formateNumber } from "../../../utils/helpers";
function CdaBudget() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA Budget",
      link: "/cda-budget",
    },
  ];

  const [tab, setTab] = useState("receipts");
  const [previousBudget, setPreviousBudget] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expendituresData, setExpendituresData] = useState([]);
  const [receiptsData, setReceiptstData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const fetchBudgetReceipts = async () => {
    setTableLoading(true);
    const data = await getBugetReceipts();
    return data;
  };

  const fetchBugetExpenditure = async () => {
    setTableLoading(true);
    const data = await getBugetExpenditures();
    return data;
  };

  // total receipt
  const Total_Receipts_Budget =
    Number(receiptsData?.self_financing_budget) +
    Number(receiptsData?.revenue_budget) +
    Number(receiptsData?.cda_fund_budget) +
    Number(receiptsData?.funds_transferred_mci_budget) +
    Number(receiptsData?.received_amount_budget);
  const Total_Receipts_Revised =
    Number(receiptsData?.self_financing_revised) +
    Number(receiptsData?.revenue_revised) +
    Number(receiptsData?.cda_fund_revised) +
    Number(receiptsData?.funds_transferred_mci_revised) +
    Number(receiptsData?.received_amount_revised);
  const Total_Receipts_Proposed =
    Number(receiptsData?.self_financing_proposed) +
    Number(receiptsData?.revenue_proposed) +
    Number(receiptsData?.cda_fund_proposed) +
    Number(receiptsData?.funds_transferred_mci_proposed) +
    Number(receiptsData?.received_amount_proposed);
  // total expenditure
  const Total_Expenditures_Budget =
    Number(expendituresData?.self_financing_budget) +
    Number(expendituresData?.revenue_budget) +
    Number(expendituresData?.loan_mci_budget) +
    Number(expendituresData?.payable_fed_gov_budget);
  const Total_Expenditures_Revised =
    Number(expendituresData?.self_financing_revised) +
    Number(expendituresData?.revenue_revised) +
    Number(expendituresData?.loan_mci_revised) +
    Number(expendituresData?.payable_fed_gov_revised);
  const Total_Expenditures_Proposed =
    Number(expendituresData?.self_financing_proposed) +
    Number(expendituresData?.revenue_proposed) +
    Number(expendituresData?.loan_mci_proposed) +
    Number(expendituresData?.payable_fed_gov_proposed);

  //Surplus / Deficit
  const surplus_Budget = Total_Receipts_Budget - Total_Expenditures_Budget;
  const surplus_Revised = Total_Receipts_Revised - Total_Expenditures_Revised;
  const surplus_Proposed =
    Total_Receipts_Proposed - Total_Expenditures_Proposed;

  function getNextChar(char, i) {
    return String.fromCharCode(char.charCodeAt(0) + i);
  }
  useEffect(() => {
    if (tab === "receipts") {
      fetchBudgetReceipts()
        .then((response) => {
          // if (response && response.length > 0) {
          if (response) {
          let data = response;
          data.from_year = response.length > 0 ? response[0]?.from_year : "";
          data.to_year = response.length > 0 ? response[0]?.to_year : "";

          data.self_financing_budget = response[0]?.sfb;
          data.self_financing_revised = response[0]?.sfr;
          data.self_financing_proposed = response[0]?.sfp;

          data.revenue_budget = response[1]?.sfb;
          data.revenue_revised = response[1]?.sfr;
          data.revenue_proposed = response[1]?.sfp;

          data.cda_fund_budget = response[2]?.sfb;
          data.cda_fund_revised = response[2]?.sfr;
          data.cda_fund_proposed = response[2]?.sfp;

          data.funds_transferred_mci_budget = response[3]?.sfb;
          data.funds_transferred_mci_revised = response[3]?.sfr;
          data.funds_transferred_mci_proposed = response[3]?.sfp;

          data.received_amount_budget = response[4]?.sfb;
          data.received_amount_revised = response[4]?.sfr;
          data.received_amount_proposed = response[4]?.sfp;

          if (response.length > 5) {
            data.onwards = response.slice(5);
          }
          setReceiptstData(data);
        }
        })
        .finally(() => { setTimeout(() => {
          setTableLoading(false)
        }, 600)
      })
    } else {
      fetchBugetExpenditure()
        .then((response) => {
          if (response && response.length > 0) {
          let data = response;
          data.from_year = response.length > 0 ? response[0]?.from_year : "";
          data.to_year = response.length > 0 ? response[0]?.to_year : "";

          data.self_financing_budget = response[0]?.sfb;
          data.self_financing_revised = response[0]?.sfr;
          data.self_financing_proposed = response[0]?.sfp;

          data.revenue_budget = response[1]?.sfb;
          data.revenue_revised = response[1]?.sfr;
          data.revenue_proposed = response[1]?.sfp;

          data.loan_mci_budget = response[2]?.sfb;
          data.loan_mci_revised = response[2]?.sfr;
          data.loan_mci_proposed = response[2]?.sfp;

          data.payable_fed_gov_budget = response[3]?.sfb;
          data.payable_fed_gov_revised = response[3]?.sfr;
          data.payable_fed_gov_proposed = response[3]?.sfp;

          if (response.length > 5) {
            data.onwards = response.slice(5);
          }
          setExpendituresData(data);
        }
        })
        .finally(() => { setTimeout(() => {
          setTableLoading(false)
        }, 600)
      })
    }
  }, [tab]);

  const fetchData = async () => {
    setLoading(true);
    const res = await getPreviousBudget();
    setPreviousBudget(res?.data);
  };
  useEffect(() => {
    fetchData().then((res) => { 
      setTimeout(() => {
        setLoading(false)
      }, 600)

     
      })     
  }, []);

  const tabsData = [
    {
      title: "Receipts",
      content: "",
      type: "receipts",
    },
    {
      title: "Expenditures",
      content: "",
      type: "expenditures",
    },
  ];

  const incrementYear = (year) => {
    if (year) {
      const x = JSON.parse(year);
      return JSON.stringify(x + 1);
    }
  };

  let pdfCount = 0

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Budget Fiscal Year 2022-2023" />
      <PageWraper>
        <BaseHeading color="secondary" fontSize="xl" marginTop="16px">
          Adjustment of loans
        </BaseHeading>
        <UnorderedList>
          <ListItem>
            <Paragraph content="budgetItem1" marginTop="22px" />
          </ListItem>
          <ListItem>
            <Paragraph content="budgetItem2" marginTop="22px" />
          </ListItem>
        </UnorderedList>
        <Flex direction={"column"} flexWrap="nowrap" marginTop="16px">
          <TabHeader tabsData={tabsData} onTabChange={setTab} />

          {tableLoading ? (
            <CircularLoader />
          ) : (
            <Box
              borderRadius="8px"
              className="chakraTableCustomClass"
              border="1px solid var(--chakra-colors-grey2)"
              overflowX="scroll"
            >
              <Table>
                <Thead
                  borderY="1px solid var(--chakra-colors-grey2)"
                  bgColor="tableHeader"
                  textAlign="justify"
                  fontSize="md"
                >
                  <Tr>
                    <Th
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                    >
                      SR#
                    </Th>
                    <Th
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                    >
                      DESCRIPTION
                    </Th>
                    <Th
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                    >
                      BUDGET ESTIMATES&nbsp;
                      {tab === "receipts"
                        ? receiptsData?.from_year
                        : expendituresData?.from_year}
                   { (receiptsData?.from_year || expendituresData?.from_year) && '-' }
                      {tab === "receipts"
                        ? receiptsData?.to_year
                        : expendituresData?.to_year}
                    </Th>
                    <Th
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                    >
                      REVISED ESTIMATES&nbsp;
                      {tab === "receipts"
                        ? receiptsData?.from_year
                        : expendituresData?.from_year}

                { (receiptsData?.from_year || expendituresData?.from_year) && '-' }
     
                      {tab === "receipts"
                        ? receiptsData?.to_year
                        : expendituresData?.to_year}
                    </Th>
                    <Th
                      color="secondary"
                      whiteSpace="nowrap"
                      fontSize="md"
                      paddingY="19px"
                      borderBottom="1px solid var(--chakra-colors-grey2)"
                    >
                      PROPOSED ESTIMATES&nbsp;
                      {tab === "receipts"
                        ? incrementYear(receiptsData?.from_year)
                        : incrementYear(expendituresData?.from_year)}
                { (receiptsData?.from_year || expendituresData?.from_year) && '-' }
              
                      {tab === "receipts"
                        ? incrementYear(receiptsData?.to_year)
                        : incrementYear(expendituresData?.to_year)}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody textAlign="justify">
                  <Tr fontWeight="600">
                    <Td borderBottom="1px solid var(--chakra-colors-grey2)">
                      A
                    </Td>
                    <Td borderBottom="1px solid var(--chakra-colors-grey2)">
                      CDA SOURCES
                    </Td>
                    <Td borderBottom="1px solid var(--chakra-colors-grey2)"></Td>
                    <Td borderBottom="1px solid var(--chakra-colors-grey2)"></Td>
                    <Td borderBottom="1px solid var(--chakra-colors-grey2)"></Td>
                  </Tr>
                  <Tr>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      (i)
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      Self-Financing A/c
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">

                      {tab === "receipts"
                        ? formateNumber(receiptsData?.self_financing_budget) 
                        : formateNumber(
                            expendituresData?.self_financing_budget
                          )}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.self_financing_revised)
                        : formateNumber(
                            expendituresData?.self_financing_revised
                          )}
                    </Td>
                    <Td>
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.self_financing_proposed)
                        : formateNumber(
                            expendituresData?.self_financing_proposed
                          )}
                    </Td>
                  </Tr>
                  <Tr bgColor="grey3">
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      (ii)
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      Revenue Account
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.revenue_budget)
                        : formateNumber(expendituresData?.revenue_budget)}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.revenue_revised)
                        : formateNumber(expendituresData?.revenue_revised)}
                    </Td>
                    <Td>
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.revenue_proposed)
                        : formateNumber(expendituresData?.revenue_proposed)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      (iii)
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts" ? "CDA own fund" : "Loan to MCI"}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.cda_fund_budget)
                        : formateNumber(expendituresData?.loan_mci_budget)}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.cda_fund_revised)
                        : formateNumber(expendituresData?.loan_mci_revised)}
                    </Td>
                    <Td>
                      {tab === "receipts"
                        ? formateNumber(receiptsData?.cda_fund_proposed)
                        : formateNumber(expendituresData?.loan_mci_proposed)}
                    </Td>
                  </Tr>
                  <Tr bgColor="grey3">
                    <Td
                      fontWeight="600"
                      borderRight="1px solid var(--chakra-colors-grey2)"
                    >
                      B
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? "Funds transferred from MCI"
                        : "Payable to Fed. Govt. / IESCO"}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(
                            receiptsData?.funds_transferred_mci_budget
                          )
                        : formateNumber(
                            expendituresData?.payable_fed_gov_budget
                          )}
                    </Td>
                    <Td borderRight="1px solid var(--chakra-colors-grey2)">
                      {tab === "receipts"
                        ? formateNumber(
                            receiptsData?.funds_transferred_mci_revised
                          )
                        : formateNumber(
                            expendituresData?.payable_fed_gov_revised
                          )}
                    </Td>
                    <Td>
                      {tab === "receipts"
                        ? formateNumber(
                            receiptsData?.funds_transferred_mci_proposed
                          )
                        : formateNumber(
                            expendituresData?.payable_fed_gov_proposed
                          )}
                    </Td>
                  </Tr>
                  {tab === "receipts" && (
                    <Tr>
                      <Td
                        fontWeight="600"
                        borderRight="1px solid var(--chakra-colors-grey2)"
                      >
                        C
                      </Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        Outstanding Receivable amount from WASA, RCB, IESCO,
                        PTV, PBC, MCI, Universities, Clubs etc.
                      </Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        {formateNumber(receiptsData?.received_amount_budget)}
                      </Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        {formateNumber(receiptsData?.received_amount_revised)}
                      </Td>
                      <Td>
                        {formateNumber(receiptsData?.received_amount_proposed)}
                      </Td>
                    </Tr>
                  )}
                  {tab === "receipts" &&
                    receiptsData?.onwards?.map((ele, i) => (
                      <Tr>
                        <Td
                          fontWeight="600"
                          borderRight="1px solid var(--chakra-colors-grey2)"
                        >
                          {getNextChar("C", i + 1)}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {ele.name}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(ele?.sfb)}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(ele?.sfr)}
                        </Td>
                        <Td>{formateNumber(ele?.sfp)}</Td>
                      </Tr>
                    ))}
                  {tab === "receipts" ? (
                    <Tr bgColor="grey3" fontWeight="600">
                      <Td borderRight="1px solid var(--chakra-colors-grey2)"></Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        Total (A+B+C)
                      </Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        {formateNumber(Total_Receipts_Budget)}
                      </Td>
                      <Td borderRight="1px solid var(--chakra-colors-grey2)">
                        {formateNumber(Total_Receipts_Revised)}
                      </Td>
                      <Td>{formateNumber(Total_Receipts_Proposed)}</Td>
                    </Tr>
                  ) : (
                    <>
                      <Tr fontWeight="600">
                        <Td borderRight="1px solid var(--chakra-colors-grey2)"></Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          Total (A+B)
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(Total_Expenditures_Budget)}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(Total_Expenditures_Revised)}
                        </Td>
                        <Td>{formateNumber(Total_Expenditures_Proposed)}</Td>
                      </Tr>
                      <Tr bgColor="grey3">
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          1-
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          Dev: Expenditure [IIA(i)]
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(
                            expendituresData?.self_financing_budget
                          )}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(
                            expendituresData?.self_financing_revised
                          )}
                        </Td>
                        <Td>
                          {formateNumber(
                            expendituresData?.self_financing_proposed
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          2-
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          Non Dev: Exp [IIA(ii)]
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(expendituresData?.revenue_budget)}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(expendituresData?.revenue_revised)}
                        </Td>
                        <Td>
                          {formateNumber(expendituresData?.revenue_proposed)}
                        </Td>
                      </Tr>
                      <Tr bgColor="grey3" fontWeight="600">
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          III
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          Surplus / Deficit
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(surplus_Budget)}
                        </Td>
                        <Td borderRight="1px solid var(--chakra-colors-grey2)">
                          {formateNumber(surplus_Revised)}
                        </Td>
                        <Td>{formateNumber(surplus_Proposed)}</Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </Box>
          )}
        </Flex>
        {loading ? (
          <></>
          // <CircularLoader />
        ) : (
          <Box className="downloadWraperBudget">
            {previousBudget &&
              previousBudget?.length > 0 &&
              previousBudget?.map((item, i) => {
                if (item?.attachment) {
                  pdfCount += 1
                  return (
                    <GridItem colSpan={1} key={i}>
                      <PdfDownloader
                        title={`Budget of year ${item?.from_year} - ${item?.to_year}`}
                        width="100%"
                        height="50.74px"
                        link={item?.attachment}
                        justifyContent="left"
                        fileIndex={pdfCount}
                      />
                    </GridItem>
                  );
                }
              })}
          </Box>
        )}
      </PageWraper>
     
    </>
  );
}

export default withTranslation()(CdaBudget);
