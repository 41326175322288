import React, { useState } from "react";
import { Flex, Link } from "@chakra-ui/react";
import { getAlbumbType } from "../../../utils/helpers";
import BaseHeading from "../../Base/Heading";
import BaseImage from "../../Base/Image";
import BaseText from "../../Base/Text";
import "./style.css";
import BaseButton from "../../Base/Button/index";

export default function PhotoAlbum({
  title,
  data,
  img1,
  oncategoryChange,
  isGallery,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleLinkClick = (event, index) => {
    event.preventDefault();
    oncategoryChange(index);
    setSelectedIndex(index);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleLinkHover = (index) => {
    setHoveredIndex(index);
  };

  const handleLinkLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <Flex
      display="flex"
      flexDirection="column"
      gap="40px"
      className="PhotoAlbum-comp-container"
    >
      {title && <BaseHeading fontSize="xl">{title}</BaseHeading>}
      <Flex columnGap="75px" rowGap="67px" flexWrap="wrap">
        {data?.map((item, index) => (
          <Link
            key={index}
            href="#"
            onClick={(e) => handleLinkClick(e, index)}
            onMouseEnter={() => handleLinkHover(index)}
            onMouseLeave={handleLinkLeave}
            _hover={{ textDecoration: "none" }}
            display="flex"
            gap="16px"
            flexDirection="column"
            height="269px"
            width="229px"
            position="relative"
          >
            <BaseImage
              className={index === selectedIndex && !title ? "imgWrapper" : ""}
              //  source={img1 || "galleryPlaceHolder.svg"}
              source={item.image || "galleryPlaceHolder.svg"}
              width="229px"
              height="229px"
              objectFit="cover"
            />

            {index === hoveredIndex && (
              <BaseButton
                className="albumSeeAllbtn"
                color="primary"
                bgColor="white"
                variant="outline"
                _hover={{ textDecoration: "none" }}
              >
                SEE ALL PHOTOS
              </BaseButton>
            )}

            <BaseText
              className={
                index === selectedIndex && !title
                  ? "selectedText"
                  : "unSelected"
              }
              textAlign="center"
            >
              {getAlbumbType(item?.type) || item}
            </BaseText>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
}
