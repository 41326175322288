import { Flex, Input, Textarea } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import BaseSelect from "../Base/BaseSelect";
import BaseButton from "../Base/Button";
import BaseHeading from "../Base/Heading";
import BaseImage from "../Base/Image";
import BaseText from "../Base/Text";
import Banner from "../Common/Banner";

function ComplaintForm() {
  const data = ["option1", "option2", "option3", "option4"];
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [selectedType, setSelectedType] = useState("Complaint Type");
  const [selectedSector, setSelectedSector] = useState("Sector");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("Complaint Sub Type");
  const handleSelect = (option) => {
    setSelectedLocation(option);
    setSelectedType(option);
    setSelectedSector(option);
    setSelectedSubType(option);
  };
  return (
    <Flex flexDirection="column" gap="27px" width="100%">
      <BaseHeading fontSize="xl">Register Complaint</BaseHeading>
      <Flex gap="80px" width="100%">
        <Flex gap="24px" width="100%" flexDirection="column">
          <Input
            placeholder="Full Name"
            type="text"
            border="1px solid var(--chakra-colors-grey2)"
            color="var(--chakra-colors-grey1)"
            _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          />

          <PhoneInput
            country={"pk"}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            containerClass="containerClass"
            containerStyle={{
              border: "1px solid var(--chakra-colors-grey2)",
              borderRadius: "4px",
              height: "40px",
            }}
            inputStyle={{
              border: "none",
              width: "252px",
              color: "#737678",
            }}
            buttonStyle={{
              border: "none",
              background: "transparent",
            }}
          />

          <BaseSelect
            options={data}
            selectedOption={selectedLocation}
            onSelect={handleSelect}
          />
          <BaseSelect
            options={data}
            selectedOption={selectedType}
            onSelect={handleSelect}
          />
          <Textarea
            placeholder="Complaint Details"
            border="1px solid var(--chakra-colors-grey2)"
            color="var(--chakra-colors-grey1)"
            _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          />
        </Flex>
        <Flex gap="24px" width="100%" flexDirection="column">
          <Input
            placeholder="Email"
            type="email"
            border="1px solid var(--chakra-colors-grey2)"
            color="var(--chakra-colors-grey1)"
            _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          />
          <Input
            placeholder="Residential Address"
            type="text"
            border="1px solid var(--chakra-colors-grey2)"
            color="var(--chakra-colors-grey1)"
            _placeholder={{ color: "var(--chakra-colors-grey1)" }}
          />
          <BaseSelect
            options={data}
            selectedOption={selectedSector}
            onSelect={handleSelect}
          />
          <BaseSelect
            options={data}
            selectedOption={selectedSubType}
            onSelect={handleSelect}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="368px" gap="8px" marginTop="8px">
        <BaseHeading fontSize="lg">Attachment (if any)</BaseHeading>
        <BaseText fontSize="12px" color="grey1">
          Supported file formats: png , jpeg , jpg, gif
          <br /> Maximum file size: 5 Mb
        </BaseText>
        <BaseButton
          variant="outline"
          color="primary"
          borderColor="primary"
          marginTop="16px"
          gap="8px"
        >
          <BaseImage source="upload.svg" width="15px" height="15px" />
          UPLOAD IMAGES
        </BaseButton>
      </Flex>
      <Banner description="Dear Citizen, if you have already registered your complaint regarding instant matter on other forums like Pakistan Citizen Portal (PCP), Federal Ombudsman etc. please do provide us the complaint/tracking ID to serve you better. Follow-up alerts will be sent to your Mobile No. and Email address." />

      <ReCAPTCHA sitekey=" " />

      <BaseButton
        bgColor="primary"
        color="white"
        width="368px"
        height="40px"
        _hover={{ bgColor: "primary" }}
      >
        SUBMIT
      </BaseButton>
    </Flex>
  );
}
export default withTranslation()(ComplaintForm);
