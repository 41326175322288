import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useScreenWidth } from "../../../utils/helpers";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./style.css";
import { useDispatch,useSelector } from "react-redux";
import {setisGalleryPageChange} from "../../../reduxToolkit/slices/changePage"

import BaseHeading from "../Heading";
import BaseText from "../Text";

export default function CustomGallery({
  images = [],
  // heading = "",
  // title = "",
  isShowTitleOnImg,
  isShowthumbnail,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { isSmallScreen, screenSize } = useScreenWidth();
  const [firstImageActive, setFirstImageActive] = useState(true);
  const dispatch = useDispatch();

  const isGalleryPageChange = useSelector(
    (state) => state.changePage.isGalleryPageChange
  );
  
  return (
    <Box
      className={
        firstImageActive
          ? "custom-gallery-container abc"
          : "custom-gallery-container"
      }
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        grabCursor={true}
        navigation={images.length > 1}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Thumbs, Navigation]}
        className="has-3-items custom-gallery-slider"
      >
        {images &&
          images?.map((item, index) => (
            <SwiperSlide key={index} className="swiper-lazy">
              <Box className="image-Main-container">
                <img src={item.image} alt="img" />
              </Box>
              {isShowTitleOnImg && (
                <Box className="iconAboveImgContainer">
                  <BaseHeading
                    fontSize="md"
                    color="secondary"
                    marginBottom="4px"
                  >
                    {item.type}
                  </BaseHeading>

                  <BaseText
                    fontSize="md"
                    color="secondary"
                    paddingBottom="30px"
                  >
                    {/* {item?.name} ///incorrect name is comming in api response */}
                  </BaseText>
                </Box>
              )}
            </SwiperSlide>
          ))}
      </Swiper>

      {isShowthumbnail !== false && (
        <Box
          className="thumbs-container"
          onClick={() => {
            setFirstImageActive(false);
          }}
        >
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            grabCursor={true}
            slidesPerView={
              screenSize < 380
                ? 2
                : screenSize < 510
                ? 3
                : screenSize < 630
                ? 4
                : 5
            }
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="my-swiper"
          >
            {images &&
              images.length > 1 &&
              images.map((item, index) => (
                <SwiperSlide key={index} className="swiper-lazy">
                  <Box className="image-container">
                    <img src={item.image} alt="img" />
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>

          {images && images.length > 5 && (
            <ChakraLink
              as={ReactRouterLink}
              to="/galleryImages"
              className="linkBtn"
              color="primary"
              _hover={{ textDecoration: "none" }}
              onClick={()=>{dispatch(setisGalleryPageChange(!isGalleryPageChange))}}
            >
              SEE ALL PHOTOS
            </ChakraLink>
          )}
        </Box>
      )}
    </Box>
  );
}
