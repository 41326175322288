import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PageWraper from "../../../../components/Base/PageWraper";
import NotificationComponent from "../../../../components/Base/Notification";
import BaseText from "../../../../components/Base/Text";
import BaseHeading from "../../../../components/Base/Heading";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { htmlParser } from "../../../../utils/helpers";
import BaseImage from "../../../../components/Base/Image";
import "./style.css";

import { Flex, Input, Toast } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { InputGroup, InputLeftElement } from "@chakra-ui/react";
import BaseButton from "../../../../components/Base/Button/index";
import { applyForJobApi } from "../../../../utils/API";
import { useNavigate } from "react-router-dom";
import {countriesDialcode } from "../../../../../src/utils/constants"


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { color } from "framer-motion";




function ApplyNow() {
  const location = useLocation();
  const history = useNavigate();

  const data = location?.state || null;
  const [jobId, setJobId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [cvFile, setCvFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [cvFileError, setCvFileError] = useState(false);

  const [showNotification , setShowNotification] = useState(false)
  const [type , setType] = useState('')
  const  [message ,setMessage] = useState('')

  const [currentCountryCode ,setCurrentCountryCode] = useState('')
 


  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Career",
      link: "/careers-and-jobs",
    },
    {
      name: "Apply Now",
      link: "/apply-now",
    },
  ];




  useEffect(() => {
    setJobId(data?.id || null);
  }, [data]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    // Validate the file type here if needed
    setCvFile(file);
  };
  const handleSubmit = async () => {
    setPhoneNumber(currentCountryCode);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError(true);
      setShowNotification(true);
      setType("error");
      setMessage("Invalid email address");
      setTimeout(() => {
        setShowNotification(false);
        setEmailError(false);
      }, 3000);
      return;
    }
  
    // Check if any field is empty
    if (!fullName || !email || !phoneNumber || !cvFile) {
      // Show an error message or handle it as per your UI/UX design
      // Add red border to empty fields
      if (!fullName) setFullNameError(true);
      if (!email) setEmailError(true);
      if (!phoneNumber) setPhoneNumberError(true);
      if (!cvFile) setCvFileError(true);
      return;
    }
  
    try {
      // Prepare data for API
      const formData = {
        name: fullName,
        email: email,
        phone: phoneNumber,
        job_id: jobId,
        attachment: cvFile,
      };
  
      // Call the API with formData
      const res = await applyForJobApi(formData);
  
      if (res) {
        setShowNotification(true);
        setType("success");
        setMessage("Form submitted successfully");
  
        // Reset form fields
        setFullName("");
        setEmail("");
        setPhoneNumber(currentCountryCode);
        setCvFile(null);
  
        // Navigate to "careers-and-jobs" after 4 seconds
        setTimeout(() => {
          setShowNotification(false);
          history("/careers-and-jobs");
        }, 4000);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      setShowNotification(true);
      setMessage(
        error?.response?.data?.message ? error?.response?.data?.message : "error"
      );
      setType("error");
      // Handle the error, show an error message, etc.
    }
  };

  const isPhoneNumberValid = () => {
    return countriesDialcode.some((country) =>
    country.dial_code === phoneNumber
    );
  };

  

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Careers and Jobs" />
      <PageWraper>
        <Box className="headWrapper" style={{ marginBottom: "40px" }}>
          <Box display="flex" flexDirection="column" gap="14px">
            <BaseHeading textAlign="center" fontSize="2xl" color="secondary">
              {data?.title}
            </BaseHeading>

            <BaseText fontSize="md" textAlign="center" color="secondary">
              {htmlParser(data?.type)}
            </BaseText>
          </Box>
        </Box>
        <Box className="formWrapper">
          <Flex flexDirection="column" gap="27px" width="100%">
            <Flex gap="80px" width="100%">
              <Flex gap="24px" width="100%" flexDirection="column">
                <InputGroup>
                  {fullName === ''  && (
                    <InputLeftElement
                      left="74px"
                      pointerEvents="none"
                      color="#C94444"
                      fontSize="1.2em"
                    >
                      *
                    </InputLeftElement>
                  )}

                  <Input
                    paddingLeft="14px"
                    placeholder="Full Name"
                    type="text"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      setFullNameError(false); // Remove red border on input change
                    }}
                    border={
                      fullNameError
                        ? "1px solid var(--chakra-colors-error)"
                        : "1px solid var(--chakra-colors-grey2)"
                    }
                    color="var(--chakra-colors-grey1)"
                    _placeholder={{
                      color: "var(--chakra-colors-grey1)",
                    }}
                  />
                </InputGroup>
                <Box style={{ position: "relative" }}>
                  <PhoneInput
                    country={"pk"}
                    value={phoneNumber}
                    onChange={(phone,event) => {
                      setPhoneNumber(phone);
                      setCurrentCountryCode(`+ ${event.dialCode}`)
                      setPhoneNumberError(false); // Remove red border on input change
                    }}
                    containerClass="containerClass"
                    containerStyle={{
                      border: (phoneNumberError || isPhoneNumberValid())
                        ? "1px solid var(--chakra-colors-error)"
                        : "1px solid var(--chakra-colors-grey2)",
                      borderRadius: "4px",
                      height: "40px",
                      paddingLeft: "7px",
                    }}
                    inputStyle={{
                      border: "none",
                      width: "252px",
                      color: "#737678",
                    }}
                    buttonStyle={{
                      border: "none",
                      background: "transparent",
                    }}
                  />

                  {(phoneNumber === ""  || isPhoneNumberValid() )&& (
                    <BaseText
                      left={phoneNumber?.length > 3 ? "100px" : "90px" } 
                      top="11px"
                      pointerEvents="none"
                      color="#C94444"
                      fontSize="1.2em"
                      position="absolute"
                    >
                      *
                    </BaseText>
                  )}

                </Box>

                <InputGroup>
                  { email === '' && (
                    <InputLeftElement
                      left="104px"
                      pointerEvents="none"
                      color="#C94444"
                      fontSize="1.2em"
                    >
                      *
                    </InputLeftElement>
                  )}

                  <Input
                    paddingLeft="14px"
                    placeholder={`${email ? "" : "Email Address"}`}
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false); // Remove red border on input change
                    }}
                    border={
                      emailError
                        ? "1px solid var(--chakra-colors-error)"
                        : "1px solid var(--chakra-colors-grey2)"
                    }
                    color="var(--chakra-colors-grey1)"
                    _placeholder={{
                      color: "var(--chakra-colors-grey1)",
                    }}
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              width="100%"
              gap="8px"
              marginTop="8px"
            >
              <BaseHeading fontSize="lg">
                Upload CV/ Resume{" "}
                <span style={{ color: "#C94444" }}> * </span>{" "}
              </BaseHeading>

              <input
                type="file"
                id="cvUpload"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileUpload(e);
                  setCvFileError(false); // Remove red border on file upload
                }}
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
              />
              <BaseButton
                variant="outline"
                color="#37474F"
                borderColor={
                  cvFileError
                    ? "var(--chakra-colors-error)"
                    : "var(--chakra-colors-grey2)"
                }
                marginTop="16px"
                gap="8px"
                borderRadius="8px"
                onClick={() => document.getElementById("cvUpload").click()}
              >
                <BaseImage fontSize="lg" source="upload.svg" width="13px" height="13px" />

                <BaseHeading fontSize="sm">
                { !cvFile ? "UPLOAD CV" : "CV UPLOADED"  } {cvFile  && <FontAwesomeIcon style={{color:"green", marginLeft:"4px"}} icon={faCheck} /> }
            </BaseHeading>
        
              </BaseButton>
            </Flex>

            <BaseButton
              bgColor="primary"
              color="white"
              width="100%"
              height="40px"
              borderRadius="8px"
              _hover={{ bgColor: "primary" }}
              onClick={handleSubmit}
            >
              SUBMIT YOUR APPLICATION
            </BaseButton>
    

          </Flex>
        </Box>
    
        {showNotification && (

                <NotificationComponent
                  type={type}
                  message={message}
                  customStyle={{ marginTop: "50px", marginRight: "10px" }}
                />

            )}
      </PageWraper>
    </>
  );
}

export default withTranslation()(ApplyNow);
