import { withTranslation, useTranslation } from "react-i18next";
import "./index.css";
import { Box, Link, Divider, Center } from "@chakra-ui/react";
import BaseImage from "../../Base/Image";
import Button from "../../Base/Button";

import { useState } from "react";
import Searchbar from "../../Common/Searchbar";
import MobileNavBar from "./MobileNavBar";
import { useDispatch } from "react-redux";
import { setGlobalSearch } from "../../../reduxToolkit/slices/globalSearch";

// import MobileNavBar from "../../Common/Headerbar/MobileNavBar";

function Headerbar() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    if (searchQuery) {
     dispatch(setGlobalSearch(searchQuery));
    }
  };

 

  return (
    <nav className="main-header-nav-bar">
      <Box className="navbar">
        <Box className="mobile-header-links">
          <MobileNavBar />
        </Box>
        <Link href="/" className="Desktoplogo">
          <BaseImage source="logo.svg" width="150px" height="70px" />
        </Link>
        <Box display="flex" gap="10px" alignItems="center">
          {/* <Button
            colorScheme="brand"
            variant="ghost"
            onClick={changeLanguage}
            value={i18n.language === "ur" ? "en" : "ur"}
            fontWeight="bold"
          >
            {i18n.language === "ur" ? (
              "English"
            ) : (  "اردو"
            )}
          </Button> */}
          {/* <Center height="25px">
            <Divider orientation="vertical" />
          </Center> */}
          {showSearchbar ? (
            <Searchbar
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              handleSearch={handleSearch}
              global
            />
          ) : (
            <BaseImage
              source="Icon-Search.svg"
              className="hideOnMobile"
              width="40px"
              height="40px"
              _hover={{ cursor: "pointer" }}
              onClick={() => setShowSearchbar(!showSearchbar)}
            />
          )}
          <Center height="25px" className="hideOnMobile">
            <Divider orientation="vertical" />
          </Center>
          <Link href="/contact-us" _hover={{ textDecoration: "none" }}>
            <Button
              colorScheme="brand"
              variant="outline"
              bgColor="white"
              className="hideOnMobile"
            >
              CONTACT US
            </Button>{" "}
          </Link>
          <Center height="25px" className="hideOnMobile">
            <Divider orientation="vertical" />
          </Center>
          <Link
            className="hideOnMobile"
            href="https://twitter.com/CDAthecapital?ref_src=twsrc%5Etfw"
            _hover={{ textDecoration: "none" }}
            isExternal
          >
            <BaseImage source="fb-cda.svg" width="30px" height="30px" />
          </Link>
          <Center height="25px" className="hideOnMobile">
            <Divider orientation="vertical" />
          </Center>
          <Link
            className="hideOnMobile"
            href="http://www.facebook.com/cda.isb.pk"
            _hover={{ textDecoration: "none" }}
            isExternal
          >
            <BaseImage source="twitter-cda.svg" width="30px" height="30px" />
          </Link>
        </Box>
      </Box>
    </nav>
  );
}

export default withTranslation()(Headerbar);
