import React, { useState, useEffect } from "react";
import "./style.css";
import {Link } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faStop,
  faUniversalAccess,
  faXmark
} from "@fortawesome/free-solid-svg-icons";

import {updateIsLoadTableData} from '../../../reduxToolkit/slices/changePage'
import {setisAccessibiltyPanelOpen} from '../../../reduxToolkit/slices/Accessibiltypanel'
import { useSelector,useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { setPageChange } from "../../../reduxToolkit/slices/changePage";
// import { useDispatch } from "react-redux";



export default function AccessibiltySidePanel({ onTurnOff }) {
  const [defaultSize, setDefault] = useState(14);
  const [sizeIncreased,setSizeIncreased] = useState(0)
  const [isPaused, setIsPaused] = useState(false);
  const [pausedAt, setPausedAt] = useState(0);
  const [utterance, setUtterance] = useState(null);
  const [textContent, setTextContent] = useState("");
  const [isShowSidePanel, setIsShowSidePanel] = useState(false);


  const [istextCurrentlyReading, setIstextCurrentlyReading] = useState(false);
  const [checkActiveButton, setCheckActiveButton] = useState("");

  

  const currentUrl = window.location.pathname;
  

  const tab = useSelector(
    (state) => state.tab.selectedTab
  );
  const TablePageNo = useSelector(
    (state) => state.changePage.TablepageNo
  );
  const isDataLoad = useSelector(
    (state) => state.changePage.isLoadTableData
  );

  const isClickOnTableLink = useSelector(
    (state) => state.changePage.isClickOnTableLink
  );

  const isDataSetOnTable = useSelector(
    (state) => state.changePage.isDataSetOnTable
  );

  const isAccordianExpand = useSelector(
    (state) => state.changePage.isAccordianExpand
  );

  const isAccessibiltyPanelOpen = useSelector(
    (state) => state.AccessibiltyPanel.isAccessibiltyPanelOpen
  );

  const isClickedOnAskButton = useSelector(
    (state) => state.gallery.isClickedOnAskButton
  );

  const isGalleryPageChange = useSelector(
    (state) => state.changePage.isGalleryPageChange
  );

  const dispatch = useDispatch();

  const handleFontSizeChange = (action) => {
  
  let textSizeFrom14px = "body p, body .chakra-tabs button,body .desktop-header-links button, body .chakraTableCustomClass table td div, body .chakraTableCustomClass table thead th,  body .footerContainer a, body .footerContainer h2,  body .breadcrumbContainer .chakra-breadcrumb__link,"
  + "body .pageWraper .chakra-accordion a, body .pageWraper .chakra-link,  h2.chakra-heading"
    document.querySelectorAll(textSizeFrom14px)
    .forEach((element) => {

      let fontSize = parseInt(window.getComputedStyle(element).fontSize);
      element.style.fontSize =  action === "increaseSize" ?  `${fontSize +2 }px` :  `${fontSize -2 }px` ;
    });


 let textSizeFrom21px =  "body .pageWraper h2"
    document.querySelectorAll(textSizeFrom21px)
    .forEach((element) => {
     let fontSize = parseInt(window.getComputedStyle(element).fontSize);
      element.style.fontSize =  action === "increaseSize" ?  `${fontSize +2 }px` :  `${fontSize -2 }px` ;
    });

    action ===   "increaseSize" ? setSizeIncreased(sizeIncreased+2) : setSizeIncreased(sizeIncreased-2)
  };



  useEffect(()=>{
    setIsShowSidePanel(isAccessibiltyPanelOpen);
    onTurnOff(isAccessibiltyPanelOpen);
    dispatch(setisAccessibiltyPanelOpen(isAccessibiltyPanelOpen));
  },[isAccessibiltyPanelOpen])



  useEffect(()=>{
    if( isDataLoad && defaultSize > 14)
    {
      setTimeout(() => {
        let textSizeFrom14px = "body .chakraTableCustomClass td div, body .chakraTableCustomClass td h2"
        document.querySelectorAll(textSizeFrom14px)
        .forEach((element) => {
    
           let fontSize = parseInt(window.getComputedStyle(element).fontSize);

          element.style.fontSize =   `${fontSize +  sizeIncreased}px`;
        });
        dispatch(updateIsLoadTableData(false))
      },200);  
  }

  },[tab,TablePageNo,isDataLoad])


  // useEffect for TextOverSpeech
  useEffect(() => {
      // this useEffect is for textOverSpeech
    const currentPath = window.location.pathname;

    const loadData = () => {
      // let pagerWrapperDiv = document.querySelector("div.homePageWrapper, div.featuredLinksMain, div.pageWraper , div.pageWrapper h2, pageWraper .itemCenter p,div.pageWrapper chakra-table th, .pageWraper p, .pageWraper h2, .pageWraper h2.chakra-heading, .PhotoAlbumWrapper, .chakra-accordion");
      let pagerWrapperDiv    = document.querySelector("div.homePageWrapper, div.featuredLinksMain, div.pageWraper , div.pageWrapper h2, pageWraper .itemCenter p,div.pageWrapper chakra-table th, .pageWraper p, .pageWraper h2, .pageWraper h2.chakra-heading, .PhotoAlbumWrapper, .chakra-accordion,.chakra-accordion__item li, .gallery-container");
     if(currentPath === '/about-islamabad')
      {
            pagerWrapperDiv = document.querySelector("div.pageWraper , div.pageWrapper h2, pageWraper .itemCenter p,div.pageWrapper chakra-table th, .pageWraper p, .pageWraper h2, .pageWraper h2.chakra-heading, .PhotoAlbumWrapper, .chakra-accordion");
           // .PhotoAlbumWrapper for gallery page

           const startIndex = pagerWrapperDiv.innerText.indexOf("Historical Pictures of Islamabad");
           const endIndex = pagerWrapperDiv.innerText.indexOf("Facts & Statistics");
       
           if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
             // Extract the text excluding the specified part
             const processedText = pagerWrapperDiv.innerText.substring(0, startIndex)+ "Historical Pictures of Islamabad.  "  + pagerWrapperDiv.innerText.substring(endIndex).replace(/[+\−]/g, "");
             pagerWrapperDiv = processedText

           }
        
      }else if(isClickedOnAskButton && currentPath === "/contact-us"){

        const startIndex =  pagerWrapperDiv.innerText.indexOf("Ask CDA");

// Check if "Ask CDA" is found in the text
if (startIndex !== -1) {
  // Get the text after "Ask CDA"
  const remainingText = pagerWrapperDiv.innerText.substring(startIndex + "Ask CDA".length);
  pagerWrapperDiv = remainingText
  // Log or use the remaining text
}
       


      }
      
      else{
        pagerWrapperDiv = pagerWrapperDiv.innerText
      }
      if (pagerWrapperDiv) {
        setTextContent(pagerWrapperDiv);
      }
    };
  
    // Run the loadData function after the DOM content is fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', loadData);
    } else {
      setTimeout(() => {
        loadData();
      }, currentPath === '/news-releases-article' ? 300 :  1500);
    
    }
  
    // Cleanup: remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('DOMContentLoaded', loadData);
    };
    
  }, [textContent,tab,TablePageNo,isClickOnTableLink,isDataSetOnTable,isAccordianExpand,currentUrl]);


  useEffect(()=>{
    handleStop()
  },[tab,TablePageNo,isClickOnTableLink,isAccordianExpand,isGalleryPageChange,isClickedOnAskButton])


  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(textContent);
    setUtterance(u);

    // Event listener to track when the speech is paused
    const onEndOrPause = () => {
      setPausedAt(synth.paused ? synth.currentTime : 0);
      setIsPaused(synth.paused);
    };

    synth.addEventListener('pause', onEndOrPause);
    synth.addEventListener('end', onEndOrPause);

    return () => {
      synth.removeEventListener('pause', onEndOrPause);
      synth.removeEventListener('end', onEndOrPause);
    };
  }, [textContent]);

  const handlePlay = () => {
    setCheckActiveButton("play")
    setIstextCurrentlyReading(true)
    const synth = window.speechSynthesis;
    if (isPaused) {
      const u = new SpeechSynthesisUtterance(textContent);
      u.currentTime = pausedAt;
      setUtterance(u);
      synth.resume();
      setIsPaused(false);
    } else {
      const u = new SpeechSynthesisUtterance(textContent);
      u.onend = () => {
        setCheckActiveButton("stop")
        setIstextCurrentlyReading(false)
      };

      synth.speak(u);
       // Check if the speech synthesis has finished
    }
  };

  const handlePause = () => {
    if(istextCurrentlyReading)
    {
    const synth = window.speechSynthesis;
    synth.pause();
    setIsPaused(true);
    setCheckActiveButton("pause")
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsPaused(false);
    setIstextCurrentlyReading(false)
  };


  useEffect(()=>{
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsPaused(false);
  },[])

  return (
    <>
      <div
        onClick={() => {
          onTurnOff(true);
          setIsShowSidePanel(true);
          dispatch(setisAccessibiltyPanelOpen(true));
        }}
        class={isShowSidePanel ? "hide" : "accessibility-icon HideOnMobileScreen"}
      >
        <div >
          <FontAwesomeIcon className="fo" icon={faUniversalAccess} />
        </div>
      </div>

      <div
        className={
          isShowSidePanel ? "accessibilSidenavActive  HideOnMobileScreen" : "accessibilSidenav"
        }
      >
        <Link
          className={isShowSidePanel ? "linkab" : "hide"}
          _hover={{ textDecoration: "none" }}
          onClick={() => {
            onTurnOff(false);
            setIsShowSidePanel(false);
            dispatch(setisAccessibiltyPanelOpen(false));
          }}
        >
        

        <FontAwesomeIcon icon={faXmark} />
        </Link>

        <div className={isShowSidePanel ? "accessible-icon" : "hide"}>
          <div class="btn-group-vertical"   style={{ width: "50px" }} role="group" aria-label="font-resize">
            <button
              onClick={() => {
                if(defaultSize < 20){
                  setDefault(defaultSize + 2);
                  handleFontSizeChange("increaseSize");
                }
            
              }}
              type="button"
              class="btn increase-font-size"
              title="increase fontsize"
            >
              {" "}
              +
            </button>
            <button type="button" class="btn disabled-cls" disabled="">
              <img
                src="https://www.pta.gov.pk/themes/pta/img/font-eng-A.png"
                alt=""
              />
            </button>

            <button
              onClick={() => {
                if(defaultSize > 14){
                  setDefault(defaultSize - 2);
                  handleFontSizeChange("decreaseSize");
                }
              }}
              type="button"
              class="btn decrease-font-size"
              title="decrease fontsize"
              disabled=""
            >
              -
            </button>
          </div>
          <br /> <br />
          <div
            class="btn-group-vertical"
            role="group"
            aria-label="speech-control"
            style={{ width: "50px" }}
          >
            <button
              onClick={handlePlay}
              type="button"
              id="play"
              className={checkActiveButton === "play" ? "activeButton"  :  "btn"} 
              title="play"
            >
              <FontAwesomeIcon icon={faPlay} />
            </button>
            <button
              onClick={handlePause}
              type="button"
              id="pause"
              className={checkActiveButton === "pause" ? "activeButton"  :  "btn"} 
              title="pause"
            >
              <FontAwesomeIcon icon={faPause} />
            </button>
            <button
              onClick={()=>{handleStop();  setCheckActiveButton("stop")}}
              type="button"
              id="stop"
              className={checkActiveButton === "stop" ? "activeButton"  :  "btn"} 
              title="stop"
            >
              <FontAwesomeIcon icon={faStop} />
            </button>
          </div>
        </div>




      </div>




    </>
  );
}
