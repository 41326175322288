import { Flex, ListItem, OrderedList, Box } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PageWraper from "../../../../components/Base/PageWraper";
import Paragraph from "../../../../components/Common/Paragraph";
import BaseHeading from "../../../../components/Base/Heading";
import BaseText from "../../../../components/Base/Text";
import GridTable from "../../../../components/Common/Table/GridTable";
import BaseButton from "../../../../components/Base/Button";
import { exportToCSV } from "../../../../utils/helpers";
import "./index.css";
function IllegalHousingSchemes() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Public Notices",
      link: "#",
    },
    {
      name: "Illegal Housing Schemes",
      link: "/illegal-housing-schemes",
    },
  ];

  const zone1 = [
    "Abdullah Town, H-17",
    "Ahmad Town, D-14",
    "Ammar Town, H-17",
    "Awan Town, D-14",
    "Buraq Valley, H-17",
    "Capital Hills Residencia, D-14",
    "Chinnar Town, H-17",
    "Civic Center, H-17",
    "Fazal-e-Rabbi, H-17",
    "Green Valley Phase-II, E-15",
    "Green Valley, D-14",
    "Gulshan-e-Taleem, H-15",
    "Jhangio Sayedain Homes, H-15",
    "Karam Elahi Town, H-13",
    "Margalla View Valley, D-14",
    "Paradise Valley, D-14",
    "Peral Orchard, H-17",
    "Qamar Garden, H-15",
    "Shehzad Town, E-15",
    "Sher Zaman Garden, H-17",
    "Shifa International Housing Society, H-17",
    "Talha Farms, H-17",
    "Tayyab Garden, H-15",
    "Zammar Valley, H-17",
  ];

  const zone2 = [
    "Green City, Sector D-17, E-17",
    "Gulshan-Rehman, Sector C-17, D-17",
    "Islamabad Co-operative Farming Scheme, Sector D-17",
    "Jamal Akber Colony, Tarnol phatak",
    "Pakistan Overseas Housing Scheme, Sector F-16",
    "Pakistan Town Phase-II G-16, F-16",
    "Taj Seventeen West, Luxury Apartments & Shops",
  ];

  const zone3 = [
    "Al Rayan Society",
    "Ali Town",
    "Arcadia City",
    "Aryan Enclave, Korang Road, Banni Galla",
    "Green Hills",
    "Green Meadows (North Ridge) Housing Scheme",
    "Khyber City, Haider Street, Bani Galla",
    "Lake View Lanes, Main Korang Road, Bani Galla",
    "Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of Korang River near Banni Galla Bridge",
    "Major Makhdom Society",
    "Maple Homes, Pesi Road, Bani Galla",
    "Pakistan Colony, Korang Road, Bani Galla",
    "Riverside Enclave, Korang Road, Banni Gala",
    "Silver Oaks Villas, Bani Galla",
    "Sukoon Villas, Bani Galla",
  ];

  const zone4 = [
    "Abdullah Gardens, Kurri Road",
    "Abu Bakar Town, Islamabad Expressway",
    "Adil Farms, Simly Dam Road",
    "Adil Valley, Simly Dam Road",
    "Afaq Town, Nilore, Lehtrar Road, Islamabad",
    "Al-Amin Gardens, Nilore, Lehtrar Road, Islamabad",
    "Al-Huda Town, Lehtrar Road",
    "Al-Nahal Housing Scheme, Simly Dam Road",
    "Al-Rahman Villas, Kurri Road, near Attock Petroleum",
    "Al-Rehman City View, Lehtrar Road, near PINSTECH, Nilore",
    "Al-Syed Avenue, Park Road",
    "Ali Model Town",
    "Ameer Khan Enclave, Mallah Road, near Bahria Enclave-I",
    "Arslan Town, Lehtrar Road, adjacent to Alhamra Hills",
    "Asian Ranches Villas, located at Lehtrar, Simly Dam link road Zone-4(D), Islamabad",
    "Babar Enclave, Mura Noor",
    "Badar Farms, Simly Dam Road",
    "Bani Gala Hill View, situated at Hill View Road, Bani Gala, Zone-4, Islamabad",
    "Batala Town, Near Neelam Colony, Lehtrar Road, Islamabad",
    "Baylee Town, Kurri Road, near Attock Petroleum",
    "Bhara Kahu Enclave, Islamabad",
    "Blue Enclave / Star Home, located at Lehtrar, Simly Dam link road, Zone-4(d), Islamabad",
    "Burma Town, Lehtrar Road",
    "Canterbury Enclave near Park Road",
    "Capital Gardens, Lehtrar Road",
    "City Town, Lehtrar Road",
    "City Views near Nilore, Islamabad",
    "Commonors Sky Gardens (Flyover Valley)",
    "Danyal Town, Hurno Thanda Pani, Lehtrar Road",
    "Doctor's Enclave, Simly Dam Road",
    "Dream Land City, Lehtrar Road Thanda Pani",
    "Faisal Town, Islamabad Expressway",
    "Gakkhar Town, Lehtrar Road",
    "Galaxy Enclave / Mufti Mehmood Enclave, Lehtrar Road",
    "Ghouri Gardens, Lehtrar Road",
    "Ghouri Town (All Phases in Zone-4), Islamabad Expressway",
    "Government Officers Co-operative Farming Scheme",
    "Green Avenue, Park Road",
    "Green Avenue-II, Kuri Road",
    "Green Fields, Simly Dam Road",
    "Green Residencia. Lehtrar Road having head office opposite to Shaheen Town Phase-I, near Petrol Pump Jhang Syedaan",
    "Green Valley (Phase-I & II), Simly Dam Road",
    "Green Valley, Karor Road, Pehont, near Quran Complex, Zone-4(D), Islamabad sponsored by Raja Nasir & Ch. Amjad",
    "Green View Villas, Lehtrar Road",
    "Gulberg Town (Phase-I & II), Lehtrar Road",
    "Gulf Residencia (Phase-II), Lehtrar Road, Islamabad",
    "Gulf Residencia, Lehtrar Road",
    "Gulshan Valley By M/s Vista Valley, Thanda Pani, Lehtrar Road, Islamabad",
    "Haji Asif Society, Nilore, Lehtrar Road, Islamabad",
    "Hameed Town (Mouza Mohrian), Kuri Road",
    "Hill View Houses, Simly Dam Road",
    "Ideal Residencia, near Park Enclave, Kurri",
    "Iqbal Town, Islamabad Expressway",
    "Islamabad Farms, Simly Dam Road",
    "Ittefaq Town, Old Kirpa Road, Lehtrar Road",
    "J&K Farms, Islamabad Highway",
    "Japan Valley, Kirpa Road, Lehtrar Road",
    "Karsaaz Villas, near D-12, Shah Allah Ditta, Zone-4, Islamabad",
    "Kiyani Town, Kirpa Road, Lehtrar Road",
    "Kohsaar Enclave, Jandala Road, near Naval Farms Simly Dam Road",
    "Land Sub-Division Sponsored by Mr. Shahzad, Sanjania Road opposite Beaconhouse Newlands, Banni Galla",
    "M/s Tricon Agro Farms, Simly Dam Road",
    "Madina Enclave, Phase-I, located at Lehtrar, Simly Dam link road, Zone-4(d), Islamabad",
    "Makkah Town, Hurno Thanda Pani, Lehtrar Road",
    "Margalla Garden, Lehtrar Road",
    "Margalla Valley, Main Murree Road, Zone-4, Islamabad",
    "Marwa Town, Islamabad Highway",
    "Media City-I, Kirpa Road, off Lehtrar Road",
    "Meharban Colony, Park Road, Islamabad",
    "Mufti Mehmood Enclave, Lehtrar Road",
    "Mughal Enclave, Nilore, Lehtrar Road, Islamabad",
    "Muslim Town, Simly Dam Road",
    "Mustafa Town, Lehtrar Road, Islamabad",
    "Muzaffar Abad Town (Pind Bhegewal), Simly Dam Road",
    "New University Town (near COMSATS), Park Road",
    "Nilore Greens, Nilore, Lehtrar Road, Islamabad",
    "OGDCL Town, near Chattar, Murree Road",
    "Olive Wood Farms, Simly Dam Road",
    "Paradise Enclave (Phase-II), Nilore, Lehtrar Road, Islamabad",
    "Paradise Point Housing Scheme, Lehtrar Road, Islamabad",
    "Park Lane Valley, Park Road",
    "Partal Town, Lehtrar Road, Islamabad",
    "PTV Colony, Simly Dam Road",
    "Qureshi Town, Nilore, Lehtrar Road, Islamabad",
    "Qurtabal Town, Islamabad Highway",
    "Rafay Town, Lehtrar Road, Nilore, Islamabad",
    "Rawal Enclave, Kurri Road",
    "Rawal Enclave, Phase-III, Lethrar Road, Tumair, Islamabad. Head Office: Heaven Street Near Head Start School, Kurri Road, Islamabad",
    "Roshan Gardens, Lehtrar Road, Islamabad",
    "Royal Avenue, Park Road",
    "Royal City/Royal Villas (near PINSTECH, Nilore), Lehtrar Road",
    "Royal Homes Residencia, Lehtrar Road",
    "Sadaat Town, Thanda Pani, Lehtrar Road, Islamabad",
    "Saif Garden, Kirpa Road, Lehtrar Road",
    "Saif Garden, Ali Pur Farash, Lehtrar Road, Islamabad",
    "Samaa Town, Kirpa Road, Lehtrar Road",
    "Sanabil Garden By M/s Al Safa Marketing, Kuri Road, Islamabad",
    "Satti Town, Lehtrar Road",
    "Shaheen Farms, Simly Dam Road",
    "Simly Valley (Phase-I & II), Simly Dam Road",
    "Small Scale Housing Project developed by Mr. Shahzad, Sanjania Road opposite Beaconhouse Newlands, Banni Galla",
    "Spring Valley, Simly Dam Road",
    "Star Villas Housing Scheme, near Country Farms, Main Simly Dam Road, Zone-4, Islamabad",
    "Suffa Valley, Gala View Road, Islamabad",
    "The Enclave, Bani Gala Road",
    "Usama Town, Hurno Thanda Pani, Nilore, Islamabad",
    "Vista Valley, Lehtrar Road",
    "Yaar Muhammad, Bani Gala",
    "Zahoor Town, Lehtrar Road",
  ];

  const zone5 = [
    "Aiza Garden, Kahuta Road",
    "Aiza Garden, Mouza Lohi Bher Dakhli Jawa",
    "Aliya Town, Mouza Lohi Bher Dokhli Jawa",
    "Askaria Town, Japan Road",
    "Ayesha Town, Navy road, Rawat",
    "Azim Town, Kahuta Road",
    "Bankers City, Dharwala Road",
    "Canyon Views, Islamabad Highway",
    "Danyal Town, Hoan Dhamyal, Sihala",
    "Dhanyal Town, Kahuta Road",
    "DownTown Residencia, Islamabad Expressway",
    "Fatima Villa, GT Road",
    "Fiza Town, Hoan Dhamyal, Sihala",
    "Ghouri Town, Phases in Zone-5 Japan Road, Islamabad Highway",
    "Gulshan-e-Danish, GT Road",
    "Gulshan-e-Rabia, Japan Road",
    "Gulshan-e-Rehman , Japan Road",
    "Judicial Employees Housing Scheme, Kirpa Chirah Road",
    "National Police Foundation, PWD Road, Islamabad Highway",
    "New Model Town Humak / Roshan Enclave, Mouza Niazian",
    "Pak PWD, Islamabad Highway",
    "Parliamentarians Enclave, Japan Road",
    "Rasheed Town, Japan Road",
    "Rawat Enclave, main Rawat chowk",
    "Rawat Housing Scheme, G.T road, Rawat",
    "River View, Kahuta Road",
    "Sadozai Town, Kangota Sayedan",
    "Samana Smart City and Sawan Farm House",
    "Television Media Town",
  ];
  const Zones = {
    "ZONE 1": zone1,
    "ZONE 2": zone2,
    "ZONE 3": zone3,
    "ZONE 4": zone4,
    "ZONE 5": zone5,
  };
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Illegal/ Un-Authorised Housing<br/> Schemes In Islamabad" />
      <PageWraper>
        <Flex flexDirection="column">
          <Flex flexDirection="column" gap="16px">
            <Paragraph content="illegalHousingPara1" />
            <Paragraph content="illegalHousingPara2" />
          </Flex>
          <Box className="download-container">
            <BaseText fontSize="md">
              Download complete list of Illegal Housing Societies.
            </BaseText>
            <BaseButton
              colorScheme="brand"
              className="download-btn"
              variant="outline"
              marginLeft="auto"
              onClick={() => {
                exportToCSV(Zones);
              }}
            >
              Download List
            </BaseButton>
          </Box>
          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <BaseHeading fontSize="xl">ZONE-I</BaseHeading>
            <Paragraph
              content="Islamabad Capital Territory (ICT) has been divided into Five
              Zones, under the provisions of ICT (zoning) regulations, 1992. As
              per Delineation of the Zones, Zone-1 constitutes Sector up to the
              existing alignment of the G.T. Road from the point of intersection
              of G.T. Road with Shahrah-e-Kashmir to the point of the Nicolson
              Monument inclusive of Sector D-14,D-15, D-16 (Partial) E-14, E-15,
              H-13, H-14, H-15, E-17."
            />
            <Paragraph
              content="
              As per provisions of Clause-4 (1)(A) of ICT (Zoning) Regulation,
              1992, the development of land in Un-acquired Sectoral areas of
              Zone-1, shall be subject to the following conditions:"
            />
            <OrderedList fontSize="md" paddingLeft="16px">
              <ListItem>
                Land shall be acquired under a phased programme and developed by
                the Authority in accordance with the Land Use pattern spelled
                out in the Master Plan.
              </ListItem>
              <ListItem>
                No Sale/Purchase of land which entails Change in Land Use shall
                be allowed.
              </ListItem>
              <ListItem>
                No construction of houses or buildings shall be allowed.
              </ListItem>
              <ListItem>
                No Private Scheme of any kind whatsoever shall be allowed.
              </ListItem>
            </OrderedList>
            <Paragraph
              content="
              Therefore, Construction of Buildings, Apartment Projects,
              Settlements, Land Sub-Divisions, etc. in violation of the Master
              Plan of Islamabad 1960, provision ICT (zoning) Regulations 1992
              and Islamabad capital Territory Building Control Regulation 2020,
              are Illegal, including the following:"
            />

            {/* <OrderedList fontSize="md" paddingLeft="16px">
              <ListItem>Abdullah Town, H-17</ListItem>
              <ListItem>Ahmad Town, D-14</ListItem>
              <ListItem>Ammar Town, H-17</ListItem>
              <ListItem>Awan Town, D-14</ListItem>
              <ListItem>Buraq Valley, H-17</ListItem>
              <ListItem>Capital Hills Residencia, D-14</ListItem>
              <ListItem>Chinnar Town, H-17</ListItem>
              <ListItem>Civic Center, H-17</ListItem>
              <ListItem>Fazal-e-Rabbi, H-17</ListItem>
              <ListItem>Green Valley Phase-II, E-15</ListItem>
              <ListItem>Green Valley, D-14</ListItem>
              <ListItem>Gulshan-e-Taleem, H-15</ListItem>
              <ListItem>Jhangio Sayedain Homes, H-15</ListItem>
              <ListItem>Margalla View Valley, D-14</ListItem>
              <ListItem>Paradise Valley, D-14</ListItem>
              <ListItem>Peral Orchard, H-17</ListItem>
              <ListItem>Qamar Garden, H-15</ListItem>
              <ListItem>Shehzad Town, E-15</ListItem>
              <ListItem>Sher Zaman Garden, H-17</ListItem>
              <ListItem>Shifa International Housing Society, H-17</ListItem>
              <ListItem>Talha Farms, H-17</ListItem>
              <ListItem>Tayyab Garden H-15</ListItem>
              <ListItem>Zammar Valley, H-17</ListItem>
            </OrderedList>*/}
            <GridTable data={zone1} />
          </Flex>

          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <BaseHeading fontSize="xl">
              Illegal Land sub-division in the following Sectors
            </BaseHeading>
            <BaseText fontSize="md">E-14,D-14,D-15,D-16,E-16,F-15</BaseText>
          </Flex>
          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <Flex alignItems="center">
              <BaseHeading fontSize="xl">ZONE-II</BaseHeading>
            </Flex>
            <GridTable data={zone2} />
            {/* <OrderedList fontSize="md" paddingLeft="16px">
              <ListItem>Green City ,Sector D-17, E-17</ListItem>
              <ListItem>Gulshan-Rehman Sector C-17,D-17</ListItem>
              <ListItem>
                Islamabad Co-operative Farming Scheme, Sector D-17
              </ListItem>
              <ListItem>Jamal Akber Colony, Tarnol phatak</ListItem>
              <ListItem>Pakistan Overseas Housing Scheme, Sector F-16</ListItem>
              <ListItem>Pakistan Town Phase-II G-16, F-16</ListItem>
              <ListItem>Taj Seventeen West, Luxury Apartments & Shops</ListItem>
            </OrderedList> */}
          </Flex>

          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <Flex alignItems="center">
              <BaseHeading fontSize="xl">ZONE-III</BaseHeading>
            </Flex>
            <GridTable data={zone3} />
            {/* <OrderedList paddingLeft="16px" fontSize="md">
              <ListItem>Al Rayan Society</ListItem>
              <ListItem>Ali Town</ListItem>
              <ListItem>Arcadia City</ListItem>
              <ListItem>Aryan Enclave, Korang road, Banni Galla</ListItem>
              <ListItem>Green Hills</ListItem>
              <ListItem>Green Meadows (North Ridge) Housing Scheme</ListItem>
              <ListItem>Khyber City, Haider Street, Bani Galla</ListItem>
              <ListItem>Lake View Lanes, Main Korang Road, Bani Galla</ListItem>
              <ListItem>
                Land Sub-Division, Sponsored by Mr. Raja Fakhar, on the South of
                Korang River near Banni Galla Bridge
              </ListItem>
              <ListItem>Major Makhdom Society</ListItem>
              <ListItem>Maple Homes, Pesi Road Bani Galla</ListItem>
              <ListItem>Pakistan Colony, Korang Road, Bani Galla</ListItem>
              <ListItem>Riverside Enclave, Korang Road, Banni Gala</ListItem>
              <ListItem>Silver Oaks Villas, Bani Galla</ListItem>
              <ListItem>Sukoon Villas, Bani Galla</ListItem>
            </OrderedList> */}
          </Flex>

          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <Flex alignItems="center">
              <BaseHeading fontSize="xl">ZONE-IV</BaseHeading>
            </Flex>
            <GridTable data={zone4} scroll={true} height="500px" />
            {/* <OrderedList fontSize="md" paddingLeft="16px">
              <ListItem>Abdullah Gardens, Kurri Road</ListItem>
              <ListItem>Abu Bakar Town, Islamabad Expressway</ListItem>
              <ListItem>Adil Farms, Simly Dam Road</ListItem>
              <ListItem>Adil Valley, Simly Dam Road</ListItem>
              <ListItem>Afaq Town, Nilore, Lehtrar Road, Islamabad</ListItem>
              <ListItem>
                Al-Amin Gardens, Nilore, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Al-Huda Town, Lehtrar Road</ListItem>
              <ListItem>Al-Nahal Housing Scheme, Simly Dam Road</ListItem>
              <ListItem>
                Al-Rahman Villas, Kurri Road, near Attock Petroleum
              </ListItem>
              <ListItem>
                Al-Rehman City View, Lehtrar Road, near PINSTECH, Nilore
              </ListItem>
              <ListItem>Al-Syed Avenue, Park Road</ListItem>
              <ListItem>Ali Model Town</ListItem>
              <ListItem>
                Ameer Khan Enclave, Mallah Road, near Bahria Enclave-I
              </ListItem>
              <ListItem>
                Arslan Town, Lehtrar Road, adjacent to Alhamra Hills
              </ListItem>
              <ListItem>
                Asian Ranches Villas, located at Lehtrar, Simly Dam link road
                Zone-4(D), Islamabad
              </ListItem>
              <ListItem>Babar Enclave, Mura Noor</ListItem>
              <ListItem>Badar Farms, Simly Dam Road</ListItem>
              <ListItem>
                Bani Gala Hill View, situated at Hill View Road, Bani Gala,
                Zone-4, Islamabad
              </ListItem>
              <ListItem>
                Batala Town, Near Neelam Colony, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>
                Baylee Town, Kurri Road, near Attock Petroleum
              </ListItem>
              <ListItem>Bhara Kahu Enclave, Islamabad</ListItem>
              <ListItem>
                Blue Enclave / Star Home, located at Lehtrar, Simly Dam link
                road, Zone-4(d), Islamabad
              </ListItem>
              <ListItem>Burma Town, Lehtrar Road</ListItem>
              <ListItem>Canterbury Enclave near Park Road</ListItem>
              <ListItem>Capital Gardens, Lehtrar Road</ListItem>
              <ListItem>City Town, Lehtrar Road</ListItem>
              <ListItem>City Views near Nilore, Islamabad</ListItem>
              <ListItem>Commonors Sky Gardens (Flyover Valley)</ListItem>
              <ListItem>Danyal Town, Hurno Thanda Pani, Lehtrar Road</ListItem>
              <ListItem>Doctor's Enclave, Simly Dam Road</ListItem>
              <ListItem>Dream Land City, Lehtrar Road Thanda Pani</ListItem>
              <ListItem>Faisal Town, Islamabad Expressway</ListItem>
              <ListItem>Gakkhar Town, Lehtrar Road</ListItem>
              <ListItem>
                Galaxy Enclave/ Mufti Mehmood Enclave, Lehtrar Road
              </ListItem>
              <ListItem>Ghouri Gardens, Lehtrar Road</ListItem>
              <ListItem>
                Ghouri Town (All Phases in Zone-4), Islamabad Expressway
              </ListItem>
              <ListItem>
                Government Officers Co-operative Farming Scheme
              </ListItem>
              <ListItem>Green Avenue, Park Road</ListItem>
              <ListItem>Green Avenue-II, Kuri Road</ListItem>
              <ListItem>Green Fields, Simly Dam Road</ListItem>
              <ListItem>
                Green Residencia. Lehtrar Road having head office opposite to
                Shaheen Town Phase-I, near Petrol Pump Jhang Syedaan
              </ListItem>
              <ListItem>Green Valley (Phase-I & II), Simly Dam Road</ListItem>
              <ListItem>
                Green Valley, Karor Road, Pehont, near Quran Complex, Zone-4(D),
                Islamabad sponsored by Raja Nasir & Ch. Amjad
              </ListItem>
              <ListItem>Green View Villas, Lehtrar Road</ListItem>
              <ListItem>Gulberg Town (Phase-I & II), Lehtrar Road</ListItem>
              <ListItem>
                Gulf Residencia (Phase-II), Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Gulf Residencia, Lehtrar Road</ListItem>
              <ListItem>
                Gulshan Valley By M/s Vista Valley, Thanda Pani, Lehtrar Road,
                Islamabad
              </ListItem>
              <ListItem>
                Haji Asif Society, Nilore, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Hameed Town (Mouza Mohrian), Kuri Road</ListItem>
              <ListItem>Hill View Houses, Simly Dam Road</ListItem>
              <ListItem>Ideal Residencia, near Park Enclave, Kurri</ListItem>
              <ListItem>Iqbal Town, Islamabad Expressway</ListItem>
              <ListItem>Islamabad Farms, Simly Dam Road</ListItem>
              <ListItem>Ittefaq Town, Old Kirpa Road, Lehtrar Road</ListItem>
              <ListItem>J&K Farms, Islamabad Highway</ListItem>
              <ListItem>Japan Valley, Kirpa Road, Lehtrar Road</ListItem>
              <ListItem>
                Karsaaz Villas, near D-12, Shah Allah Ditta, Zone-4, Islamabad
              </ListItem>
              <ListItem>Kiyani Town, Kirpa Road, Lehtrar Road</ListItem>
              <ListItem>
                Kohsaar Enclave, Jandala Road, near Naval Farms Simly Dam Road
              </ListItem>
              <ListItem>
                Land Sub Division Sponsored by Mr. Shahzad, Sanjania Road
                opposite Beaconhouse Newlands, Banni Galla
              </ListItem>
              <ListItem>M/s Tricon Agro Farms, Simly Dam Road</ListItem>
              <ListItem>
                Madina Enclave, Phase-I, located at Lehtrar, Simly Dam link
                road, Zone-4(d), Islamabad
              </ListItem>
              <ListItem>Makkah Town, Hurno Thanda Pani, Lehtrar Road</ListItem>
              <ListItem>Margalla Garden, Lehtrar Road</ListItem>
              <ListItem>
                Margalla Valley, Main Murree Road, Zone-4, Islamabad
              </ListItem>
              <ListItem>Marwa Town, Islamabad Highway</ListItem>
              <ListItem>Media City-I, Kirpa Road, off Lehtrar Road</ListItem>
              <ListItem>Meharban Colony, Park Road, Islamabad</ListItem>
              <ListItem>Mufti Mehmood Enclave, Lehtrar Road</ListItem>
              <ListItem>
                Mughal Enclave, Nilore, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Muslim Town, Simly Dam Road</ListItem>
              <ListItem>Mustafa Town, Lehtrar Road, Islamabad</ListItem>
              <ListItem>Mustafa Town, Lehtrar Road, Islamabad</ListItem>
              <ListItem>
                Muzaffar Abad Town (Pind Bhegewal), Simly Dam Road
              </ListItem>
              <ListItem>New University Town (near COMSATS) Park Road</ListItem>
              <ListItem>
                Nilore greens, Nilore, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>OGDCL Town, near Chattar, Murree Road</ListItem>
              <ListItem>Olive Wood Farms, Simly Dam Road</ListItem>
              <ListItem>
                Paradise Enclave (Phase-II), Nilore, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>
                Paradise Point Housing Scheme, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Park Lane Valley, Park Road</ListItem>
              <ListItem>Partal Town, Lehtrar Road, Islamabad</ListItem>
              <ListItem>PTV Colony, Simly Dam Road</ListItem>
              <ListItem>Qureshi Town, Nilore, Lehtrar Road, Islamabad</ListItem>
              <ListItem>Qurtabal Town, Islamabad Highway</ListItem>
              <ListItem>Rafay Town, Lehtrar Road, Nilore, Islamabad</ListItem>
              <ListItem>Rawal Enclave, Kurri Road</ListItem>
              <ListItem>
                Rawal Enclave, Phase-III, Lethrar Road, Tumair, Islamabad. Head
                Office: Heaven Street Near Head Start School, Kurri Road,
                Islamabad
              </ListItem>
              <ListItem>Roshan Gardens, Lehtrar Road, Islamabad</ListItem>
              <ListItem>Royal Avenue, Park Road</ListItem>
              <ListItem>
                Royal City/Royal Villas (near PINSTECH, Nilore), Lehtrar Road
              </ListItem>
              <ListItem>Royal Homes Residencia, Lehtrar Road</ListItem>
              <ListItem>
                Sadaat Town, Thanda Pani, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Saif Garden, Kirpa Road, Lehtrar Road</ListItem>
              <ListItem>
                Saif Garden, Ali Pur Farash, Lehtrar Road, Islamabad
              </ListItem>
              <ListItem>Samaa Town, Kirpa Road, Lehtrar Road</ListItem>
              <ListItem>
                Sanabil Garden By M/s Al Safa Marketing, Kuri Road, Islamabad
              </ListItem>
              <ListItem>Satti Town, Lehtrar Road</ListItem>
              <ListItem>Shaheen Farms, Simly Dam Road</ListItem>
              <ListItem>Simly Valley (Phase-I & II), Simly Dam Road</ListItem>
              <ListItem>
                Small Scale Housing Project developed by Mr. Shahzad, Sanjania
                Road opposite Beaconhouse Newlands, Bani Galla
              </ListItem>
              <ListItem>Spring Valley, Simly Dam Road</ListItem>
              <ListItem>
                Star Villas Housing Scheme, near Country Farms, Main Simly Dam
                Road, Zone-4, Islamabad
              </ListItem>
              <ListItem>Suffa Valley, Gala View Road, Islamabad</ListItem>
              <ListItem>The Enclave, Bani Gala Road</ListItem>
              <ListItem>
                Usama Town, Hurno Thanda Pani, Nilore, Islamabad
              </ListItem>
              <ListItem>Vista Valley, Lehtrar Road</ListItem>
              <ListItem>Yaar Muhammad, Bani Gala</ListItem>
              <ListItem>Zahoor Town, Lehtrar Road</ListItem>
            </OrderedList> */}
          </Flex>

          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <Flex alignItems="center">
              <BaseHeading fontSize="xl">ZONE-V</BaseHeading>
            </Flex>
            <GridTable data={zone5} scroll={true} height="500px" />
            {/* <OrderedList fontSize="md" paddingLeft="16px">
              <ListItem>Aiza Garden, Kahuta Road</ListItem>
              <ListItem>Aiza Garden, Mouza Lohi Bher Dakhli Jawa</ListItem>
              <ListItem>Aliya Town, Mouza Lohi Bher Dokhli Jawa</ListItem>
              <ListItem>Askaria Town, Japan Road</ListItem>
              <ListItem>Ayesha Town, Navy Road, Rawat</ListItem>
              <ListItem>Azim Town, Kahuta Road</ListItem>
              <ListItem>Bankers City, Dharwala Road</ListItem>
              <ListItem>Canyon Views, Islamabad Highway</ListItem>
              <ListItem>Danyal Town, Hoan Dhamyal, Sihala</ListItem>
              <ListItem>Dhanyal Town, Kahuta Road</ListItem>
              <ListItem>DownTown Residencia, Islamabad Expressway</ListItem>
              <ListItem>Fatima Villa, GT Road</ListItem>
              <ListItem>Fiza Town, Hoan Dhamyal, Sihala</ListItem>
              <ListItem>
                Ghouri Town, Phases in Zone-5 Japan Road, Islamabad Highway
              </ListItem>
              <ListItem>Gulshan-e-Danish, GT Road</ListItem>
              <ListItem>Gulshan-e-Rabia, Japan Road</ListItem>
              <ListItem>Gulshan-e-Rehman, Japan Road</ListItem>
              <ListItem>
                Judicial Employees Housing Scheme, Kirpa Chirah Road
              </ListItem>
              <ListItem>
                National Police Foundation, PWD Road, Islamabad Highway
              </ListItem>
              <ListItem>
                New Model Town Humak / Roshan Enclave, Mouza Niazian
              </ListItem>
              <ListItem>Pak PWD, Islamabad Highway</ListItem>
              <ListItem>Parliamentarians Enclave, Japan Road</ListItem>
              <ListItem>Rasheed Town, Japan Road</ListItem>
              <ListItem>Rawat Enclave, Main Rawat Chowk</ListItem>
              <ListItem>Rawat Housing Scheme, G.T Road, Rawat</ListItem>
              <ListItem>River View, Kahuta Road</ListItem>
              <ListItem>Sadozai Town, Kangota Sayedan</ListItem>
              <ListItem>Samana Smart City and Sawan Farm House</ListItem>
              <ListItem>Television Media Town</ListItem>
            </OrderedList> */}
          </Flex>

          <Flex flexDirection="column" gap="16px" marginTop="24px">
            <Paragraph
              content="Agro Farming and Residential Housing Schemes are permissible in
                Zone-4 under the provisions of ICT (Zoning) Regulation, 1992
                [amended in 2010] and permissions are granted by CDA after
                completing the codal formalities."
            />

            <Paragraph
              content="
                Housing schemes namely Muhafiz Gardens, Rawat Enclave, Faisal
                Town, Grace Land Housing and Air Line Avenue are being marketed
                as located in Islamabad, whereas neither the subject schemes
                fall in Islamabad nor any approval/NOC has been obtained from
                CDA for launching and marketing of the schemes."
            />
            <Paragraph
              content="
                Through this notice, General Public is therefore
                cautioned/warned in their own interest to refrain from making
                any booking/purchase of plots in these or any any other
                un-authorized and illegal housing scheme and must consult
                authority (Tel. 051-9252494 for Zone-2, 5 & E-11 and office of
                Director Regional Planning, CDA for Zone-3 & 4 at 051-9252605)
                before making transactions about the legal status of the
                schemes. CDA Website www.cda.gov.pk may also be visited for
                having updated status of the schemes."
            />

            <Paragraph
              content="
                The marketing/advertisement of Housing Projects without NOC from
                CDA is illegal and unauthorized. The advertisers/marketing
                agencies are restrained from misleading advertising/marketing of
                illegal housing schemes. The sponsors are further directed to
                refrain from marketing and development of Housing Schemes
                without NOC from CDA."
            />
            <Flex flexDirection="column">
              <BaseText fontSize="md">Director Housing Societies</BaseText>
              <BaseText fontSize="md">
                Contact: <a href="tel:051-9252494">051-9252494</a>
              </BaseText>
              <BaseText fontSize="md" color="secondary" textAlign="right">
                Last Updated on 31 July, 2023
              </BaseText>
            </Flex>
          </Flex>
        </Flex>
      </PageWraper>
    </>
  );
}

export default withTranslation()(IllegalHousingSchemes);
