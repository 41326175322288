import { Flex } from "@chakra-ui/react";
import MapCard from "../../Common/MapCard";
import "./index.css";

export default function MapCardSection({ data }) {
  return (
    <Flex className="MapCardSectionContainer">
      {data?.map((item, i) => (
        <MapCard data={item} key={i} />
      ))}
    </Flex>
  );
}
