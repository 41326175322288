import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import BaseHeading from "../../Base/Heading";
import BaseImage from "../../Base/Image";
import BaseLink from "../../Base/Link";
import BaseText from "../../Base/Text";
import Separator from "../Separator";
import "./style.css";
export default function Footer() {
  return (
    <Box className="footerContainer">
      <Box margin="auto" maxWidth="1440px">
        <Box
          display="flex"
          gap="70px"
          justifyContent="space-between"
          flexWrap="wrap"
          className="footerMobile"
        >
          <Flex className="HideOnMobile" flexDirection="column" color="white">
            <BaseLink href="/">
              <BaseImage
                source="footerLogo.svg"
                width="144px"
                height="76.71px"
              />
            </BaseLink>
            <BaseText fontSize="md" marginTop="16px" color="white">
              Together, let's shape a dynamic <br />
              and sustainable future for
              <br /> Islamabad, where every resident's <br />
              well-being is our priority.
            </BaseText>
          </Flex>

          <Flex className="HideOnMobile" flexDirection="column" color="white">
            <BaseHeading fontSize="md" color="white" marginBottom="10px">
              General Information
            </BaseHeading>
            <BaseLink href="/cda-history" className="space">
              About CDA
            </BaseLink>
            <BaseLink href="https://owo.cda.gov.pk/" className="space">
              E-Services
            </BaseLink>
            <BaseLink href="/news-releases" className="space">
              Media Center
            </BaseLink>
            <BaseLink href="/contact-us" className="space">
              Contact Us
            </BaseLink>
            {/* <BaseLink href="/" className="space">
              Site Map
            </BaseLink> */}
          </Flex>

          <Flex className="HideOnMobile" flexDirection="column" color="white">
            <BaseHeading fontSize="md" color="white" marginBottom="10px">
              Consumer Facilitation
            </BaseHeading>
            <BaseLink href="/faqs" className="space">
              FAQs
            </BaseLink>
            <BaseLink href="/careers-and-jobs" className="space">
              Careers
            </BaseLink>
            {/* <BaseLink
              href="https://complaints.cda.gov.pk/eservices/registerComplaint.aspx"
              className="space"
            >
              Complaint Management
              <br /> System
            </BaseLink> */}
          </Flex>

          <Flex className="HideOnMobile" flexDirection="column" color="white">
            <BaseHeading fontSize="md" color="white" marginBottom="10px">
              Contact
            </BaseHeading>
            <Box
              display="flex"
              gap="16px"
              alignItems="center"
              className="space"
            >
              <BaseImage source="phone.svg" />
              <a href="tel:051-9252614">051-9252614</a>
            </Box>
            <Box
              display="flex"
              gap="16px"
              alignItems="center"
              className="space"
            >
              <BaseImage source="emailIcon.svg" />
              <a href="mailto:contactus@cda.gov.pk">contactus@cda.gov.pk</a>
            </Box>
            <BaseLink
              display="flex"
              gap="16px"
              className="space"
              isExternal
              href="https://www.google.com/maps/place/Capital+Development+Authority+Secretariat/@33.7023402,73.0752121,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbff2ea7d1f0d:0xbb2acabbc4bf1efb!8m2!3d33.7023358!4d73.077787!16s%2Fg%2F1tdzphlz?entry=ttu"
            >
              <BaseImage source="locationIcon.svg" width="20px" height="19px" />
              Secretary CDA
              <br /> Board, Block-V,
              <br /> Sector G-7/4,
              <br /> Islamabad
            </BaseLink>
          </Flex>
          {/* HideOnDesktop */}
          <Accordion allowToggle className="HideOnDesktop">
            <AccordionItem flexDirection="column" color="white">
              <AccordionButton justifyContent="space-between">
                <BaseHeading fontSize="md" color="white">
                  CDA
                </BaseHeading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="footerMobilePannel">
                <BaseLink href="/">
                  <BaseImage
                    source="footerLogo.svg"
                    width="144px"
                    height="76.71px"
                  />
                </BaseLink>
                <BaseText fontSize="md" marginTop="16px" color="white">
                  Together, let's shape a dynamic <br />
                  and sustainable future for
                  <br /> Islamabad, where every resident's <br />
                  well-being is our priority.
                </BaseText>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem flexDirection="column" color="white">
              <AccordionButton justifyContent="space-between">
                <BaseHeading fontSize="md" color="white">
                  General Information
                </BaseHeading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="footerMobilePannel">
                <BaseLink href="/" className="space">
                  About CDA
                </BaseLink>
                <BaseLink href="/" className="space">
                  E-Services
                </BaseLink>
                <BaseLink href="/" className="space">
                  Media Center
                </BaseLink>
                <BaseLink href="/contact-us" className="space">
                  Contact Us
                </BaseLink>
                <BaseLink href="/faqs" className="space">
                  FAQs
                </BaseLink>
                <BaseLink href="/career" className="space">
                  Careers
                </BaseLink>
                {/* <BaseLink
                  className="space"
                  href="https://complaints.cda.gov.pk/eservices/registerComplaint.aspx"
                >
                  Complaint Management System
                </BaseLink> */}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem flexDirection="column" color="white">
              <AccordionButton justifyContent="space-between">
                <BaseHeading fontSize="md" color="white">
                  Contact
                </BaseHeading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className="footerMobilePannel">
                <Box
                  display="flex"
                  gap="16px"
                  alignItems="center"
                  className="space"
                >
                  <BaseImage source="phone.svg" />
                  <a href="tel:051-9252614">051-9252614</a>
                </Box>
                <Box
                  display="flex"
                  gap="16px"
                  alignItems="center"
                  className="space"
                >
                  <BaseImage source="emailIcon.svg" />
                  <a href="mailto:contactus@cda.gov.pk">contactus@cda.gov.pk</a>
                </Box>
                <BaseLink
                  display="flex"
                  gap="16px"
                  alignItems="center"
                  className="space"
                  isExternal
                  href="https://www.google.com/maps/place/Capital+Development+Authority+Secretariat/@33.7023402,73.0752121,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfbff2ea7d1f0d:0xbb2acabbc4bf1efb!8m2!3d33.7023358!4d73.077787!16s%2Fg%2F1tdzphlz?entry=ttu"
                >
                  <BaseImage
                    source="locationIcon.svg"
                    width="20px"
                    height="19px"
                  />
                  Secretary CDA Board, Block-V, Sector G-7/4, Islamabad
                </BaseLink>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          {/* HideOnDesktop */}
        </Box>
        <Box className="separator HideOnMobile">
          <Separator
            width="100%"
            height="1px"
            background="var(--chakra-colors-grey3)"
          />
        </Box>
        <Box className="copyRightSection">
          <BaseText fontSize="md" color="white">
            © 2007-2024 CDA, All rights reserved.
          </BaseText>
          <Box className="socialLinks">
            <BaseLink
              href="https://twitter.com/CDAthecapital?ref_src=twsrc%5Etfw"
              isExternal
            >
              <BaseImage source="fb-cda.svg" width="30px" height="30px" />
            </BaseLink>
            <Center height="25px">
              <Divider orientation="vertical" />
            </Center>
            <BaseLink href="http://www.facebook.com/cda.isb.pk" isExternal>
              <BaseImage source="twitter-cda.svg" width="30px" height="30px" />
            </BaseLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
