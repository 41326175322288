import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";
import ShowDownloadLink from "../../../components/WingsAndDirectorates/ShowDownloadLink";

export default function EstateWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Estate Wing",
      link: "/estate-wing",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Estate Wing" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Estate Wing</BaseHeading>
              <BaseText fontSize="md">
                The wing is responsible for the preparation of Islamabad Land
                Disposal Regulation along with the opening of new sectors in
                Islamabad. The wing deals with all matters relating to
                allotment/transfer of plots & property under the Land Disposal
                Regulations. It caters for the allotment of residential plots
                through ballot and open auction. The wing also handles the legal
                matters pertaining to the Authority.
              </BaseText>
            </Flex>
            <ShowDownloadLink title="To download Estate Management & Miscellaneous forms." />
            <WingsAccordion type={"estate"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
