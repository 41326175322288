import PageWraper from "../../../components/Base/PageWraper";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import Card from "../../../components/Base/Card";
import { parksData } from "../../../utils/constants";
import BaseHeading from "../../../components/Base/Heading";
import BaseText from "../../../components/Base/Text";

export default function ParksOfIct() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Destinations in Islamabad",
      link: "/destinations-in-islamabad",
    },
  ];

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Destinations in Islamabad" />
      <PageWraper>
        {/* <BaseHeading fontSize="xl">Destinations in Islamabad</BaseHeading> */}
        <BaseText lineHeight="28px" fontSize="md" margin="24px 0px 32px 0px">
          CDA, as the prime architect, has meticulously developed the
          captivating array of tourist destinations in Islamabad, enriching the
          cityscape and ensuring a wealth of engaging experiences for residents
          and visitors alike.
        </BaseText>
        <Card data={parksData} />
      </PageWraper>
    </>
  );
}
