import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PageWraper from "../../../../components/Base/PageWraper";
import { Box, Flex } from "@chakra-ui/react";
import PdfDownloader from "../../../../components/Common/PdfDownloader";
import { useEffect, useState } from "react";
import { getBallotingData } from "../../../../utils/API";
import CircularLoader from "../../../../components/Common/CircularLoader";
import BaseText from "../../../../components/Base/Text";
import Searchbar from "../../../../components/Common/Searchbar";
import "./index.css";
import BaseHeading from "../../../../components/Base/Heading";
function BallotingSectorC16andI15() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Public Notices",
      link: "#",
    },
    {
      name: "Balloting Announcement",
      link: "/balloting-sector-c16-i15",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const res = await getBallotingData("balloting");

    setData(res?.data);
  };

  useEffect(() => {
    fetchData().then((res) => {
      setLoading(false);
    });
  }, []);



  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchData().then((res) => {
        setLoading(false);
      })
    }
  }, [searchQuery]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = data?.filter((item) =>
      item?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setData(filteredData);
    setPages(Math.ceil(filteredData?.length/pageSize))
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Balloting Announcement" />
      <PageWraper>

        <BaseText fontSize="md" color="secondary" margin="24px 0px 32px 0px">
          Explore the Balloting page for the latest balloting updates in Islamabad's housing sectors. 
        </BaseText>
        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={ handleSearch}
        />
        {loading ? (
          <CircularLoader />
        ) : (
          <Box className={data?.length > 0 ?"balloting" : ""} >
            {
              data?.length > 0 ?
              data?.map((item, i) => (
                <PdfDownloader
                  key={i}
                  title={item?.title || "Balloting Sector C-16 & I-15"}
                  width="100%"
                  height="50.74px"
                  link={item?.attachment}
                  justifyContent="left"
                  fileIndex={i+1}
                />
              ))
                  : <>
                    <Box margin="140px auto" width="max-content">
                      <BaseHeading fontSize="2xl" color="secondary">
                        Information not available
                      </BaseHeading>
                    </Box>

                  </>
            }
          </Box>
        )}
      </PageWraper>
    </>
  );
}

export default withTranslation()(BallotingSectorC16andI15);
