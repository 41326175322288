import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import BaseHeading from "../../../components/Base/Heading";
import BaseText from "../../Base/Text";
import "./style.css";
import BaseLink from "../../Base/Link";

export default function DataTable({ tableConfig }) {
  const ViewAttachmentBtn = (val) => {
    return (
      <BaseLink
        marginTop="41px"
        fontSize="14px"
        fontWeight="bold"
        color="primary"
        href={val}
        isExternal
      >
        VIEW ATTACHMENT
      </BaseLink>
    );
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);



  return (
    <Box margin="" width="" className="main">
      {tableConfig.map((row, rowIndex) => (
        <Box
          key={rowIndex}
          marginTop=""
          width=""
          className={`${
            isSmallScreen
              ? ""
              : `ChakraCardsOnDesktopInner-simpleTable row${rowIndex + 1}`
          }`}
        >
          {row.map((column, colIndex) => (
            <Box
              key={colIndex}
              marginRight={isSmallScreen ? "0px" : "71px"}
              width={isSmallScreen ? "100%" : `${100 / row.length}%`}
              className={`${
                isSmallScreen
                  ? "ChakraCardsOnMobile"
                  : ``
              }`}
            >

              <BaseHeading  fontSize="lg" marginTop="0px">
                {column.Name}
              </BaseHeading>
              <BaseText marginTop={isSmallScreen ? "0px" : "6px"}  marginLeft={isSmallScreen ? "6px" : "0px"}  fontSize="md">
                {column.detail}
              </BaseText>
             {column.Attachments &&<Box marginTop={isSmallScreen ? "0px" : "41px"}  marginLeft={isSmallScreen ? "6px" : "0px"} >
                {column.Attachments && ViewAttachmentBtn(column.Attachments)}
              </Box> }
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
