export const data = [
  {
    title: "Islamabad Urban Area",
    period: "220.15 sq. km",
  },
  {
    title: "Islamabad Rural Area",
    period: "466.20 sq. km",
  },
  {
    title: "Islamabad Park",
    period: "220.15 sq. km",
  },
];

export const climatedata = [
  {
    seasons: "Winter (Oct-Mar)",
    highAvg: "16.6°",
    lowAvg: "3.4°",
  },
  {
    seasons: "Summer (Apr-Sept)",
    highAvg: "34.2°",
    lowAvg: "24.4°",
  },
  {
    seasons: "Year Average",
    highAvg: "28.5°",
    lowAvg: "14.1°",
  },
];
