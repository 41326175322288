import "./App.css";
import { withTranslation } from "react-i18next";
import React, { Suspense, useEffect, useState } from "react";
// import Home from "./pages/home";
import { getHomePageBanner } from "./utils/API";
import CircularLoader from "./components/Common/CircularLoader";
// import BannerModal from "./components/Home/BannerModal";
const Home = React.lazy(() => import("./pages/home"));
const BannerModal = React.lazy(() => import("./components/Home/BannerModal"));

function App() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);

  const fetchData = async () => {
    const res = await getHomePageBanner();
    setImage(res?.data?.length > 0 && res?.data[res?.data.length - 1]?.banner_img);
  };

  useEffect(() => {
    const hasVisitedBefore = localStorage?.getItem("hasVisited");
     if (!hasVisitedBefore) {
      setShowModal(true);
      localStorage?.setItem("hasVisited", "true");
      fetchData();
     }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Suspense fallback={<CircularLoader />}>
        {showModal && (
          <BannerModal isOpen={showModal} onClose={closeModal} image={image} />
        )}
        <Home />
      </Suspense>
    </div>
  );
}

export default withTranslation()(App);
