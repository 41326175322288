import BaseHeading from "../../Base/Heading";

import { Flex, Grid, ListItem, UnorderedList } from "@chakra-ui/react";
import {
  accountsRevenueTreasuryDirectory,
  administrationDirectory,
  buildingControlDirectory,
  chairman,
  civicManagementDirectory,
  dcAndMagistrateDirectory,
  designWingDirectory,
  dmaDirectory,
  electricalAndMechanicalDirectory,
  engineeringWingDirectory,
  environmentWingDirectory,
  estateManagementDirectory,
  estateWingDirectory,
  financeWingDirectory,
  hospitalAndCaresDirectory,
  housingSocietiesDirectory,
  informationTechnologyDirectory,
  inquiryNos,
  labourRelationAndOneWindowOperationDirectory,
  landAndRehabAndEnforcementDirectory,
  lawDirectorateDirectory,
  parkAndZoosDirectory,
  phoneDirectory,
  procurementAndMunicipalDirectory,
  programmingAndEvaluationDirectory,
  sectorDevelopmentDirectory,
  serviceAndMaintainanceDirectory,
  sportsAndCultureDirectory,
  streetLightNos,
  structureDirectory,
  waterManagementDirectory,
  waterTankerEnquiry,
  workAndQsDirectory,
} from "../../../utils/constants";
import PaginationTable from "../../Common/Table/PaginationTable";
import { useState } from "react";
import "./index.css";

function CdaPhoneDirectory() {
  const [directory, setDirectory] = useState(chairman);
  const [selectedDirectory, setSelectedDirectory] = useState(
    "Chairman Office, CDA Secretariat"
  );
  const headers = {
    office: "Office",
    phone: "Phone",
    ext: "Ext",
    fax: "Fax",
    email: "Email",
  };

  const keys = {
    office: "office",
    phone: "phone",
    ext: "ext",
    fax: "fax",
    email: "email",
  };

  const inquiryHeaders = {
    location: "Location",
    phone: "Phone",
  };

  const inquiryKeys = {
    location: "location",
    phone: "phone",
  };

  const scrollToFocus = () => {
    const srollVal = document.getElementById("focus-here").offsetTop;
    window.scrollTo({ top: srollVal - 70, behavior: "smooth" });
  };

  const handleChange = (e) => {
    setSelectedDirectory(e.target.id);
    if (e.target.id === "Chairman Office, CDA Secretariat") {
      setDirectory(chairman);
    } else if (e.target.id === "CDA Enquiry Nos.") {
      setDirectory(inquiryNos);
    } else if (e.target.id === "Street Light Enquiry") {
      setDirectory(streetLightNos);
    } else if (e.target.id === "Water Tanker Enquiry") {
      setDirectory(waterTankerEnquiry);
    } else if (e.target.id === "Administration Wing") {
      setDirectory(administrationDirectory);
    } else if (e.target.id === "Civic Management Wing") {
      setDirectory(civicManagementDirectory);
    } else if (
      e.target.id === "Directorate of Municipal Administration (DMA)"
    ) {
      setDirectory(dmaDirectory);
    } else if (e.target.id === "Hospital & CARES") {
      setDirectory(hospitalAndCaresDirectory);
    } else if (e.target.id === "Sports & Culture") {
      setDirectory(sportsAndCultureDirectory);
    } else if (e.target.id === "Law Directorate") {
      setDirectory(lawDirectorateDirectory);
    } else if (e.target.id === "Design Wing") {
      setDirectory(designWingDirectory);
    } else if (e.target.id === "Building Control") {
      setDirectory(buildingControlDirectory);
    } else if (e.target.id === "Structure") {
      setDirectory(structureDirectory);
    } else if (e.target.id === "Housing Societies") {
      setDirectory(housingSocietiesDirectory);
    } else if (e.target.id === "Finance Wing") {
      setDirectory(financeWingDirectory);
    } else if (e.target.id === "Accounts, Revenue and Treasury") {
      setDirectory(accountsRevenueTreasuryDirectory);
    } else if (e.target.id === "Procurement and Municipal Bond") {
      setDirectory(procurementAndMunicipalDirectory);
    } else if (e.target.id === "Information Technology (IT)") {
      setDirectory(informationTechnologyDirectory);
    } else if (e.target.id === "Environment Wing") {
      setDirectory(environmentWingDirectory);
    } else if (e.target.id === "Parks and Zoo") {
      setDirectory(parkAndZoosDirectory);
    } else if (e.target.id === "Engineering Wing") {
      setDirectory(engineeringWingDirectory);
    } else if (e.target.id === "Works & QS") {
      setDirectory(workAndQsDirectory);
    } else if (e.target.id === "Sector Development") {
      setDirectory(sectorDevelopmentDirectory);
    } else if (e.target.id === "Services & Maintenance") {
      setDirectory(serviceAndMaintainanceDirectory);
    } else if (e.target.id === "Water Management") {
      setDirectory(waterManagementDirectory);
    } else if (e.target.id === "Electrical & Mechanical") {
      setDirectory(electricalAndMechanicalDirectory);
    } else if (e.target.id === "Programming & Evaluation") {
      setDirectory(programmingAndEvaluationDirectory);
    } else if (e.target.id === "Estate Wing") {
      setDirectory(estateWingDirectory);
    } else if (e.target.id === "Estate Management") {
      setDirectory(estateManagementDirectory);
    } else if (e.target.id === "(Land & Rehabilitation) and Enforcement") {
      setDirectory(landAndRehabAndEnforcementDirectory);
    } else if (e.target.id === "Labour Relations & One Window Operations") {
      setDirectory(labourRelationAndOneWindowOperationDirectory);
    } else if (e.target.id === "DC & Magistrate") {
      setDirectory(dcAndMagistrateDirectory);
    }

    scrollToFocus();
  };

  return (
    <Flex flexDirection="column" gap="32px">
      <Flex flexDirection="column" gap="16px">
        <BaseHeading fontSize="xl">CDA Telephone Directory</BaseHeading>
        <Grid className="cdaPhoneDirectoryGrid">
          {phoneDirectory?.map((item, i) => (
            <UnorderedList key={i}>
              <ListItem
                fontSize="md"
                id={item}
                fontWeight={selectedDirectory === item ? "600" : "400"}
                color={selectedDirectory === item ? "primary" : "secondary"}
                _hover={{ cursor: "pointer", color: "primary" }}
                onClick={(e) => handleChange(e)}
                textDecoration={
                  selectedDirectory === item ? "underline" : "none"
                }
              >
                {item}
              </ListItem>
            </UnorderedList>
          ))}
        </Grid>
      </Flex>

      <Flex flexDirection="column" gap="8px" id="focus-here">
        {selectedDirectory === "CDA Enquiry Nos." ||
        selectedDirectory === "Street Light Enquiry" ||
        selectedDirectory === "Water Tanker Enquiry" ? (
          <PaginationTable
            loading={false}
            headers={inquiryHeaders}
            dataSet={directory}
            keys={inquiryKeys}
            withPagination={false}
          />
        ) : (
          <PaginationTable
            loading={false}
            headers={headers}
            dataSet={directory}
            keys={keys}
            withPagination={false}
          />
        )}
      </Flex>
    </Flex>
  );
}
export default CdaPhoneDirectory;
