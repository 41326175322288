// import { Box } from "@chakra-ui/react";
// import { withTranslation } from "react-i18next";
// import PageHeader from "../../../components/Common/PageHeader";
// import BreadCrumb from "../../../components/Common/Breadcrumb";
// import Paragraph from "../../../components/Common/Paragraph";
// import PdfDownloader from "../../../components/Common/PdfDownloader";
// import "./style.css";
// import {
//   CdaOrdinanceRelatedInfo,
//   CdaOrdinanceRelatedLinks,
// } from "../../../utils/constants";
// import BaseText from "../../../components/Base/Text";
// import ReleateLinksAndInfo from "../../../components/Common/ReleateLinksAndInfo";
// import PageWraper from "../../../components/Base/PageWraper";
// import { getOrdinance } from "../../../utils/API";
// import { useEffect, useState } from "react";
// import CircularLoader from "../../../components/Common/CircularLoader";
//
// function CdaOrdinance1960() {
//   const [ordinanceData, setOrdinance] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const breadcrumbs = [
//     { name: "Home", link: "/" },
//     {
//       name: "Legislation",
//       link: "#",
//     },
//     {
//       name: "CDA Ordinance 1960",
//       link: "/cda-ordinance-1960",
//     },
//   ];
//
//   const fetchData = async () => {
//     setLoading(true);
//     const res = await getOrdinance();
//     setOrdinance(res?.data);
//   };
//
//   useEffect(() => {
//     fetchData().then(() => setLoading(false));
//   }, []);
//
//   return (
//     <>
//       <BreadCrumb breadcrumbs={breadcrumbs} />
//       <PageHeader
//         title="CDA Ordinance 1960"
//         description={`"An Ordinance to establish a Capital Development Authority"`}
//       />
//
//       <PageWraper>
//         <Paragraph content="cdaOrdinancePara1" />
//         <Paragraph content="cdaOrdinancePara2" marginTop="16px" />
//         <Paragraph content="cdaOrdinancePara3" marginTop="16px" />
//         <Paragraph content="cdaOrdinancePara4" marginTop="16px" />
//         <Paragraph content="cdaOrdinancePara5" marginTop="24px" />
//         <Box className="downloadContainerOrdinance">
//           {loading ? (
//             <CircularLoader />
//           ) :
//               (
//             <PdfDownloader
//               title="CDA Ordinance 1960"
//               width="100%"
//               height="50.74px"
//               link={
//                 ordinanceData &&
//                 ordinanceData?.length > 0 &&
//                 ordinanceData[0]?.ordinance_1960
//               }
//               justifyContent="left"
//             />
//           )}
//         </Box>
//         <BaseText color="secondary" marginTop="32px" textAlign="right">
//           Last Updated on 15 Dec, 2011
//         </BaseText>
//         <ReleateLinksAndInfo
//           releatedLinks={CdaOrdinanceRelatedLinks}
//           releatedInfo={CdaOrdinanceRelatedInfo}
//         />
//       </PageWraper>
//     </>
//   );
// }
//
// export default withTranslation()(CdaOrdinance1960);



import {Box, Grid, GridItem} from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import Paragraph from "../../../components/Common/Paragraph";
import PdfDownloader from "../../../components/Common/PdfDownloader";
import "./style.css";
import {
  CdaOrdinanceRelatedInfo,
  CdaOrdinanceRelatedLinks,
} from "../../../utils/constants";
import BaseText from "../../../components/Base/Text";
import ReleateLinksAndInfo from "../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../components/Base/PageWraper";
import { getOrdinance } from "../../../utils/API";
import { useEffect, useState } from "react";
import CircularLoader from "../../../components/Common/CircularLoader";
import BaseHeading from "../../../components/Base/Heading";

function CdaOrdinance1960() {
  const [ordinanceData, setOrdinance] = useState([]);
  const [loading, setLoading] = useState(false);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Legislation",
      link: "#",
    },
    {
      name: "CDA Ordinance 1960",
      link: "/cda-ordinance-1960",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const res = await getOrdinance();
    setOrdinance(res?.data);
  };

  useEffect(() => {
    fetchData().then(() => setLoading(false));
  }, []);

  return (
      <>
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <PageHeader
            title="CDA Ordinance 1960"
            description={`"An Ordinance to establish a Capital Development Authority"`}
        />

        <PageWraper>
          <Paragraph content="cdaOrdinancePara1" />
          <Paragraph content="cdaOrdinancePara2" marginTop="16px" />
          <Paragraph content="cdaOrdinancePara3" marginTop="16px" />
          <Paragraph content="cdaOrdinancePara4" marginTop="16px" />
          <Paragraph content="cdaOrdinancePara5" marginTop="24px" />
          <Box className="downloadContainerOrdinance">
            {loading ? (
                <CircularLoader />
            ) :(ordinanceData?.length > 0) && (ordinanceData !== undefined) ? (
                    <Grid className=" laws-download-container " >
                      {ordinanceData
                          ?.slice()
                          ?.reverse()
                          ?.map((item, i) => (
                              // <GridItem colSpan={1} key={i}>
                              // <PdfDownloader
                              //     title={item?.title}
                              //     fileSize={item?.fileSize}
                              //     // height="50.74px"
                              //     height={"auto"}
                              //     minHeight={"50.74px"}
                              //     width="100%"
                              //     justifyContent="left"
                              //     // link={item?.ordinance_1960}
                              //     fileIndex={i+1}
                              // />

                        <PdfDownloader
                        title="CDA Ordinance 1960"
                        width="100%"
                        height="50.74px"
                        link={
                        ordinanceData &&
                        ordinanceData?.length > 0 &&
                        ordinanceData[i]?.ordinance_1960
                      }
                        justifyContent="left"
                        fileIndex={i+1}
                        />
                              // </GridItem>
                          ))}
                    </Grid>
                )
                : ( <Box margin="140px auto" width="max-content"> <BaseHeading fontSize="2xl" color="secondary"> Information not available </BaseHeading> </Box> )
            }
          </Box>
          <BaseText color="secondary" marginTop="32px" textAlign="right">
            Last Updated on 15 Dec, 2011
          </BaseText>
          <ReleateLinksAndInfo
              releatedLinks={CdaOrdinanceRelatedLinks}
              releatedInfo={CdaOrdinanceRelatedInfo}
          />
        </PageWraper>
      </>
  );
}

export default withTranslation()(CdaOrdinance1960);
