import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";
import Paragraph from "../../../components/Common/Paragraph";

export default function PlanningAndDesignWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Planning And Design Wing",
      link: "/planning-and-design-wing",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Planning And Design Wing" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Planning And Design Wing</BaseHeading>
              <Paragraph
                content="Planning & Design wing undertakes the planning of all urban and
                rural area within the framework of the existing Master Plan. It
                analyses the housing needs of the growing city and responds
                according to the trends of the market."
              />
            </Flex>
            <WingsAccordion type={"planning"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
