// /src/slices/tabsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tabsSlice = createSlice({
  name: 'tabs',
  initialState: {
    selectedTab: null, // Adjusted the naming for clarity
  },
  reducers: {
    settab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { settab } = tabsSlice.actions;
export const selectTab = (state) => state.tabs.selectedTab; // Adjusted the naming here
export default tabsSlice.reducer;
