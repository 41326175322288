import { Box, Input, Link } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import BaseImage from "../../Base/Image";

function SearchBar({ value, onChange, handleSearch, global, ...otherProps }) {
  return (
    <Box width="328px" height="40px" position="relative" {...otherProps}>
      <Input
        width="269px"
        height="39px"
        borderRightRadius="none"
        placeholder="Search"
        borderColor="grey2"
        _focusVisible={{ borderColor: "secondary", boxShadow: "none" }}
        _hover={{ borderColor: "grey1" }}
        background="white"
        value={value}
        onChange={onChange}
      />

      {global && value ? (
        <Link href={"/search"}>
          <BaseImage
            source="Icon-Search.svg"
            width="40px"
            height="40px"
            _hover={{ cursor: "pointer" }}
            position="absolute"
            right="22px"
            top="0px"
            onClick={() => {
              handleSearch();
            }}
          />
        </Link>
      ) : (
        <BaseImage
          source="Icon-Search.svg"
          width="40px"
          height="40px"
          _hover={{ cursor: "pointer" }}
          position="absolute"
          right="22px"
          top="0px"
          onClick={() => {
            handleSearch();
          }}
        />
      )}
    </Box>
  );
}

export default withTranslation()(SearchBar);
