// /src/slices/tabsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const AccessibiltyPanelSlice = createSlice({
  name: 'AccessibiltyPanel',
  initialState: {
    isAccessibiltyPanelOpen: false
  },
  reducers: {
    setisAccessibiltyPanelOpen:(state, action) => {
        state.isAccessibiltyPanelOpen = action.payload;
      },
  },
});

export const {setisAccessibiltyPanelOpen } = AccessibiltyPanelSlice.actions;
export const selectAccessibility = (state) => state.AccessibiltyPanelSlice; // Adjusted the naming here
export default AccessibiltyPanelSlice.reducer;
