import React from "react";
import { Flex } from "@chakra-ui/react";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseText from "../../../components/Base/Text";
import PageWrapper from "../../../components/Base/PageWraper";
import WingsAccordion from "../../../components/WingsAndDirectorates/Accordion";
import BaseHeading from "../../../components/Base/Heading";
import ShowDownloadLink from "../../../components/WingsAndDirectorates/ShowDownloadLink";

export default function AdministrationWing() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Wings & Directorates",
      link: "#",
    },
    {
      name: "Administration Wing",
      link: "/administration-wing",
    },
  ];
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Administration Wing" />

      <PageWrapper>
        <Flex gap="33px" flexDirection="column">
          <Flex flexDirection="column" gap="24px">
            <Flex flexDirection="column" gap="16px" fontSize="md">
              <BaseHeading fontSize="xl">Administration Wing</BaseHeading>
              <BaseText fontSize="md">
                Administration Wing is responsible for ensuring that CDA has the
                right personnel, resources, and support systems in place to
                carry out its operations effectively. Additionally, it oversees
                the allocation of quarters and residences to employees.
                Furthermore, the secretariat takes charge of all municipal
                functions, ensuring compliance with Municipal By-laws.
              </BaseText>
            </Flex>
            <ShowDownloadLink title="To download Municipal administration forms." />
            <WingsAccordion type={"administration"} />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  );
}
