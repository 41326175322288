import { Box, Flex } from "@chakra-ui/react";
import BaseHeading from "../../../../components/Base/Heading";
import PageHeader from "../../../../components/Common/PageHeader";
import PageWraper from "../../../../components/Base/PageWraper";
import { useEffect, useState } from "react";
import { getAreaDataByID } from "../../../../utils/API";
import { useLocation } from "react-router-dom";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";
import CircularLoader from "../../../../components/Common/CircularLoader";
import BaseImage from "../../../../components/Base/Image";
import BaseButton from "../../../../components/Base/Button";
import BaseLink from "../../../../components/Base/Link";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import DataTable from "../../../../components/Common/simpledataTable/DataTable";
import "./index.css";
import BaseText from "../../../../components/Base/Text";

export default function PrivateScheme() {
  const location = useLocation();
  const dataID = location?.state ? location?.state : null;

  const [tableLoading, setTableLoading] = useState(true);
  const [areaData, setAreaData] = useState(null);
  const [areaName, setAreaName] = useState("");
  const [areaPlan, setAreaPlan] = useState("");
  const [tableConfigdata, settableConfigdata] = useState(null);
  const [lastUpdate, setLastUpdate] = useState("");

  const keys = {
    Name: "Project Data",
    Detail: "Detail",
    Attachments: "Attachments",
  };

  const headers = {
    Name: "Project Data",
    Detail: "Detail",
    Attachments: "Attachments",
  };

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Housing Societies",
      link: "/housing-societies",
    },
    {
      name: "Commercial Projects (Private Scheme)",
      link: "/housing-societies/commercial-private",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getAreaDataByID(dataID.id);
    const data = response.data;

    const societyTable = [
      {
        Name: "Project Developers",
        Detail: data?.project_developers,
        Attachments: "",
      },
      { Name: "Covered Area", Detail: data?.area, Attachments: "" },
      {
        Name: "Project Approved By",
        Detail: data?.approved_by,
        Attachments: "",
      },
      {
        Name: "Completion Time",
        Detail: data?.completion_time,
        Attachments: "",
      },
      {
        Name: "Project Approval Terms",
        Detail: data?.approval_term,
        Attachments: "",
      },
      { Name: "Project Cost", Detail: data?.project_cost, Attachments: "" },
      {
        Name: "Project NOC",
        Detail: data?.nocDescription,
        Attachments: data?.noc,
      },
      { Name: "Contact Phone", Detail: data?.phone, Attachments: "" },
      { Name: "Project Location", Detail: data?.location, Attachments: "" },
    ];

    const getDetailOrDefault = (property, defaultValue = "--") => {
      return data !== undefined &&
        data[property] !== undefined &&
        data[property] !== null
        ? data[property]
        : defaultValue;
    };
    const tableConfig = [
      [
        {
          Name: "Project Developer",
          detail: getDetailOrDefault("project_developers"),
          Attachments: "",
        },
        {
          Name: "Covered Area",
          detail: getDetailOrDefault("area"),
          Attachments: "",
        },
        {
          Name: "Project Cost",
          detail: getDetailOrDefault("project_cost"),
          Attachments: "",
        },
        {
          Name: "Project Location",
          detail: getDetailOrDefault("location"),
          Attachments: "",
        },
      ],
      [
        {
          Name: "Project Approved By",
          detail: getDetailOrDefault("approved_by"),
          Attachments: "",
        },
        {
          Name: "Project Approval Terms",
          detail: getDetailOrDefault("approval_term"),
          Attachments: "",
        },
        {
          Name: "Project Timeline",
          detail: getDetailOrDefault("completion_time"),
          Attachments: "",
        },
      ],
      [
        {
          Name: "Completion Time",
          detail: getDetailOrDefault("completion_time"),
          Attachments: "",
        },
        { Name: "Phone", detail: getDetailOrDefault("phone"), Attachments: "" },
        {
          Name: "Project NOC",
          detail: getDetailOrDefault("nocDescription"),
          Attachments: data?.noc,
        },
      ],
    ];

    settableConfigdata(tableConfig);

    setLastUpdate(data?.last_updated ? data.last_updated : "--");
    setAreaName(data?.name);
    setAreaPlan(JSON.parse(data?.layoutPlan));
    setAreaData(societyTable);

    setTableLoading(false);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Commercial Projects (Private Scheme)" />
      <PageWraper>
        <BaseHeading fontSize="xl">{areaName}</BaseHeading>
        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading && areaData && areaData?.length > 0 ? (
          <>
            <DataTable
              loading={false}
              headers={headers}
              marginTop="33px"
              tableConfig={tableConfigdata}
            />
            {/* <PaginationTable
              loading={false}
              headers={headers}
              marginTop="33px"
              dataSet={areaData}
              keys={keys}
              viewDetail={true}
            /> */}
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}

        {areaPlan?.length && areaPlan?.length > 0 ? (
          <>
            <Box className="ChakraCardMap">
              <BaseHeading fontSize="lg" marginTop="">
                Project Layout Plan
              </BaseHeading>

              <Flex alignItems="center" flexWrap="wrap" gap="10px">
                {areaPlan.map((item, index) => (
                  <Box className="mapBox">
                    <Flex
                      width="544px"
                      height="201px"
                      marginTop="30px"
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <BaseButton zIndex="1">
                        <BaseLink
                          isExternal
                          href={item}
                          style={{ textDecoration: "none" }}
                        >
                          View Plan
                        </BaseLink>
                      </BaseButton>
                      <BaseImage
                        source={item}
                        alt="layout plan"
                        width="100%"
                        height="100%"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                        objectFit="cover"
                        opacity="0.8"
                        fallbackSrc="/generic-file.png"
                      />
                    </Flex>
                    <BaseHeading
                      textAlign="center"
                      fontSize="lg"
                      marginTop="16px"
                    >
                      Plan {index === 0 ? "A" : "B"}
                    </BaseHeading>
                  </Box>
                ))}
              </Flex>
            </Box>

            <BaseText fontSize="md" className="updatedtext">{`Last Updated on ${lastUpdate}`}</BaseText>

          </>
        ) : null}
      </PageWraper>
    </>
  );
}
