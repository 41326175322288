import {Flex, Image} from "@chakra-ui/react";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import BaseText from "../../../components/Base/Text";
import BaseHeading from "../../../components/Base/Heading";
import {useState} from "react";
import PageWraper from "../../../components/Base/PageWraper";

export const data1 = [
    {
        serialNumber: "1",
        Position: "Graphic Designer/ Video Editor",
        title:
            "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
        period:
            "Bachelor’s degree in Mass Communication, Marketing, English, Journalism, or related fied",
        job: "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
    },
    {
        serialNumber: "2",
        Position: "Graphic Designer/ Video Editor",
        title:
            "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
        period:
            "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
        job: "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
    },
    {
        serialNumber: "3",
        Position: "IT Officer",
        title:
            "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
        period:
            "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
        job: "Bachelor’s degree in Mass Communication,Marketing, English, Journalism, or related fied",
    },
];

const keys2 = {
    serialNumber: "serialNumber",
    Position: "Position",
    title: "title",
    period: "period",
};

const headers2 = {
    serialNumber: "Sr No.",
    Position: "Position",
    title: "Qualification",
    period: "What You’ll Do",
};
export default function Career() {
    const breadcrumbs = [
        {name: "Home", link: "/"},

        {
            name: "Careers",
            link: "/career",
        },
    ];

    const [imageUrls, setImageUrls] = useState([]);

    return (
        <>
            <BreadCrumb breadcrumbs={breadcrumbs}/>
            <PageHeader title="Careers and Jobs"/>

            <PageWraper>
                <Flex gap={"30px"} flexDirection="column">
                    <BaseHeading fontSize="lg" flexDirection="column">
                        CDA INTERNSHIP PROGRAM
                    </BaseHeading>
                    <BaseHeading fontSize="md" color="grey1">
                        Join CDA as Intern
                    </BaseHeading>

                    {/* <PaginationTable
            loading={false}
            headers={headers2}
            dataSet={data1}
            keys={keys2}
            withPagination={false}
          /> */}
                    <Image src="career.png" borderRadius="8px"/>
                    <Flex gap={"10px"} flexDirection="column">
                        <BaseHeading fontSize="lg">Instructions</BaseHeading>
                        <BaseText textAlign="justify" fontSize={"md"}>
                            1. Only e-applications may be submitted before closing date i.e.,
                            August 18th, 2023. Application form can be accessed at the
                            following link:
                            <strong style={{color: "#4EA858"}}>
                                &nbsp;https://www.cda.gov.pk/resource_center/careers/internship-application
                            </strong>
                        </BaseText>
                        <BaseText textAlign="justify" fontSize={"md"}>
                            2. Applicants with consistently excellent academic record shall be
                            shortlisted. Percentage marks obtained in SSC, HSSC and Bachelors
                            or equivalent shall be given corresponding weightage from thirty
                            (30) marks each i.e., total ninety (90) marks.
                        </BaseText>
                        <BaseText fontSize={"md"} textAlign="justify">
                            3. Name of shortlisted candidate for interview will be uploaded on
                            CDA website with details guidelines.
                        </BaseText>
                        <BaseText fontSize={"md"} textAlign="justify">
                            4. Ten (10) marks will be allocated for interview. Candidates with
                            highest marks in order of merit will be considered for offer of
                            Internship.
                        </BaseText>
                        <BaseText textAlign="justify" fontSize={"md"}>
                            5. Duration of Internship is 6 months. Monthly stipend @ Rs.
                            60,000/- will be paid (Taxes shall apply).
                        </BaseText>
                        <BaseText fontSize={"md"} textAlign="justify">
                            6. The internship is neither offer of appointment nor shall confer
                            any right of employment/regularisation etc. Thus interns will only
                            be entitled to lump-sum stipend mentioned above and will be
                            governed by rules of CDA with regard to disciplinary matters and
                            confidentiality.
                        </BaseText>
                        <BaseText textAlign="justify" fontSize={"md"}>
                            7. Placement, assigning of duties, and termination etc. of
                            internship shall be at the discretion of CDA. However, Interns can
                            also request for termination of internship on 15 days notice in
                            writing but internship completion certificate shall not be awarded
                            in such cases.
                        </BaseText>
                        <BaseText textAlign="justify" fontSize={"md"}>
                            8. For any query, please email at
                            <a
                                href="mailto:jobs@cda.gov.pk"
                                style={{color: "#4EA858", fontWeight: "600"}}
                            >
                                &nbsp;jobs@cda.gov.pk
                            </a>
                        </BaseText>
                        <BaseHeading fontSize={"lg"} textAlign="right">
                            Director, Information Technology
                        </BaseHeading>

                    </Flex>
                </Flex>
            </PageWraper>
        </>
    );
}
