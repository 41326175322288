import { Box, Flex, Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import BaseHeading from "../../../components/Base/Heading";
import { years } from "../../../utils/constants";

import BaseMenuButton from "../../../components/Base/MenuButton";
import "./style.css";

import PageWraper from "../../../components/Base/PageWraper";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import { getBoardMeetings } from "../../../utils/API";
import { useEffect, useState } from "react";
import { convertToPKT, getOrdinal } from "../../../utils/helpers";
import CircularLoader from "../../../components/Common/CircularLoader";
import Pagination from "../../../components/Common/Pagination";

function BoardMeetings() {
  const currentDate = new Date();

  const [meetingData, setMeetingData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      link: "#",
    },
    {
      name: "Board Meetings",
      link: "/board-meetings",
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    const data = await getBoardMeetings(selectedYear, activePage, pageSize);
    return data;
  };

  const handleChange = (e) => {
    if (selectedYear !== e?.target?.innerHTML) {
      setSelectedYear(e?.target?.innerHTML);
      setPageSize(10);
      setActivePage(1);
    }
  };

  useEffect(() => {
    let i = 0;
    while (!years.includes(`${currentDate.getFullYear() - i}`)) {
      years.push(`${currentDate.getFullYear()}`);
      i++;

      if (years.includes(`${currentDate.getFullYear() - i}`)) break;
    }
  }, []);

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    setPageSize(event);
    setActivePage(1);
  };



  // ${i?.order ? i?.order : ""}

  useEffect(() => {
    fetchData()
      .then((response) => {
        let final = response?.data?.data?.map((i, index) => {
          i.date =
            i?.is_feature === "true"
              ? "Special Meeting"
              : convertToPKT(i?.date, "meeting");
          i.summery = `${i?.summery} ${i?.year}`;
          return i;
        });
        setMeetingData(final);
        setPages(Math.ceil(response?.data?.total / pageSize));
      })
      .finally(() => setTableLoading(false));
  }, [selectedYear, activePage, pageSize]);

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();  
    setSelectedYear(year.toString()); // Set the selected year to the current year
  }, []);

  
  const keys = {
    date: "date",
    summery: "summery",
    pdf: "pdf",
  };

  const headers = {
    date: "Date",
    summery: "Minutes of Meeting",
    pdf: "Download",
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Board Meetings" />
      <PageWraper>
        <Flex gap="40px" flexDirection="column">
          <Flex gap="24px" alignItems="baseline">
            <BaseHeading fontSize="xl" color="secondary">
              Select Year
            </BaseHeading>
            <Menu>
              <BaseMenuButton className="selectYear" variant="outlined">
                {selectedYear}
              </BaseMenuButton>
              <MenuList height="250px" overflow="scroll">
                {years
                  ?.slice()
                  ?.reverse()
                  ?.map((item) => (
                    <MenuItem
                      _focus={{ backgroundColor: "transparent" }}
                      _hover={{ backgroundColor: "var(--chakra-colors-grey3)" }}
                      key={item}
                      onClick={(e) => handleChange(e)}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </Flex>
          {tableLoading ? (
            <CircularLoader />
          ) : !tableLoading && meetingData?.length > 0 ? (
            <>
              <PaginationTable
                loading={false}
                headers={headers}
                dataSet={meetingData}
                keys={keys}
                downloadableContent
                withPagination={false}
                meetings
              />
              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                pages={pages}
                handlePageSelect={(event) => handlePageSelect(event)}
                handlePageSize={(event) => handlePageSize(event)}
                marginTop="50px"
              />
            </>
          ) : (
            <Box margin="140px auto" width="max-content">
              <BaseHeading fontSize="2xl" color="secondary">
              Information not available
              </BaseHeading>
            </Box>
          )}
        </Flex>
      </PageWraper>
    </>
  );
}

export default withTranslation()(BoardMeetings);
