import PageWraper from "../../../components/Base/PageWraper";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import TabHeader from "../../../components/Common/TabHeader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex } from "@chakra-ui/react";
import AboutUs from "../../../components/CdaModalSchool/AboutUs";
import Faculty from "../../../components/CdaModalSchool/Faculty";
import NewsAndUpdates from "../../../components/Home/NewsAndUpdates";
import {
  galleryData,
  publicNoticesModalSchool,
} from "../../../utils/constants";
import Acadamics from "../../../components/CdaModalSchool/Acadamics";
import SchoolGallery from "../../../components/CdaModalSchool/PhotoAlbum";
import { useDispatch } from "react-redux";
import { setGalleryImages } from "../../../reduxToolkit/slices/galleryImages";

export default function CdaModelSchool() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "CDA Model School",
      link: "/cda-model-school",
    },
  ];
  const [tab, setType] = useState("about-us");

  const [albumbs, setAlbumbs] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabsData = [
    {
      title: "About Us",
      content: "",
      type: "about-us",
    },
    {
      title: "Faculty",
      content: "",
      type: "faculty",
    },
    {
      title: "Public Notices",
      content: "",
      type: "public-notices",
    },
    {
      title: "Academics",
      content: "",
      type: "acadamics",
    },
    {
      title: "Gallery",
      content: "",
      type: "gallery",
    },
  ];

  useEffect(() => {
    const data = [];
    Object.keys(galleryData).forEach((key) => {
      data.push(galleryData[key][0]);
    });
    setAlbumbs(data);


    const albumbImages = [];
    Object.keys(galleryData).forEach((key) => {
      albumbImages.push(galleryData[key]);
    });



    setImageUrls(albumbImages);
  }, []);

  
  const pageContent = () => {
    switch (tab) {
      case "about-us":
        return <AboutUs />;

      case "faculty":
        return <Faculty />;
      case "public-notices":
        return (
          <NewsAndUpdates
            descFontSize="md"
            headingFontSize="lg"
            heading="Public Notices"
            data={publicNoticesModalSchool}
          />
        );
      case "acadamics":
        return <Acadamics />;

      case "gallery":
        return (
          <SchoolGallery
            title="School Gallery"
            data={albumbs}
            oncategoryChange={oncategoryChange}
          />
        );

      default:
        return <></>;
    }
  };

  const oncategoryChange = (index) => {
    setImageUrls(imageUrls[index]);
    dispatch(setGalleryImages(imageUrls[index]));
    navigate("/galleryImages");
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="CDA Model School" />
      <PageWraper>
        <Flex gap="40px" flexDirection="column">
          <TabHeader tabsData={tabsData} onTabChange={setType} />
          {pageContent()}
        </Flex>
      </PageWraper>
    </>
  );
}
