import { Flex } from "@chakra-ui/react";
import BaseHeading from "../../Base/Heading";
import BaseImage from "../../Base/Image";
import BaseText from "../../Base/Text";
import Paragraph from "../../Common/Paragraph";
import "./index.css";

export default function AboutUs() {
  return (
    <Flex gap="24px" flexDirection="column">
      <Flex gap="16px" flexDirection="column">
        <BaseHeading fontSize="xl" marginBottom="8px">
          About Us
        </BaseHeading>
        <Paragraph
          content="Considering the need for providing quality schooling facilities
            for the children in Islamabad, based on modern teaching techniques,
            the board of the Capital Development Authority approved the
            establishment of the CDA Model School, I-9, Islamabad."
        />
        <Paragraph
          content="CDA Model School was established in March 1986, initially offering classes from Nursery to class II. Each year an upper class was
            added. Currently the education extend up to the Matriculation level."
        />

        <Paragraph
          content=" The junior section (up to class V) accomodates both boys and girls, while the
            senior section (up to class X) is exclusively for girls."
        />
      </Flex>
      <Flex gap="33px" className="AboutUs-image-container">
        <BaseImage
          objectFit="cover"
          source="school_front 1.jpg"
          width="559px"
          height="419px"
        ></BaseImage>
        <BaseImage
          objectFit="cover"
          source="aboutUs_slot2.jpg"
          width="558px"
          height="419px"
        />
      </Flex>
      {/* <Flex gap="24px" flexDirection="column">
        <BaseHeading fontSize="xl">Chairman's Message</BaseHeading>
        <Paragraph
          content="As we look forward to the future, we envision the CDA Model School
              as a place where excellence in education means educating the whole
              child. We aim to provide the knowledge, skills, abilities,
              attitudes and beliefs that are essential for a productive and
              successful life. We recognize that these goals are best achieved
              when the school provides an environment in which teachers,
              parents, staff and others develop and practise core values that
              benefit the academic, emotional and social needs of all children.
              Strong communication between home and school forms the basis for
              the caring, nurturing, family atmosphere that is essential to CDA
              Model School. This atmosphere promotes an excellence in teaching
              that results in high academic achievement and enables children to
              realise personal excellence at all levels."
        />
      </Flex>
      <Flex gap="24px" flexDirection="column">
        <BaseHeading fontSize="xl">Member(Administration) Message</BaseHeading>
        <Paragraph
          content="Welcome to the CDA Model School, where we have been preparing
              pioneers for the future for the last 23 years. CDA Model School is
              rich in traditions and programs. We encourage all of our
              students to find their niche, to blaze their own trail through
              school, making friends and memories along the way. Students who
              actively participate will feel more connected to the school and
              will perform better academically. We also establish high standards for
              student performance in the classroom. Our teachers are
              committed to seeing each student succeed in meeting or exceeding
              standards as well as preparing for life after school. School is a
              place that most people remember fondly. For me, it was the scene
              of many moments that define my life. We strongly encourage
              students, parents, teachers and community leaders to join together
              for the common goal of a better tomorrow."
        />
      </Flex> */}
      <Flex gap="24px" flexDirection="column">
        <BaseHeading fontSize="xl">Principal's Message</BaseHeading>
        <Paragraph
          content="The World is progressing at full tilt. It is the need of hour to
              be equipped with the modern technology. As teachers it is
              obligatory for us to equip our youth with the best of knowledge as
              they are to shoulder their responsibilities tomorrow. CDA Model
              School is the torchbearer of knowledge and we are doing our best
              to impart quality education. Teachers and parents both make a
              wonderful combination to inculcate children with a good all round
              education. We are always keen to involve parents in the education
              process and want their helping hand. In this regard, we do our
              best to provide all kinds of information."
        />
      </Flex>
      <BaseHeading fontSize="xl">Facilities</BaseHeading>
      <BaseHeading fontSize="lg">Classrooms</BaseHeading>
      <BaseText fontSize="md">
        Classrooms are airy, well-decorated and equipped with heaters and room
        coolers etc.
      </BaseText>
      <Flex gap="24px" className="AboutUs-image-container">
        <BaseImage
          objectFit="cover"
          // source="aboutUsImg3.jpg"
          source="aboutUs_slot3.jpg"
          width="561.96px"
          height="419px"
        />
        <BaseImage
          objectFit="cover"
          source="aboutUsImg4.jpg"
          width="558px"
          height="419px"
        />
      </Flex>
      <Flex gap="16px" flexDirection="column">
        <BaseHeading fontSize="lg">Labs</BaseHeading>
        <BaseText fontSize="md">
          The school has three well-equipped labs for the students.
        </BaseText>
        <BaseHeading fontSize="md">1. Science Lab</BaseHeading>
        <BaseText fontSize="md">
          It is equipped with all the necessary apparatus for performing
          experiments of Physics, Chemistry and Biology.
        </BaseText>
        <BaseHeading fontSize="md">2. Home Economics Lab</BaseHeading>
        <BaseText fontSize="md">
          It has four cooking-ranges, a refrigerator, a deep-freezer, a
          microwave oven, sewing machines, crockery etc. for conducting
          practicals in home economics.
        </BaseText>
        <BaseHeading fontSize="md">3. Computer Lab</BaseHeading>
        <BaseText fontSize="md">
          A fully air-conditioned computer lab having 20 P-IV workstations
          loaded with latest operating systems and software packages. A laser
          printer, a scanner, a CD-Writer, DVD ROM etc. is also available for
          students. The internet facility in school is one of its prominent
          features. It has 2mb/s internet connectivity for research purposes for
          students and faculty. The lab is interconnected with fibre optic cable
          attached through LAN (local area network).
        </BaseText>
      </Flex>

      <Flex gap="24px" flexDirection="column">
        <BaseHeading fontSize="lg">Library</BaseHeading>
        <BaseText fontSize="md">
          The school library aims to support the academics & improve general
          knowledge of students. The library has a reasonable collection of
          books & magazines. All daily newspapers & different magazines are
          provided to the students & the staff in the library. The number of
          books is 5076. School has a plan to further improve it to facilitate
          the students & the staff.
        </BaseText>

        <Flex gap="16px" className="AboutUs-image-container">
          <BaseImage
            objectFit="cover"
            source="aboutUsImg5.jpg"
            width="561.96px"
            height="419px"
          />
          <BaseImage
            objectFit="cover"
            source="aboutUsImg3.jpg"
            width="558px"
            height="419px"
          />
        </Flex>
        <BaseHeading fontSize="lg">Sports</BaseHeading>
        <BaseText fontSize="md">
          The sports provided for the students include badminton, base ball,
          shot-put, javelin, discus throw etc.
        </BaseText>
        <BaseHeading fontSize="lg">Health Facilities</BaseHeading>
        <BaseText fontSize="md">
          The school has a first aid facility to attend to minor emergencies. In
          serious cases, the students are referred to the CDA Hospital. In
          collaboration with Capital Hospital, Medical check-up of all the
          students is managed on an annual basis. Reports are kept in record and
          a copy is sent to the parents as well.
        </BaseText>
        <BaseHeading fontSize="lg">Transport</BaseHeading>
        <BaseText fontSize="md">
          School buses ply the twin cities to pick and drop the students close
          to their residences. The service is provided at nominal rates.
          Transport Officer can be contacted for further details.
        </BaseText>
        <BaseHeading fontSize="lg">Canteen</BaseHeading>
        <BaseText fontSize="md">
          An amiable and friendly school canteen provides a pleasant atmosphere
          for students. Refreshments are available at subsidised rates to the
          students and faculty.
        </BaseText>
        <BaseHeading fontSize="lg">Auditorium</BaseHeading>
        <BaseText fontSize="md">
          A well-decorated, air conditioned auditorium is available, where
          different curricular and co-curricular activities are held.
        </BaseText>
      </Flex>
    </Flex>
  );
}
