import React from "react";
import "./style.css";
import BaseImage from "../../Base/Image";
import BaseText from "../../Base/Text";
import BaseHeading from "../Heading";
import { Box, Link } from "@chakra-ui/react";
const Card = ({ data }) => {
  return (
    <>
      {data?.map((item, i) => {
        return (
          <Box className="container Parks-of-ICT-container" key={i}>
            <Box className="left">
              <BaseHeading className="head1" fontSize="lg" fontWeight="bold">
                {item?.title}
              </BaseHeading>

              <BaseText
                className="cardDetail"
                fontSize="md"
                color="secondary"
                textAlign="justify"
              >
                {item.description}
              </BaseText>

              {item?.mapLink && (
                <Link
                  className="viewMapBtn"
                  href={item?.mapLink}
                  fontSize="md"
                  fontWeight="bold"
                  color="primary"
                  isExternal
                  _hover={{ textDecoration: "none" }}
                >
                  View MAP
                </Link>
              )}
            </Box>
            <Box className="divider"></Box>
            <Box className="right">
              {item.img && (
                <BaseImage
                  className="cardImg"
                  source={item.img}
                  width="100%"
                  height="241px"
                />
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default Card;
