import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import BaseText from "../../../components/Base/Text";
import BaseHeading from "../../../components/Base/Heading";
import PageWraper from "../../../components/Base/PageWraper";
import {
  Box,
  Flex,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import Searchbar from "../../../components/Common/Searchbar";
import BaseButton from "../../../components/Base/Button";
import BaseImage from "../../../components/Base/Image";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import { withTranslation } from "react-i18next";
import {
  getAreaData,
  getCdaData,
  getHousingSocieties,
  getPrivateData,
} from "../../../utils/API";
import { useEffect, useState } from "react";
import CircularLoader from "../../../components/Common/CircularLoader";
import { exportToCSVHousingSchemes } from "../../../utils/helpers";
import Pagination from "../../../components/Common/Pagination";
import { housingSocieties } from "../../../utils/constants";
import "./index.css";
import TabHeader from "../../../components/Common/TabHeader";

function HousingSocieties() {
  const [housingSocietyData, setHousingSocietyData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [tab, setTab] = useState("Housing");

  const keys = {
    Name: "Name",
    LOP: "LOP",
    NOC: "NOC",
  };

  const headers = {
    Name: "Name",
    LOP: "LOP",
    NOC: "NOC",
  };

  const keys1 = {
    Name: "Name",
    LOP: "LOP",
    NOC: "NOC",
    Category: "Category",
  };

  const headers1 = {
    Name: "Name",
    LOP: "LOP",
    NOC: "NOC",
    Category: "Category",
  };

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Housing Societies",
      link: "/housing-societies",
    },
  ];

  const tabsData = [
    {
      title: "Private Housing Society",
      type: "Housing",
    },
    {
      title: "Commerical Projects (Private Scheme)",
      type: "Private",
    },
    {
      title: "Commerical Projects (CDA Sector)",
      type: "cda",
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    const res = await getHousingSocieties(pageSize, activePage);
    return res?.data;
  };

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  // const handlePageSize = (event) => {
  //   setPageSize(event);
  //   setActivePage(1);
  // };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      if (tab === "Housing") {
        getHousingScehmeData();
      } else if (tab === "Private") {
        getPrivateSchemeData();
      } else {
        getCdaSchemeData();
      }
      // setPages(Math.ceil(response?.total / pageSize));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (tab === "Housing") {
      getHousingScehmeData();
    } else if (tab === "Private") {
      getPrivateSchemeData();
    } else {
      getCdaSchemeData();
    }
  }, [tab]);

  const getHousingScehmeData = async () => {
    const response = await getAreaData();
    const reversedArray = [...response.data].reverse();
    setHousingSocietyData(reversedArray);
  };

  const getPrivateSchemeData = async () => {
    const response = await getPrivateData();
    const reversedArray = [...response.data].reverse();
    setHousingSocietyData(reversedArray);
  };

  const getCdaSchemeData = async () => {
    const response = await getCdaData();
    const reversedArray = [...response.data].reverse();
    setHousingSocietyData(reversedArray);
  };

  //search
  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = housingSocietyData?.filter((item) =>
      item?.Name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    // setActivePage(1);
    // setPageSize(10);
    setHousingSocietyData(filteredData);
  };

  //sort
  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    const sortedData = [...housingSocietyData]?.sort((a, b) => {
      const nameA = a?.Name?.toLowerCase();
      const nameB = b?.Name?.toLowerCase();
      if (newSortOrder === "asc") {
        return nameA?.localeCompare(nameB);
      } else {
        return nameB?.localeCompare(nameA);
      }
    });
    setHousingSocietyData(sortedData);
    setSortOrder(newSortOrder);
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader
        title={
          tab === "Housing"
            ? "Private Housing Societies in Islamabad Capital Territory"
            : tab === "Private"
            ? "Commerical Projects (Private Scheme)"
            : "Commerical Projects (CDA Sector)"
        }
      />
      <PageWraper>
        <TabHeader tabsData={tabsData} onTabChange={setTab} />

        <Flex flexDirection="column" gap="16px">
          <BaseText fontSize="md">
            In the Interest of the general public and other stakeholders, it is
            informed that the CDA Ordinance, 1960, ICT (Zoning) Regulations, 1992
            and the revised modalities and procedures framed there under for
            private housing schemes empower CDA to regulate planning and
            development of private housing schemes.
            {/* Capital Development Authority operates under its standard
            procedures. The procedures listed below are the most frequently
            requested. However, this is not intended to be a complete list of
            operating procedures. These procedures are provided for
            informational purposes only. */}
          </BaseText>
          <BaseText fontSize="md">
            In exercise of the powers conferred by section 51 of the Capital
            Development Authority Ordinance 1960, the Capital Development
            Authority has issued regulations for the "planning and development of
            private housing/farm housing, apartment/commercial schemes/ projects
            in Zone-II, IV and V of Islamabad Capital Territory (ICT), 2023
            framed under ICT Zoning Regulations 1992".
            {/* CDA reserves the right to modify or replace a posted procedure or
            policy at any time without notice. If you do not find any procedure
            here, please{" "}
            <Link href="/contact-us" color="primary" textDecoration="underline">
              Ask CDA
            </Link> */}
          </BaseText>

          {/* <OrderedList fontSize="md">
            <ListItem>Procedure required for Change of Trade.</ListItem>
            <ListItem>Registration of Contractors/ Firms in CDA.</ListItem>
            <ListItem>Contract Awarding Procedure.</ListItem>
            <ListItem>
              Permission to Display Signboard/Pana Flex/Neon Sign Etc.
            </ListItem>
            <ListItem>Property Tax Examption Declaration Form.</ListItem>
            <ListItem>Life-cycle of a Project</ListItem>
          </OrderedList> */}
        </Flex>

        <Flex flexDirection="column" gap="24px" marginTop="24px">
          {tab === "Housing" && (
            <>
              {" "}
              <BaseHeading fontSize="xl">
                Two-tier approvals of private housing schemes are granted by
                CDA:
              </BaseHeading>
              <BaseText fontSize="md">
                - Technical approval of the Layout Plan (LOP) of the scheme.
              </BaseText>
              <BaseText fontSize="md">
                - Upon completion of subsequent formalities, the No Objection
                Certificates (NOC) for development of the scheme are issued. The
                sponsors can start development work and the sale of plots after
                obtaining NOC from CDA. Application addressed to Director
                Municipal Administration, CDA Islamabad.
              </BaseText>
              {/* <UnorderedList fontSize="md">
            <ListItem>NIC Copy of allottee (s) 4 Nos.</ListItem>
            <ListItem>Copy of allotment letter</ListItem>
          </UnorderedList> */}
              {/* <BaseHeading fontSize="xl">
            Information about Private Housing Schemes
          </BaseHeading> */}
              <BaseText fontSize="md">
                The information about private housing schemes is placed on the
                website under clause 12(VIII), 40(15), 41(III) of
                <Link
                  href="pdf/PrivateHousingRegulations-Zone-2-4-5.pdf"
                  color="primary"
                  isExternal
                >
                  &nbsp; S.R.O. 886(I) 2023&nbsp;
                </Link>
                published on July 7th, 2023.
              </BaseText>
            </>
          )}

          <Box className="filters-main-box">
            <Searchbar
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              handleSearch={handleSearch}
            />
            <BaseButton
              fontWeight="400"
              bgColor="white"
              gap="10px"
              _hover={{ bgColor: "white" }}
              borderRadius="8px"
              border={
                sortOrder === "asc"
                  ? "1px solid var(--chakra-colors-grey3)"
                  : "1px solid var(--chakra-colors-primary)"
              }
              onClick={handleSort}
            >
              <BaseImage
                source="sort.svg"
                alt="sort"
                width="18px"
                height="12px"
              />
              Sort
            </BaseButton>
            <BaseButton
              colorScheme="brand"
              variant="outline"
              className="download-btn"
              onClick={() => {
                exportToCSVHousingSchemes(housingSocietyData);
              }}
            >
              Download List
            </BaseButton>
          </Box>
        </Flex>

        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading &&
          housingSocietyData &&
          housingSocietyData?.length > 0 ? (
          <>
            <PaginationTable
              loading={false}
              headers={tab === "cda" ? headers1 : headers}
              marginTop="33px"
              dataSet={housingSocietyData}
              keys={tab === "cda" ? keys1 : keys}
              navigation={true}
              type={tab}
            />
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}
      </PageWraper>
    </>
  );
}
export default withTranslation()(HousingSocieties);
