import React from "react";
import BaseText from "../../Base/Text";
import { Box } from "@chakra-ui/react";

export default function Banner({ description }) {
  return (
    <Box padding="10px 16px" backgroundColor="grey3">
      <BaseText fontSize="md">{description}</BaseText>
    </Box>
  );
}
