import React,{useState,useEffect} from "react";
import "./style.css";
// import { ListItem, UnorderedList } from "@chakra-ui/react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import BaseText from "../../Base/Text";
import { stringToHtml } from "../../../utils/helpers";
import {
  setisAccordianExpand
} from "../../../reduxToolkit/slices/changePage";
import {useDispatch } from "react-redux";

const AccordionComponent = ({ faqData }) => {

  const [isClick ,setIsClick] = useState(true)
    const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setisAccordianExpand(!isClick));
  },[isClick])

  return (
    <div>
      <Accordion allowToggle>
        {faqData?.map((item, index) => (
          <AccordionItem key={item.id} className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                <h2 onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton AccordionButtonText"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    {isExpanded ? (
                      <>
                        <Box
                          as="span"
                          className="numbersCssExpandedCase"
                          textAlign="left"
                        >
                          <BaseText color="secondary" fontWeight="bold">
                            {(index + 1)?.toString()}
                          </BaseText>
                        </Box>
                        <BaseText
                          fontSize="md"
                          fontWeight="bold"
                          textAlign="left"
                          color="white"
                        >
                          {item.question}
                        </BaseText>
                      </>
                    ) : (
                      <>
                        <Box
                          as="span"
                          className="numbersCssNotExpandedCase"
                          textAlign="left"
                        >
                          <BaseText color="white" fontWeight="bold">
                            {(index + 1)?.toString()}
                          </BaseText>
                        </Box>
                        <BaseText
                          fontSize="md"
                          fontWeight="bold"
                          textAlign="left"
                        >
                          {item.question}
                        </BaseText>
                      </>
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pl={4}>
                  <BaseText
                    textAlign="justify"
                    listStyle="decimal"
                    fontSize="md"
                    padding="8px"
                    margin="8px"
                    className={isExpanded ? "faqOpen": "faqOpen"} 
                  >
                    {stringToHtml(item?.answer)}
                  </BaseText>
                  {/* <UnorderedList className="paddingList">
                    {item.items.map((listItem, index) => (
                      <ListItem key={index}>{listItem}</ListItem>
                    ))}
                  </UnorderedList>
                  <BaseText>{item.contact}</BaseText> */}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
