import { Button } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export default function BaseButton({ children, ...otherprops }) {
  return (
    <Button fontSize="md" {...otherprops}>
      <Trans>{children}</Trans>
    </Button>
  );
}
