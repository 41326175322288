import { Box } from "@chakra-ui/react";
import "./style.css";

export default function PageWraper({ children, ...otherprops }) {
  return (
    <Box className= {otherprops.isGallery ? "pageWrapeIsGallery" : "pageWraper"}>
      {children}
    </Box>
  );
}
