import { useEffect, useState } from "react";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import PageWraper from "../../../components/Base/PageWraper";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import PhotoAlbum from "../../../components/CdaModalSchool/PhotoAlbum";
import { getGalleryData } from "../../../utils/API";
import "./style.css";
import { useDispatch,useSelector } from "react-redux";
import { setGalleryImages } from "../../../reduxToolkit/slices/galleryImages";
import Pagination from "../../../components/Common/Pagination";
import noImage from "../../../assets/galleryImages/no image.png";

import {setisGalleryPageChange} from "../../../reduxToolkit/slices/changePage"

import { useNavigate } from "react-router-dom";

export default function Gallery() {
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Gallery",
      link: "/gallery",
    },
  ];

  const [imageUrls, setImageUrls] = useState([]);
  const [photoAlbumData, setPhotoAlbumData] = useState([]);
  const [allFirstPictureForAlbum, setAllFirstPictureForAlbum] = useState([]);
  const [currentAllselectedPictures, setCurrentAllselectedPictures] = useState(
    []
  );
  const [apiResponseData, setapiResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [pages, setPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isGalleryPageChange = useSelector(
    (state) => state.changePage.isGalleryPageChange
  );
  const fetchData = async () => {
    const res = await getGalleryData();


    if (res) {
      setLoading(false);

      // this is not used
      // setImageUrls(res?.data.sunday_bazar);


      // dispatch(setGalleryImages(res?.data.sunday_bazar));
    }
    // Separate the "sunday_bazar" object and the rest of the objects
    let sundayBazarData = [];
    let otherData = {};
    // this is for to take and store zero index image from every object
    Object.keys(res?.data).forEach((key) => {
      if (key === "sunday_bazar") {
        sundayBazarData = res.data[key];
      } else {
        otherData[key] = res.data[key];
      }
    });

 // Initialize reorderedData with otherData
let reorderedData = { ...otherData };

// If sundayBazarData is not empty, add it to reorderedData
if (sundayBazarData.length > 0) {
  reorderedData.sunday_bazar = sundayBazarData;
}


const firstIndexDataArray = []; // this is for to take and store zero index image from every object to show in photo album
const convertedDataFromObjectToArray = [];

    // Convert the reordered data into an array form // to show in gallery and all imges page
    Object.keys(reorderedData).forEach((key) => {

      if (reorderedData[key].length === 0) {
        convertedDataFromObjectToArray.push([{ image: noImage, type: key ,description:"No Image",name:""}]);
      } else {
        convertedDataFromObjectToArray.push(reorderedData[key]);
      }
    });

    // this is for to take and store zero index image from every object
    Object.keys(reorderedData).forEach((key) => {

      if(res.data[key][0] === undefined){
        firstIndexDataArray.push({ image: noImage, type: key ,description:"No Image",name:""
      });
      }else{
        firstIndexDataArray.push(res.data[key][0]);

      }
     
    });

    setAllFirstPictureForAlbum(firstIndexDataArray);
    setapiResponseData(convertedDataFromObjectToArray);

    setPages(Math.ceil(firstIndexDataArray.length / pageSize));
    setTotalItems(firstIndexDataArray.length);

    const { startIndex, endIndex } = calculateDisplayRange(
      1,
      pageSize,
      firstIndexDataArray.length
    );
    const displayedItems = firstIndexDataArray.slice(startIndex, endIndex);
    // Render the displayedItems in your component
    setPhotoAlbumData(displayedItems);

    const currentAllselectedPicturesIndexData =
      convertedDataFromObjectToArray.slice(startIndex, endIndex);
    setCurrentAllselectedPictures(currentAllselectedPicturesIndexData);
  };

  useEffect(() => {
    fetchData();
    dispatch(setisGalleryPageChange(!isGalleryPageChange))
  }, []);

  const oncategoryChange = (index) => {
    setImageUrls(apiResponseData[index]);
    // dispatch(setGalleryImages(apiResponseData[index]));
    dispatch(setGalleryImages(currentAllselectedPictures[index]));
    navigate("/galleryImages");
    dispatch(setisGalleryPageChange(!isGalleryPageChange))
  };

  const calculateDisplayRange = (page, pageSizeP, totalItemsP) => {
    const startIndex = (page - 1) * pageSizeP;
    let endIndex = startIndex + pageSizeP;

    if (endIndex > totalItemsP) {
      endIndex = totalItemsP;
    }

    return { startIndex, endIndex };
  };

  const handlePageSelect = (page) => {
    const { startIndex, endIndex } = calculateDisplayRange(
      page.selected + 1,
      pageSize,
      totalItems
    );

    const displayedItems = allFirstPictureForAlbum.slice(startIndex, endIndex);
    // Render the displayedItems in your component
    setPhotoAlbumData(displayedItems);

    const currentAllselectedPicturesIndexData = apiResponseData.slice(
      startIndex,
      endIndex
    );
    setCurrentAllselectedPictures(currentAllselectedPicturesIndexData);
  };

  const handlePageSize = (event) => {
    setPageSize(event);
    setActivePage(1);
    setPages(Math.ceil(totalItems / event));
    const { startIndex, endIndex } = calculateDisplayRange(
      1,
      event,
      totalItems
    );
    const displayedItems = allFirstPictureForAlbum.slice(startIndex, endIndex);
    // Render the displayedItems in your component
    setPhotoAlbumData(displayedItems);

    const currentAllselectedPicturesIndexData = apiResponseData.slice(
      startIndex,
      endIndex
    );
    setCurrentAllselectedPictures(currentAllselectedPicturesIndexData);
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Gallery" />
      <PageWraper isGallery={true}>
        <Box className="ws">
          <Box className="PhotoAlbumWrapper">
            <Flex gap="40px" flexDirection="column">
              {loading ? (
                <Flex gap="75px" flexWrap="wrap">
                  {[1, 2, 3, 4].map((i) => {
                    return (
                      <Skeleton
                        key={i}
                        width="230px"
                        height="250px"
                        startColor="grey2"
                        endColor="grey3"
                        borderRadius="8px"
                      />
                    );
                  })}
                </Flex>
              ) : (
                <>
                  <PhotoAlbum
                    oncategoryChange={oncategoryChange}
                    noImage={noImage}
                    title=""
                    data={photoAlbumData}
                    isGallery={true}
                  />
                  <Pagination
                    activePage={activePage}
                    pageSize={pageSize}
                    pages={pages}
                    handlePageSelect={(event) => handlePageSelect(event)}
                    handlePageSize={(event) => handlePageSize(event)}
                    marginTop="50px"
                  />
                </>
              )}
              {/* <Separator
                width="100%"
                height="1px"
                background="var(--chakra-colors-grey2)"
              /> */}
            </Flex>
          </Box>
          {/* 
          <Box className="galleryWrapper" margin="40px auto">
            <CustomGallery
              images={imageUrls}
              isShowTitleOnImg={true}
              heading={"Islamabad Terrain"}
              title={"Islamabad terrain before development work"}
            />
          </Box> */}
        </Box>
      </PageWraper>
    </>
  );
}
