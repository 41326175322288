import { Flex } from "@chakra-ui/react";
import BaseHeading from "../../../components/Base/Heading";
import BaseImage from "../../../components/Base/Image";
import PageWraper from "../../../components/Base/PageWraper";
import BaseText from "../../../components/Base/Text";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import PageHeader from "../../../components/Common/PageHeader";
import {getChairmanData} from '../../../utils/API'
import Separator from "../../../components/Common/Separator";
import CircularLoader from "../../../components/Common/CircularLoader";
import "./index.css";
import { useEffect,useState } from "react";

export default function ChairmanMessage() {

  const [chairmanData, setChairmanData] = useState({});
  const [loading, setLoading] = useState(false);

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Chairman CDA Message",
      link: "/chairman-cda-message",
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    const data = await getChairmanData();

    setChairmanData(data)

  };

  useEffect(() => {
    fetchData().then((res) => setLoading(false));
  }, []);



  const splitTextIntoParagraphs = (text) => {
    return text.split('\r\n');
  };

  const paragraphs = chairmanData?.message ? splitTextIntoParagraphs(chairmanData.message) : [];

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="chairman-cda-message-title" />
      <PageWraper>


        {loading ? (
          <CircularLoader />
        ) : (
        <Flex
          gap={"48px"}
          marginTop="40px"
          alignItems={"center"}
          justifyContent="center"
          className="chairmanContainer"
        >
          <Flex>
            <BaseImage
              source={chairmanData?.image ? chairmanData.image : "noImage.svg"}
              width="470px"
              height="407px"
              className="chairmanInner"
            ></BaseImage>
          </Flex>
          <Flex
            gap="24px"
            flexDirection="column"
            alignItems={"center"}
            justifyContent="center"
            className="chairmanInner"
          >
            <BaseImage
              source="Group 1597883019.svg"
              width="54px"
              height="54px"
            />

              {paragraphs?.map((paragraph, index) => (
                <BaseText key={index} fontSize="md" textAlign="center">
                  {paragraph}
                </BaseText>
              ))}
      
            <Separator
              width="100%"
              height="1px"
              background="var(--chakra-colors-grey2)"
            />
            <BaseHeading fontSize={"md"}>{chairmanData?.name ? chairmanData.name : '' }</BaseHeading>
            <BaseText fontSize="md" marginTop="-20px">
              chairman-position
            </BaseText>
          </Flex>
        </Flex>
        )}
      </PageWraper>
    </>
  );
}
