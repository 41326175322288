import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import {
  auctionNoticesRelatedLinks,
  CdaOrdinanceRelatedInfo,
  publicNotices,
} from "../../../../utils/constants";
import Searchbar from "../../../../components/Common/Searchbar";
import React, { useEffect, useState } from "react";
import TabHeader from "../../../../components/Common/TabHeader";
import BaseHeading from "../../../../components/Base/Heading";
import ReleateLinksAndInfo from "../../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../../components/Base/PageWraper";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";
import { getPublicNotices } from "../../../../utils/API";
import CircularLoader from "../../../../components/Common/CircularLoader";
import { convertToPKT } from "../../../../utils/helpers";
import Pagination from "../../../../components/Common/Pagination";
import { Box } from "@chakra-ui/react";

function PublicNotices() {
  const [tab, setTab] = useState("active");
  const [noticesData, setNoticesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Public Notices",
      link: "/public-notice",
    },
  ];

  const keys = {
    summery: "summery",
    created_at: "created_at",
    pdf: "pdf",
  };

  const headers = {
    summery: "Description",
    created_at: "Date",
    pdf: "View Ad",
  };

  const tabsData = [
    {
      title: "Active Public Notices",
      content: "",
      type: "active",
    },
    {
      title: "Archive Public Notices",
      content: "",
      type: "archive",
    },
  ];

  const convertDate = (data) => {
    data?.map((el) => {
      el.created_at = el?.created_at ? convertToPKT(el?.created_at) : "";
    });

    return data;
  };

  const fetchData = async () => {
    setTableLoading(true);
    const res = await getPublicNotices("public", tab, pageSize, activePage);
    return res;
  };

  const handleTabChange = (value) => {
    setTab(value);
    setPageSize(10);
    setActivePage(1);
    setSearchQuery("");
  };

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    setPageSize(event);
    setActivePage(1);
  };

  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
      fetchData()
        .then((response) => {
          let ans = [];
          response?.data?.map((el) => {
            el.summery = el?.summery || el?.description || "...";
            ans.push(el);
          });
          setNoticesData(convertDate(ans));
          setPages(Math.ceil(response?.total / pageSize));
        })
        .finally(() => setTableLoading(false));
    }
  }, [tab, searchQuery, activePage, pageSize]);

  // const handleSearch = () => {
  //   if (searchQuery.trim() === "") {
  //     return;
  //   }
  //   const filteredData = noticesData?.filter((item) =>
  //     item?.summery?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  //   );

  //   setNoticesData(filteredData);
  //   setPages(1);
  // };


  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }

    const formattedSearchQuery = searchQuery.trim(); // Trim search query
    const filteredData = noticesData?.filter((item) => {
      // const headlineMatch = item?.headline?.toLowerCase()?.includes(formattedSearchQuery?.toLowerCase());
      const descriptionMatch =  item?.summery?.toLowerCase()?.includes(searchQuery?.toLowerCase())

      // let createdAtMatch = ( htmlParser(item?.tender_due_date)?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || htmlParser(item?.tender_due_date?.replace(/<sup>.*?<\/sup>/g, '').trim())?.toLowerCase()?.includes(searchQuery?.toLowerCase()) );
      
      // const numericalCreatedAt = item?.tender_due_date?.match(/\d+/)?.[0]; // Extract numerical part from tender_due_date
      //   if(createdAtMatch && searchQuery?.toLowerCase() === "20" ){
      //     createdAtMatch = numericalCreatedAt && numericalCreatedAt.includes(formattedSearchQuery)
      //   }

      return descriptionMatch 
      // || createdAtMatch;
    });

    setNoticesData(filteredData);
    setPages(Math.ceil(filteredData?.length/pageSize))
  }

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Public Notices" />
      <PageWraper>
        <TabHeader
          tabsData={tabsData}
          onTabChange={(e) => handleTabChange(e)}
        />
        {/* {tab === "active" ? (
          <BaseText margin="24px 0px 32px 0px">
            Capital Development Authority carry out auctions in accordance with
            prescribed rules and policy.
          </BaseText>
        ) : (
          <></>
        )} */}
        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          handleSearch={handleSearch}
        />
        {tableLoading ? (
          <CircularLoader />
        ) : !tableLoading && noticesData && noticesData?.length > 0 ? (
          <>
            <PaginationTable
              loading={false}
              headers={headers}
              dataSet={noticesData}
              keys={keys}
              marginTop="33px"
              downloadableContent
              whiteSpace
            />

            <Pagination
              activePage={activePage}
              pageSize={pageSize}
              pages={pages}
              handlePageSelect={(event) => handlePageSelect(event)}
              handlePageSize={(event) => handlePageSize(event)}
              marginTop="50px"
            />
          </>
        ) : (
          <Box margin="140px auto" width="max-content">
            <BaseHeading fontSize="2xl" color="secondary">
            Information not available
            </BaseHeading>
          </Box>
        )}

        <ReleateLinksAndInfo
          releatedLinks={auctionNoticesRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(PublicNotices);
