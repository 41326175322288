import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Box,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import BaseHeading from "../../Base/Heading";
import BaseText from "../../Base/Text";
import "./style.css";
import parse from "html-react-parser";
import React, { useEffect } from "react";

import {
  setisAccordianExpand
} from "../../../reduxToolkit/slices/changePage";


import {  useDispatch } from "react-redux";
import {ellipsisLong} from "../../../utils/helpers";


export default function ProceduresAccordion({ type, data }) {

  const [isClick ,setIsClick] = React.useState(true)
  
  const dispatch = useDispatch();

  const DownloadForm = ({ link }) => (
    <BaseText fontSize="md">
      Download the related form{" "}
      <Link
        href={link ? link : ""}
        textDecoration="underline"
        isExternal
        color="primary"
      >
        here
      </Link>
    </BaseText>
  );

  const getIncrementedValue = (value, from) => {
    const incrementVal = value + from;
    return JSON.stringify(incrementVal);
  };


  useEffect(()=>{
    dispatch(setisAccordianExpand(!isClick));
  },[isClick])


  return (
    <>
      {type == "For Business Community" ? (
        <Accordion allowToggle className="business">
          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 1 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure required for Change of Trade
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Application addressed to Director Municipal
                      Administration, CDA Islamabad.
                    </BaseText>
                    <BaseText fontSize="md">
                      <UnorderedList className="paddingList">
                        <ListItem>NIC Copy of allottee (s) 4 Nos.</ListItem>
                        <ListItem>Copy of allotment letter</ListItem>
                      </UnorderedList>
                    </BaseText>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 2 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Registration of Contractors/ Firms in CDA
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Contractors/ Firms are registered in CDA through
                      Directorate General Works. Following are the required
                      documents :
                    </BaseText>
                    <BaseText fontSize="md">
                      <UnorderedList className="paddingList">
                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/enlistmentOfContractors.pdf"}
                            isExternal
                          >
                            Application for Enlistement of Contractors/ Firms
                          </Link>
                        </ListItem>

                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/class_A.pdf"}
                            isExternal
                          >
                            Document for A-Class (No/Limit)/ C-1 to C-5
                          </Link>
                        </ListItem>

                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/class_B1.pdf"}
                            isExternal
                          >
                            Document for B-1 Class/ C-6
                          </Link>
                        </ListItem>

                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/class_B.pdf"}
                            isExternal
                          >
                            Document for B Class/ C-7
                          </Link>
                        </ListItem>

                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/class_C.pdf"}
                            isExternal
                          >
                            Document for C Class/ C-8
                          </Link>
                        </ListItem>

                        <ListItem>
                          <Link
                            color="primary"
                            href={"pdf/class_B1.pdf"}
                            isExternal
                          >
                            Document for D Class/ C-9
                          </Link>
                        </ListItem>
                      </UnorderedList>
                    </BaseText>
                    <BaseText fontSize="md">
                      For further inquiry Please contact at{" "}
                      <a href="tel:051-9215376">051-9215376</a>
                    </BaseText>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 3 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Contract Awarding Procedure
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px">
                    <BaseText fontSize="md" marginTop="24px">
                      Projects are as a first step, advertised in major
                      newspapers of the country with the appropriate heading,
                      depending on their nature. Tender notices and
                      pre-qualification notices are dealt with via their
                      respective directorates, indicated at the end of each
                      advertisement. The projects that the PMO is responsible
                      for have a different procedure. <br />
                    </BaseText>
                    <BaseText fontSize="md">
                      Depending on the nature, size and scope of the project, an
                      advertisement for an Expression of Interest (EOI) is
                      placed in the newspaper. Usually an EOI form prepared by
                      the CDA is uploaded on to the CDA website with prospective
                      developers required to download the form and fill it out
                      as necessary. This stage of the process is relatively
                      uncomplicated and straightforward, mainly serving as an
                      indicator to evaluators in the CDA as to the experience
                      and expertise of prospective developers.
                      <br />
                    </BaseText>
                    <BaseText fontSize="md">
                      Responses to the EOI are then evaluated, with qualified
                      parties advancing to the Request for Qualifications (RFQ)
                      stage where they are provided with the RFQ document,
                      outlining specific project-related requirements which they
                      are then required to satisfy. After prospective developers
                      submit RFQs, they are then evaluated again and the
                      evaluated proposers are provided with a Request for Proposal
                      document. This document is very precise and detailed,
                      requiring specific Technical and Financial details of the
                      project. Finally, after the RFPs have been evaluated, one
                      developer is chosen and a contract is entered into,
                      depending on the nature and scope of the venture.
                    </BaseText>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 4 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Permission to Display Signboard/Pana Flex/Neon Sign Etc
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <UnorderedList
                    fontSize="md"
                    marginTop="24px"
                    fontWeight="normal"
                    className="paddingList"
                  >
                    <ListItem>
                      {/* Application to Director M.A,CDA.
                      <br />
                      &nbsp;&nbsp;&nbsp;Download Related Application Form{" "}
                      <Link>here</Link> */}

                      <DownloadForm link="pdf/signboard_reg.pdf" />
                    </ListItem>
                    <ListItem>Affidavit</ListItem>
                    <ListItem>Copy of NIC of the applicant</ListItem>
                    <ListItem>
                      Copy of lease/rent agreement/ownership proof
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 5 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Property Tax Exemption Declaration Form
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <UnorderedList
                    fontSize="md"
                    marginTop="24px"
                    fontWeight="normal"
                    className="paddingList"
                  >
                    <ListItem>
                      {/* Exemption Declaration Form.
                      <br />
                      &nbsp; &nbsp;&nbsp;Download Related Application Form{" "}
                      <Link>here</Link> */}

                      <DownloadForm link="pdf/PropertyTax_Exemption.pdf" />
                    </ListItem>
                    <ListItem>Affidavit</ListItem>
                    <ListItem>Copy of NIC of the applicant</ListItem>
                    <ListItem>
                      Copy of lease/rent agreement/ownership proof
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 6 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Life-cycle of a Project
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Depending on the nature, size and scope of the assignment,
                      a generic life-cycle can:
                    </BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>EOI</ListItem>
                      <ListItem>Evaluation</ListItem>
                      <ListItem>Request for qualification (RFQ)</ListItem>
                      <ListItem>Evaluation</ListItem>
                      <ListItem>Request for proposal (RFP)</ListItem>
                      <ListItem>Award of Contract</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {data?.map((item, index) => (
            <AccordionItem className="AccordionItemCss marginBtm">
              {({ isExpanded }) => (
                <>
                   <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                    <AccordionButton
                      className="AccordionButton customAccordionClass"
                      _expanded={{
                        bg: "primary",
                        color: "white",
                      }}
                    >
                      <Box
                        as="span"
                        className={
                          isExpanded
                            ? "numbersCssExpandedCase"
                            : "numbersCssNotExpandedCase"
                        }
                        textAlign="left"
                      >
                        <BaseText fontWeight="bold">
                          {getIncrementedValue(index, 7)}
                        </BaseText>
                      </Box>

                      <BaseText
                        fontSize="md"
                        fontWeight="bold"
                        textAlign="left"
                        color={isExpanded && "white"}
                      >
                        {(item.question)}
                      </BaseText>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex flexDirection="column" gap="16px" marginTop="24px">
                      <BaseText fontSize="md">{parse(item.answer)}</BaseText>
                    </Flex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Accordion allowToggle className="residents">
          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold"> 1 </BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Requirements for Approval of Building Plans
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <UnorderedList
                    fontSize="md"
                    marginTop="24px"
                    fontWeight="normal"
                    className="paddingList"
                  >
                    <ListItem>
                      Photocopy of forms A1, and A2 (with one photocopy) and 6
                      copies of building plans (Architectural & Structural)
                    </ListItem>
                    <ListItem>
                      Estate management forwarding letter indicating receipt of
                      appropriate scrutiny fee @ Rs.3/- per sq. ft
                    </ListItem>
                    <ListItem>For Commercial Rs.10/- per sq. ft.</ListItem>
                    <ListItem>
                      Copy of N.I.Card of Allottee or Transferee or Attorney as
                      the case may be duly attested.
                    </ListItem>
                    <ListItem>
                      Copy of Allotment Letter or Transfer Letter duly attested.
                    </ListItem>
                    <ListItem>
                      Copy of Possession Certificate. Copy of sub-division of
                      plot, if applicable duly attested.
                    </ListItem>
                    <ListItem>
                      Indemnity Bound from the Allottee duly attested from
                      Notary Public on Rs.30/-
                    </ListItem>
                    <ListItem>
                      Soundness and stability certificate by the Engineer.
                    </ListItem>
                    <ListItem>Calculation Book.</ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">2</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Requirements for issuance of Completion Certificate and
                      process to apply
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Application can be made on Form B1, B2 duly signed by the
                      allottee/attorney.
                    </BaseText>
                    <BaseText fontSize="md">
                      Approved architect and structural engineer after
                      validation from Architecture Directorate, CDA at the Front
                      Office, One Window Operation, CDA along with the following
                      documents.
                    </BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Photocopy of Form B1, and B2 along with 6 copies of the
                        completion plan as per actual construction at the site.
                      </ListItem>
                      <ListItem>
                        Estate Management dues clearance. Copy of N.I.C of
                        Allottee or Transferee or Attorney as the case may be.
                      </ListItem>
                      <ListItem>
                        Dues clearance from Revenue Directorate.
                      </ListItem>
                      <ListItem>
                        CDA Completion Scrutiny Fee as per rates of approval of
                        building plans.
                      </ListItem>
                      <ListItem>
                        Proof of payment of Withholding Tax for non-residential
                        plots.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">3</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for availing of the facilities at Capital Hospital
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <BaseText fontSize="md" marginTop="24px">
                    Availing the facilities at Capital Hospital is easy by
                    showing a medical card/ computerized card by CDA
                    Employees and their dependents which makes them entitled to
                    all medical facilities and by getting an outdoor ticket for
                    Rs. 10/- for outdoor consultations in all departments.
                    However, patients who are not CDA Employees have to pay for
                    indoor and investigative services.
                  </BaseText>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">4</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Requirements for Change of Trade
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Application addressed to Director Municipal
                      Administration, CDA Islamabad.
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>NIC Copy of allottee (s) 4 Nos.</ListItem>
                      <ListItem>Copy of allotment letter</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">5</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Documents required for Birth Certificate
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following information is required for the issuance of a Birth
                      Certificate :
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Full Name of Father and Photo Copy of National Identity
                        Card No.
                      </ListItem>
                      <ListItem>Name of Child</ListItem>
                      <ListItem>Sex of the Child either Male or Female</ListItem>
                      <ListItem>Place of Birth, Date and Hour</ListItem>
                      <ListItem>
                        Name of Hospital/Clinic and Doctor/midwife who attended
                        the delivery (attested photo copy required)
                      </ListItem>
                      <ListItem>
                        Name and address of Mother and her National Identity
                        Card No. or Nationality, Religion, Caste and Nationality
                        of the Father
                      </ListItem>
                      <ListItem>
                        Place of Residence and address of Father
                      </ListItem>
                      <ListItem>Occupation/Profession of the Father.</ListItem>
                      <ListItem>
                        Name and Address of the Person Making Report.
                      </ListItem>
                      <ListItem>Date of Report</ListItem>
                    </UnorderedList>

                    <DownloadForm link="pdf/birth_reg.pdf" />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">6</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Documents required for Death Certificate
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following documents are required for the issuance of the Death
                      Certificate:
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Name and Surname of the Deceased and his/her
                        nationality.
                      </ListItem>
                      <ListItem>
                        Name of Husband/Father/Mother Occupation/Profession of
                        the Deceased
                      </ListItem>
                      <ListItem>Address of the Deceased</ListItem>
                      <ListItem>
                        Religion, Sex and Caste of the Deceased
                      </ListItem>
                      <ListItem>Date, time and cause of death</ListItem>
                      <ListItem>Age of Deceased</ListItem>
                      <ListItem>
                        Name and Address of the Person Making Report.
                      </ListItem>
                      <ListItem>Date of Report</ListItem>
                    </UnorderedList>

                    <DownloadForm link="pdf/death_reg.pdf" />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">7</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for transfer of Plot/Property
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following documents are required for the transfer of the
                      plot/ property.
                    </BaseText>
                    <BaseText fontSize="md">
                      Prescribed transfer application is to be filled by both
                      parties and duly attested by the 1st Class officer and in the
                      case of Oustee attestation (counter signature) of the First
                      Class Magistrate is also essential.
                    </BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Transfer fee as per prescribed rate in the shape of Pay
                        Order.
                      </ListItem>
                      <ListItem>Original allotment/offer letter.</ListItem>
                      <ListItem>
                        NIC copy of the allottee (s)/ Attorney and Transfree(s)
                        (attested) NIC copy of attesting officer.
                      </ListItem>
                      <ListItem>
                        Three specimen signatures/thumb impression of allottee
                        (s)/ Attorney. (attested) Photograph of both parties.
                      </ListItem>
                      <ListItem>
                        Indemnity bond by both parties on Rs. 30/- Judicial
                        Stamp Paper duly registered with Notary Public/Oath
                        Commissioner and countersigned by 1st Class Magistrate
                        with an additional Indemnity Bond in case of Oustee of
                        Village Sheikhpur.
                      </ListItem>
                      <ListItem>
                        NOC from Revenue, the vacant plot is exempted.
                      </ListItem>
                      <ListItem>
                        NOC from Building Control Section, CDA for
                        non-conforming use of the property.
                      </ListItem>
                      <ListItem>
                        Original attorney along with CDA acceptance letter.
                        Affidavit by an attorney regarding aliveness of allottee &
                        operative of attorney.
                      </ListItem>
                      <ListItem>
                        Original Agreement with CDA along with permission to
                        mortgage letter by CDA if executed.
                      </ListItem>
                      <ListItem> NOC from loan-giving agency.</ListItem>
                      <ListItem>
                        Special power of attorney duly attested by the embassy
                        of Pakistan/ High Commission/ Consulate in case of
                        proposed transferee is abroad along with attested copies
                        of Passport and NICs.
                      </ListItem>
                      <ListItem>
                        NIC copies of witness Affidavit by allottee/attorney for
                        duplicate NIC and difference of signature duly attested
                        by Notary Public or Oath Commissioner and countersigned
                        by 1st Class Magistrate.
                      </ListItem>
                      <ListItem>
                        In the case of a female allottee accompany accompanied by a blood relation
                        with documentary proof to identify the allottee.
                      </ListItem>
                      <ListItem>
                        In the case of plots allotted by CDA to Oustee of Islamabad
                        an affidavit prescribed by CDA duly attested by Notary
                        Public/Oath Commissioner and Counter signed by 1st Class
                        Magistrate. In the case of Government Servant NOC from his
                        department
                      </ListItem>
                      <ListItem>
                        <Link
                          color="primary"
                          href="pdf/checklistproperty.pdf"
                          isExternal
                        >
                          Check List for Transfer of Property
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">8</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Transfer of property to family members through gift
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      The allottee can apply for transfer of property at the
                      Front Office One Window Operation, CDA through
                      acknowledgement of Oral Gift on stamp paper worth Rs.5/-
                      duly attested by 1st class Magistrate along with the
                      following documents:
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Original allotment letter to be surrendered.
                      </ListItem>
                      <ListItem>
                        Property Tax Clearance Certificate from Revenue
                        Directorate .
                      </ListItem>
                      <ListItem>
                        CDA Indemnity Bonds (Transferee/Allottee).
                      </ListItem>
                      <ListItem>Photocopies of N.I.C (attested).</ListItem>
                      <ListItem>
                        Statement recorded before concerned Deputy Director and
                        duly stamped by him with his official seal. NOC from
                        Building Control Section (BCS), CDA. Bank Draft of
                        Rs.3000/- or Rs.5000/- as the case may be.
                      </ListItem>
                      <ListItem>
                        Photocopies of NICs of two witnesses (attested)
                      </ListItem>
                    </UnorderedList>

                    <DownloadForm link="pdf/EF-16.jpeg" />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">9</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Documents required for transfer of plot to the legal heirs
                      of the dead Allottee
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      An application by any of the legal heirs can be made at the
                      Front Office, One Window Operation, CDA addressed to the
                      Director Estate Management, CDA regarding the change of title
                      of the plot/house in the name of legal heirs along with
                      the following documents:
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Attested copy of Death Certificate issued by CDA or
                        local body.
                      </ListItem>
                      <ListItem>
                        Affidavit regarding the factum of death and deposing the
                        detail of the legal heirs and parents of the deceased
                        whether they are alive or died and in case died whether
                        pre-deceased or otherwise duly attested by Notary Public
                        and countersigned by Class-I Magistrate.
                      </ListItem>
                      <ListItem>
                        Attested copies of N.I.C. of all the legal heirs.
                      </ListItem>
                      <ListItem>Form "B" in the case of Minors.</ListItem>
                      <ListItem>
                        Pay Order of amounting to Rs.5000/- (Residential) or
                        10,000/- (Commercial).
                      </ListItem>
                      <ListItem>
                        Original allotment/transfer letter of the subject
                        plot/house.
                      </ListItem>
                      <ListItem>
                        Original Release Deed in case some of the legal heirs
                        want to release/surrender their share in favour of one or
                        more legal heirs, duly registered in the Court.
                      </ListItem>
                      <ListItem>Photocopy of NIC of Witness</ListItem>
                      <ListItem>
                        NOC from Revenue Directorate, CDA regarding Property
                        Tax.
                      </ListItem>
                      <ListItem>
                        NOC from Building Control Section (BCS), CDA regarding
                        confirming use of the house/property and non-violation
                        of Building & Zoning Regulation.
                      </ListItem>
                      <ListItem>
                        Copies of Public Notice (provided by CDA) appeared in
                        two daily leadings News Paper having vast circulations.
                      </ListItem>
                      <ListItem>Clearance of dues.</ListItem>
                      <ListItem>
                        Advertisements in two leading news papers.
                      </ListItem>
                      <ListItem>Indemnity Bond from legal heirs.</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">10</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for change of title through sale deed
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      An application can be made at the Front Office, One Window
                      Operation, CDA addressed to the Director Estate Management,
                      CDA regarding the change of title of the property through the sale
                      deed along with the following documents.
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Original Sale Deed along with one attested photocopy
                      </ListItem>
                      <ListItem>Original allotment/transfer letter</ListItem>
                      <ListItem>Attested copy of NIC</ListItem>
                      <ListItem>
                        Two (2) attested photographs & 3 (three) specimen
                        signatures of the purchaser.
                      </ListItem>
                      <ListItem>
                        Pay Order/Demand Draft for Rs. 7,500/- for residential
                        and Rs.10,000/- for Commercial in favor of CDA.
                      </ListItem>
                      <ListItem>
                        NOC from B.C.S regarding non-conforming use and
                        non-violation of Building and Zoning Regulations.
                      </ListItem>
                      <ListItem>
                        NOC/Property Tax Clearance Certificate from Revenue
                        Directorate, CDA.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">11</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Issuance of Certified True Copy (C.T.C) of Allotment
                      Letter, if lost
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      An application can be made by the allottee at the Front
                      Office, One Window Operation in the name of Director
                      Estate Management, CDA for issuance of C.T.C along with the
                      following documents:
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>F.I.R Police Station.</ListItem>
                      <ListItem>
                        Indemnity Bond only attested by 1st Class Magistrate for
                        Rs. 30/-
                      </ListItem>
                      <ListItem>Photocopy of N.I.C duly attested.</ListItem>
                      <ListItem>
                        Affidavit duly attested by 1st Class Magistrate.
                      </ListItem>
                      <ListItem>
                        Pay Order of Rs. 1,000/- as a process fee.
                      </ListItem>
                      <ListItem>Attested Photograph of Allottee</ListItem>
                      <ListItem>
                        The Allottee record statement in the presence of the
                        concerned Deputy Director
                      </ListItem>
                    </UnorderedList>
                    <DownloadForm link="pdf/EF-21.jpeg" />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">12</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Mortgage property in favor of House Building Finance
                      Corporation or any bank
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following documents are required for granting permission
                      for the mortgage of a property:
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        In case of plot: Execute an agreement with CDA.
                        <UnorderedList listStyleType="circle">
                          <ListItem>
                            Copy of Site Plan (provided by CDA). Application for
                            permission to assign addressing Director Estate
                            Management, CDA.
                          </ListItem>
                        </UnorderedList>
                      </ListItem>
                      <ListItem>
                        In the case of houses and other built-up properties:
                        <UnorderedList listStyleType="circle">
                          <ListItem>
                            Execute Conveyance Deed or Lease Dead with CDA.
                          </ListItem>
                          <ListItem>
                            The cost of Stamp Papers will be at the rate of
                            Rs.40/- per thousand at the original price of the
                            plot and in the case of Commercial, and Industrial Plots in
                            addition to the cost of the plot plus annual ground rent
                            paid and ten-year advance.
                          </ListItem>
                          <ListItem>
                            Note: There are two days for the execution of
                            Agreement and Conveyance Deed
                          </ListItem>
                        </UnorderedList>
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">13</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Documents required for Possession of Plot
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem> Application by allottee/ attorney.</ListItem>
                      <ListItem>
                        Copy of allotment letter duly attested
                      </ListItem>
                      <ListItem>
                        Copy of NIC of allottee/ attorney duly attested
                      </ListItem>
                      <ListItem>
                        Pay Order for Rs.1,000/- for the first and Rs.2,000/- for the
                        second
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">14</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Documents Required for Court Decision{" "}
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem> Court decision certified copy</ListItem>
                      <ListItem>NIC copy of the applicant </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">15</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for Sub-Division of Plot
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Attested copy of allotment letter (original shall be
                        surrendered after approval of sub-division)
                      </ListItem>
                      <ListItem>
                        Four copies of the sub-division Plan/sketch duly signed by
                        all the allottees and the Architect
                      </ListItem>

                      <ListItem>NOC from Revenue Directorate, CDA</ListItem>
                      <ListItem>
                        Pay Order in favour of CDA @ Rs.75/- per sq. yd
                      </ListItem>
                    </UnorderedList>
                    <DownloadForm link="pdf/EF-20.jpeg" />
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">16</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for change of name (Married ladies and others)
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      If an allottee wants to change his/her name, the allottee will
                      have to apply at the O.W.O Directorate with the following
                      documents
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Attested copy of allotment/transfer letter.
                      </ListItem>
                      <ListItem>
                        Attested copy of present and previous NIC.
                      </ListItem>
                      <ListItem>
                        Attested copy of ‘Nikah Nama’ (in case of married lady)
                      </ListItem>
                      <ListItem>An affidavit as per specimen</ListItem>
                      <ListItem>
                        Press Clipping of Notice published in the newspaper
                      </ListItem>
                      <ListItem>
                        Attested copy of matriculation certificate
                      </ListItem>
                      <ListItem>
                        Attested copy of passport if available.
                      </ListItem>
                      <ListItem>
                        Any other documents supporting for change of name
                      </ListItem>
                    </UnorderedList>
                    <DownloadForm link="pdf/EF-32.jpeg" />

                    <BaseText fontSize="md">
                      The OWO Directorate shall forward the application to the
                      concerned Deputy Director of EM-I or EM-II Directorate,
                      CDA Deputy Director shall forward the case to the Law
                      Directorate for Legal Opinion/advice. the Law Directorate may
                      further refer the case to the Security/ Inquiry Directorate CDA
                      for investigation/verification if there is any doubt or
                      suspicion. After verification and legal opinion, the
                      change of name will be notified with the approval of the
                      Director Estate Management-I.
                    </BaseText>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">17</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure to Check Outstanding Dues
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      An allottee or his authorized person can apply at the One
                      Window Operation counter or through mail to find the
                      outstanding dues against his property.
                    </BaseText>
                    <BaseText fontSize="md">
                      Estate Management Directorate-I or II shall provide the
                      relevant information within 4 working days.
                    </BaseText>
                    <BaseText fontSize="md">
                      <BaseText fontSize="md" fontWeight="600" display="inline">
                        Mailing address:
                      </BaseText>{" "}
                      One Window Operations, Main CDA Secretariat, Chairman
                      Office G7/4, Islamabad.
                    </BaseText>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">18</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for same-day issuance of Transfer Letter
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        The parties can apply for urgent transfer subject to
                        payment of Rs.10000/- as a fee in addition to the other
                        charges.
                      </ListItem>
                      <ListItem>
                        In such cases, the allottee/attorney shall present the
                        Transfer Application Form alongwith the required
                        documents to the Assistant Director (Admitting) before 10
                        A.M.
                      </ListItem>
                      <ListItem>
                        If the documents are in order, the admitting of the transfer
                        case shall be done on the same day before 1200 hours.
                      </ListItem>
                      <ListItem>
                        A.D (Admitting) shall send the file through special
                        messenger to the concerned section of Estate
                        Management-I or II Directorate.
                      </ListItem>
                      <ListItem>
                        The section shall prepare the transfer proposal and transfer
                        letter immediately and submit to the Deputy Director who
                        shall be competent to approve the transfer proposal.
                      </ListItem>
                      <ListItem>
                        The transfer letter shall be delivered by the R&I Branch of
                        Estate Management-I or II Directorate at OWO Counter
                        from 2:00 p.m. to 3:00 p.m.
                      </ListItem>
                      <ListItem>
                        If the parties don't receive the letters, these should
                        be dispatched after one day.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">19</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Rate of Transfer Fees for Commercial & Residential Plots
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>Residential : 250/- Per Sq. Yd</ListItem>
                      <ListItem>Model Villages: 150/- Per Sq. Yd</ListItem>
                      <ListItem>Family Transfer Residential: 5,000/-</ListItem>
                      <ListItem>Family Transfer Commercial: 10,000/-</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">20</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for Lien Marking
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem> Application</ListItem>
                      <ListItem>Copy of NIC attested</ListItem>
                      <ListItem>Copy of Allotment Letter Attested</ListItem>
                      <ListItem>Family Transfer Commercial: 10,000/-</ListItem>
                      <ListItem>Pay Order of Rs.500/-</ListItem>
                      <ListItem>Attested Photocopy of Mortgage Deed</ListItem>
                      <ListItem>
                        Photocopy of NOC issued by CDA for Loan
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">21</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for Property Information Report (PIR)
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem> Application</ListItem>
                      <ListItem>Copy of NIC attested</ListItem>
                      <ListItem>Copy of Allotment Letter Attested</ListItem>
                      <ListItem>Pay Order Rs. 500/-</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">22</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for Water Connection and Rates for Water Billing
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" gap="16px" marginTop="24px">
                    <BaseHeading fontSize="md">
                      Documents required for Water Connection:
                    </BaseHeading>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>Form A-3</ListItem>
                      <ListItem>Pay Order as per size of Plot </ListItem>
                      <ListItem>
                        Plan Approval Letter & Allotment Letter (copy attested){" "}
                      </ListItem>
                      <ListItem>Photocopy of NIC duly attested.</ListItem>
                    </UnorderedList>

                    <BaseHeading fontSize="md">
                      Water Billing Rates:
                    </BaseHeading>

                    <BaseText fontSize="md">For Commercial Property:</BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        @ Rs.3/- per sq.ft of total covered area of the building
                      </ListItem>
                    </UnorderedList>

                    <BaseText fontSize="md">For Residential Property:</BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem> Size 249 Rs.3164/-</ListItem>
                      <ListItem>Size from 250 to 499 Rs.6328/-</ListItem>
                      <ListItem>Size from 500 to 999 Rs.12654/-</ListItem>
                      <ListItem>Size from 1000 to 1199 Rs.15878/-</ListItem>
                      <ListItem>Size from 1200 to 1499 Rs.18982/-</ListItem>
                      <ListItem>Size from 1500 to 1999 Rs.22146/-</ListItem>
                      <ListItem>2000 to above Rs.31636/-</ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">23</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Registration of Municipal Complaints
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Following nature of complaints comes under the scope of
                      Municipal Complaints addressed to Director MA, CDA,
                      Director Sanitation, Dairector Water Supply etc.
                    </BaseText>
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>Sewerage</ListItem>
                      <ListItem>Sanitation</ListItem>
                      <ListItem>Environment</ListItem>
                      <ListItem>Road Maintenance</ListItem>
                      <ListItem>Water Supply/ Tanker</ListItem>
                      <ListItem>Street Lights</ListItem>
                    </UnorderedList>

                    <Flex direction="column" gap="6px">
                      <BaseHeading fontSize="md">
                        Sanitation Directorate Contact Details:
                      </BaseHeading>
                      <BaseText fontSize="md">
                        G-6-1/4, Near New Aabpara Market, Islamabad :
                        Phone:(051)9203216
                      </BaseText>

                      <BaseHeading fontSize="md">
                        Municipal Administration Directorate Contact Details:
                      </BaseHeading>
                      <BaseText fontSize="md">
                        DMA Office, Fire Head Quarters, G7/4, Islamabad :
                        Phone:(051)9252838
                      </BaseText>

                      <BaseHeading fontSize="md">
                        E&DM Directorate Contact Details:
                      </BaseHeading>
                      <BaseText fontSize="md">
                        Fire Head Quarters Building, G7/4, Islamabad :
                        Phone:(051)9252842 & 16
                      </BaseText>
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">24</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Water Connection formalities of Water Meter Division
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      Scrutinize the status of applications, regarding
                      completion of formal procedure and issue of demand notices
                      accordingly.
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Receipt of water connection fee from the consumers as
                        per demand notice.
                      </ListItem>
                      <ListItem>
                        Execution of formal water supply agreement in between
                        the property owner and the Authority.
                        <br />
                        Documents required for water supply agreement.
                        <UnorderedList listStyleType="circle">
                          <ListItem>Water supply agreement form.</ListItem>
                          <ListItem>
                            Form A-3 along with its enclosures.
                          </ListItem>
                          <ListItem>Copy of NIC of the applicant</ListItem>
                          <ListItem>Copy of allotment letter.</ListItem>
                          <ListItem>Copy of possession letter.</ListItem>
                          <ListItem>Copy of approval plan.</ListItem>
                          <ListItem>
                            Copy of sewerage fee, road cut fee, water connection
                            fee etc.
                          </ListItem>
                        </UnorderedList>
                      </ListItem>
                      <ListItem>
                        Issue of material for water connection like clamp, bend
                        tee, pipe, gate valve, etc. and its endorsement on stock
                        register.
                      </ListItem>
                      <ListItem>
                        Endorsement in ledger register to award ledger number.
                      </ListItem>
                      <ListItem>
                        Physical provision of water connection on ground.
                      </ListItem>
                      <ListItem>
                        Forwarding the water supply agreement documents to
                        revenue documents CDA for water billing etc.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">25</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      What are the requirements for approval of building
                      plans(After Completion)?{" "}
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="column" gap="16px" marginTop="24px">
                    <BaseText fontSize="md">
                      The application can be made on Form B1, B2 duly signed by the
                      allottee/attorney, approved architect and structural
                      engineer after validation from the Architecture Directorate,
                      CDA at the Front Office, One Window Operation, CDA along
                      with the following documents.
                    </BaseText>

                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        Photocopy of Form B1 and B2 along with 4 copies of 
                        completion plan as per actual construction at site.
                      </ListItem>

                      <ListItem>Estate Management dues clearance.</ListItem>
                      <ListItem>
                        Copy of N.I.C of Allottee or Transferee or Attorney as
                        the case may be.
                      </ListItem>
                      <ListItem>
                        Dues clearance from Revenue Directorate, CDA.
                      </ListItem>
                      <ListItem>
                        Completion Scrutiny Fee as per rates of approval of
                        building plans.
                      </ListItem>
                      <ListItem>
                        Proof of payment of Withholding Tax for non residential
                        plots.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">26</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for delivery of Documents?
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        The documents entrusting title (Allotment Letter,
                        Transfer Letter, Sub-Division Letter, CTC) are
                        dispatched to the Allottee/Transferee through registered
                        post or delivered personally at Estate Management
                        Directorates after identification. The allottee may
                        authorize his agent to collect such documents through an
                        application attaching copies of NIC.
                      </ListItem>
                      <ListItem>
                        All other correspondence with the Applicants/Allottees
                        is done through mail
                      </ListItem>
                      <ListItem>
                        The applicant may request for dispatch of documents
                        through courier (subject to payment of courier charges).
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">27</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      What is the procedure of admitting in One Window Operation
                      Directorate?{" "}
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        The allotee/ attorney shall get due date from the
                        admitting officer in OWO Directorate on presenting
                        completed TAF (Transfer Application Form) along with
                        required documents.
                      </ListItem>
                      <ListItem>
                        After giving “due date “ the Admitting Officer shall
                        send a data form to the concerned Dy. Director in EM-1
                        or EM-2 at least two days before the due date. The
                        concerned DD shall check the file and ensure that the
                        relevant file and record is send to admitting officer 9
                        am on the due date.
                      </ListItem>
                      <ListItem>
                        The allotee/ attorney and the transferee have to appear
                        in person on due date with their original NIC in OWO
                        Directorate before Asstt. Director (Admitting) along
                        with TAF and the requisite documents.
                      </ListItem>
                      <ListItem>
                        The parties are photographed with their signatures and
                        thumb impressions taken in the presence of Admitting
                        Officer after they confirm before him that the
                        transaction has taken place as per satisfaction of both
                        the parties.
                      </ListItem>
                      <ListItem>
                        After completing the formalities of admitting, the
                        Admitting Officer shall send the file and TAF along will
                        the documents to the accounts section for removal of
                        bank Draft on the same day.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem className="AccordionItemCss marginBtm">
            {({ isExpanded }) => (
              <>
                 <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                  <AccordionButton
                    className="AccordionButton"
                    _expanded={{
                      bg: "primary",
                      color: "white",
                    }}
                  >
                    <Box
                      as="span"
                      className={
                        isExpanded
                          ? "numbersCssExpandedCase"
                          : "numbersCssNotExpandedCase"
                      }
                      textAlign="left"
                    >
                      <BaseText fontWeight="bold">28</BaseText>
                    </Box>
                    <BaseText
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="left"
                      color={isExpanded && "white"}
                    >
                      Procedure for issuance of Separate Transfer Letters to
                      more than one transferee
                    </BaseText>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex marginTop="24px">
                    <UnorderedList
                      fontSize="md"
                      fontWeight="normal"
                      className="paddingList"
                    >
                      <ListItem>
                        If there are more than one transferee, they can apply
                        for their separate copies of transfer letter.
                      </ListItem>
                      <ListItem>
                        If one of the co-allottees wants to transfers his share,
                        all the other co-allottees shall also have to surrender
                        their original transfer letters so that revised letters
                        are issued to them.
                      </ListItem>
                    </UnorderedList>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {data?.map((item, index) => (
            <AccordionItem className="AccordionItemCss marginBtm">
              {({ isExpanded }) => (
                <>
                   <h2  className= "faqOpen"  onClick={()=> setIsClick(!isClick)}>
                    <AccordionButton
                      className="AccordionButton"
                      _expanded={{
                        bg: "primary",
                        color: "white",
                      }}
                    >
                      <Box
                        as="span"
                        className={
                          isExpanded
                            ? "numbersCssExpandedCase"
                            : "numbersCssNotExpandedCase"
                        }
                        textAlign="left"
                      >
                        <BaseText fontWeight="bold">
                          {getIncrementedValue(index, 29)}
                        </BaseText>
                      </Box>

                      <BaseText
                        fontSize="md"
                        fontWeight="bold"
                        textAlign="left"
                        color={isExpanded && "white"}
                      >
                        {item.question}
                      </BaseText>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex flexDirection="column" gap="16px" marginTop="24px">
                      <BaseText fontSize="md">{parse(item.answer)}</BaseText>
                    </Flex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
}

{
  /* <ListItem fontSize="xl" fontWeight="bold">
                      Registration of Contractors/ Firms in CDA.
                      <Flex flexDirection="column" gap="16px" marginTop="24px">
                        <BaseText fontSize="md">
                          Contractors/ Firms are registered in CDA through
                          Directorate General Works. Following are the required
                          documents :
                        </BaseText>
                        <BaseText fontSize="md">
                          <UnorderedList>
                            <ListItem>
                              Application for Enlistement of Contractors/ Firms
                            </ListItem>
                            <ListItem>
                              Document for A-Class (No/Limit)/ C-1 to C-5
                            </ListItem>
                            <ListItem>Document for B-1 Class/ C-6</ListItem>
                            <ListItem>Document for B Class/ C-7</ListItem>
                            <ListItem>Document for B Class/ C-8</ListItem>
                            <ListItem>Document for B Class/ C-9 </ListItem>
                          </UnorderedList>
                        </BaseText>
                      </Flex>
                    </ListItem>
                    <ListItem fontSize="xl" fontWeight="bold">
                      Contract Awarding Procedure.
                      <Flex flexDirection="column" gap="16px">
                        <BaseText fontSize="md" marginTop="24px">
                          Projects are as a first step, advertised in major
                          newspapers of the country with the appropriate
                          heading, depending on their nature. Tender notices and
                          Pre-Qualification Notices are dealt with via their
                          respective directorates, indicated at the end of each
                          advertisement. The projects that the PMO is
                          responsible for have a different procedure. <br />
                        </BaseText>
                        <BaseText fontSize="md">
                          Depending on the nature, size and scope of the
                          project, an advertisement for an Expression of
                          Interest (EOI) is placed in the newspaper. Usually an
                          EOI form prepared by the CDA is uploaded on to the CDA
                          website with prospective developers required to
                          download the form and fill it out as necessary. This
                          stage of the process is relatively uncomplicated and
                          straightforward, mainly serving as an indicator to
                          evaluators in the CDA as to the experience and
                          expertise of prospective developers.
                          <br />
                        </BaseText>
                        <BaseText fontSize="md">
                          Responses to the EOI are then evaluated, with
                          qualified parties advancing to the Request for
                          Qualifications (RFQ) stage where they are provided
                          with the RFQ document, outlining specific
                          project-related requirements which they are then
                          required to satisfy. After prospective developers
                          submit RFQs, they are then evaluated again and the
                          evaluated proposers provided with a Request for
                          Proposal document. This document is very precise and
                          detailed, requiring specific Technical and Financial
                          details of the project. Finally, after the RFPs have
                          been evaluated, one developer is chosen and a contract
                          is entered into, depending on the nature and scope of
                          the venture.
                        </BaseText>
                      </Flex>
                    </ListItem>
                    <ListItem fontSize="xl" fontWeight="bold">
                      Permission to Display Signboard/Pana Flex/Neon Sign Etc.
                      <UnorderedList
                        fontSize="md"
                        marginTop="24px"
                        fontWeight="normal"
                      >
                        <ListItem>
                          Application to Director M.A,CDA.
                          <br />
                          Download Related Application Form <Link>here</Link>
                        </ListItem>
                        <ListItem>Affidavit</ListItem>
                        <ListItem>Copy of NIC of the applicant</ListItem>
                        <ListItem>
                          Copy of lease/rent agreement/ownership proof
                        </ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem fontSize="xl" fontWeight="bold">
                      Property Tax Exemption Declaration Form.
                      <UnorderedList
                        fontSize="md"
                        marginTop="24px"
                        fontWeight="normal"
                      >
                        <ListItem>
                          Exemption Declaration Form.
                          <br />
                          Download Related Application Form <Link>here</Link>
                        </ListItem>
                        <ListItem>Affidavit</ListItem>
                        <ListItem>Copy of NIC of the applicant</ListItem>
                        <ListItem>
                          Copy of lease/rent agreement/ownership proof
                        </ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem fontSize="xl" fontWeight="bold">
                      Life-cycle of a Project
                      <Flex flexDirection="column" gap="16px" marginTop="24px">
                        <BaseText fontSize="md">
                          Depending on the nature, size and scope of the
                          assignment, a generic life-cycle can:
                        </BaseText>
                        <UnorderedList fontSize="md" fontWeight="normal">
                          <ListItem>EOI</ListItem>
                          <ListItem>Evaluation</ListItem>
                          <ListItem>Request for qualification (RFQ)</ListItem>
                          <ListItem>Evaluation</ListItem>
                          <ListItem>Request for proposal (RFP)</ListItem>
                          <ListItem>Award of Contract</ListItem>
                        </UnorderedList>
                      </Flex>
                    </ListItem> */
}
