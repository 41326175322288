import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      50: "#E8F4EA",
      100: "#C7E4CA",
      200: "#A4D2A9",
      300: "#80C188",
      400: "#66B570",
      500: "#4EA858",
      600: "#739B21",
      700: "#3C8743",
      800: "#347738",
      900: "#255823",
    },
    primary: "#739B21",
    primary_bg: "rgba(115, 155, 33, 0.15)",
    // primary: "#4EA858",
    secondary: "#37474F",
    success: "#249951",
    warning: "#E2B93B",
    error: "#C94444",
    grey1: "#737678",
    grey2: "#BBBBBB",
    grey3: "#F2F2F2",
    white: "#FFFFFF",
    black: "#000000",
    tableHeader: "#91C74C",
  },
  fonts: {
    body: "Poppins",
    heading: "Poppins",
    mono: "Poppins",
  },
  // fontSizes: {
  //   xs: "0.64rem", //caption 8.96px
  //   sm: "0.8rem", //Body Small 11.2px
  //   md: "1rem", //Body Normal 14px
  //   lg: "1.25rem", //H6 17.50px
  //   xl: "1.563rem", //H5 21.88px
  //   "2xl": "1.953rem", //H4 27.34px
  //   "3xl": "2.441rem", //H3 34.18px
  //   "4xl": "3.052rem", //H2 42.72px
  //   "5xl": "3.815rem", //H1 53.41px
  // },
  fontSizes: {
    xs: "8.96px",
    sm: "11.2px",
    md: "14px",
    lg: "17.50px",
    xl: "21.88px",
    "2xl": "27.34px",
    "3xl": "34.18px",
    "4xl": "42.72px",
    "5xl": "53.41px",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
});
