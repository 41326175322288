import BaseText from "../../Base/Text";
import BaseHeading from "../../Base/Heading";
import { Flex } from "@chakra-ui/react";
const Card = ({ title, location, time, day }) => {
  return (
    <Flex
      flexDirection="column"
      gap="24px"
      padding="35px 86px 36px 86px"
      borderRadius="8px"
      border="1px solid var(--chakra-colors-grey2)"
    >
      <BaseHeading fontSize="xl">{title}</BaseHeading>
      <Flex direction="column" gap="8px">
        <BaseText fontSize="md" textAlign="center">
          {location}
        </BaseText>
        <BaseText fontSize="md" textAlign="center">
          {time}
        </BaseText>
        <BaseText fontSize="md" textAlign="center">
          {day}
        </BaseText>
      </Flex>
    </Flex>
  );
};

export default Card;
