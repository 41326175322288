import { withTranslation } from "react-i18next";
import PageHeader from "../../../../components/Common/PageHeader";
import BreadCrumb from "../../../../components/Common/Breadcrumb";
import PageWraper from "../../../../components/Base/PageWraper";
import { useEffect, useState } from "react";
import { getPublicNotices } from "../../../../utils/API";
import PaginationTable from "../../../../components/Common/Table/PaginationTable";

import Searchbar from "../../../../components/Common/Searchbar";
import BaseText from "../../../../components/Base/Text";
import TabHeader from "../../../../components/Common/TabHeader";
import ReleateLinksAndInfo from "../../../../components/Common/ReleateLinksAndInfo";
import {
  auctionNoticesRelatedLinks,
  CdaOrdinanceRelatedInfo,
} from "../../../../utils/constants";
import CircularLoader from "../../../../components/Common/CircularLoader";

function OpenAuctionOfPlots() {
  const [tab, setTab] = useState("active");
  const [searchQuery, setSearchQuery] = useState("");
  const [openAuctionData, setOpenAuctionData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "Public Notices",
      link: "#",
    },
    {
      name: "Open Auction of Plots",
      link: "/open-auction-of-plots",
    },
  ];
  const headers = {
    summery: "Description",
    pdf: "View Ad",
  };
  const keys = {
    summery: "summery",
    pdf: "pdf",
  };

  const tabsData = [
    {
      title: "Active Auctions",
      content:
        "Capital Development Authority carry out auctions in accordance with prescribed rules and policy.",
      type: "active",
    },
    {
      title: "Archive Auctions",
      content: "",
      type: "archive",
    },
  ];

  const fetchData = async () => {
    setTableLoading(true);
    const data = await getPublicNotices("open", tab);
    return data;
  };

  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
      fetchData()
        .then((response) => setOpenAuctionData(response?.data))
        .finally(() => setTableLoading(false));
    }
  }, [tab, searchQuery]);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    const filteredData = openAuctionData?.filter((item) =>
      item?.summery?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    setOpenAuctionData(filteredData);
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Open Auction of Plots" />
      <PageWraper>
        <TabHeader tabsData={tabsData} onTabChange={setTab} />
        {tab === "active" ? (
          <BaseText margin="24px 0px 32px 0px">
            Capital Development Authority carry out auctions in accordance with
            prescribed rules and policy.
          </BaseText>
        ) : (
          <></>
        )}
        <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          handleSearch={handleSearch}
        />

        {tableLoading ? (
          <CircularLoader />
        ) : (
          <PaginationTable
            loading={false}
            headers={headers}
            dataSet={openAuctionData}
            keys={keys}
            marginTop="33px"
            downloadableContent
          />
        )}

        <ReleateLinksAndInfo
          releatedLinks={auctionNoticesRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(OpenAuctionOfPlots);
