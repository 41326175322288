// /src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import gallerySlice from "./reduxToolkit/slices/galleryImages"; // Adjusted the import statement
import tabsSlice from "./reduxToolkit/slices/tabs"; // Adjusted the import statement
import changePageSlice from "./reduxToolkit/slices/changePage"; // Adjusted the import statement
import AccessibiltyPanelSlice from './reduxToolkit/slices/Accessibiltypanel'
import globalSlice from "./reduxToolkit/slices/globalSearch";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root", // Changed the key to a common key for all slices
  storage,
};

const persistedGalleryReducer = persistReducer(persistConfig, gallerySlice); // Adjusted the reducer name
const persistedGlobalSearchReducer = persistReducer(persistConfig, globalSlice); // Adjusted the reducer name
const AccessibiltyPanelSliceReducer = persistReducer(persistConfig, AccessibiltyPanelSlice); // Adjusted the reducer name

const store = configureStore({
  reducer: {
    gallery: persistedGalleryReducer, // Adjusted the reducer name
    // Add other slices as needed
    globalSearch: persistedGlobalSearchReducer,
    tab: tabsSlice,
    changePage:changePageSlice,
    AccessibiltyPanel:AccessibiltyPanelSliceReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
