import { withTranslation } from "react-i18next";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import {
  auctionNoticesRelatedLinks,
  CdaOrdinanceRelatedInfo,
} from "../../../utils/constants";
import { useEffect, useState } from "react";

import TabHeader from "../../../components/Common/TabHeader";
import ReleateLinksAndInfo from "../../../components/Common/ReleateLinksAndInfo";
import PageWraper from "../../../components/Base/PageWraper";
import PaginationTable from "../../../components/Common/Table/PaginationTable";
import { getNewsReleases } from "../../../utils/API";
import CircularLoader from "../../../components/Common/CircularLoader";
import { convertToPKT, htmlParser } from "../../../utils/helpers";
import Pagination from "../../../components/Common/Pagination";

import Searchbar from "../../../components/Common/Searchbar";
import BaseHeading from "../../../components/Base/Heading";
import { Box } from "@chakra-ui/react";

function NewsReleases() {
  const [tab, setTab] = useState("latest");
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "Media Center",
      link: "#",
    },
    {
      name: "News Releases",
      link: "/news-releases",
    },
  ];

  const handlePageSelect = (page) => {
    setActivePage(page.selected + 1);
  };

  const handlePageSize = (event) => {
    // setPageSize(event.target.value);

    setPageSize(event);
    setActivePage(1);
  };

  const fetchData = async () => {
    setTableLoading(true);
    const res = await getNewsReleases(tab, activePage, pageSize);
    return res?.data;
  };

  const convertDate = (data) => {
    data &&
      data.length > 0 &&
      data?.map((el) => {
        if (tab == "archive") {
          el.created_at = el?.expired_at ? convertToPKT(el.expired_at) : "";
        } else {
          el.created_at = el?.created_at ? convertToPKT(el.created_at) : "";
        }
      });

    return data;
  };
  const handleTabChange = (value) => {
    setTab(value);
    setPageSize(10);
    setActivePage(1);
    setSearchQuery("");
  };

  useEffect(() => {
    if (searchQuery.trim() === "" && tab) {
    fetchData()
      .then((response) => {
        setData(convertDate(response?.data));
        setPages(Math.ceil(response?.total / pageSize));
      })
      .finally(() => setTableLoading(false));
    }
  }, [tab, activePage, pageSize,searchQuery]);



  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return;
    }
    
    const formattedSearchQuery = searchQuery.trim(); // Trim search query
    const filteredData = data?.filter((item) => {
      // const headlineMatch = item?.headline?.toLowerCase()?.includes(formattedSearchQuery?.toLowerCase());
      const headlineMatch = item?.headline?.toLowerCase()?.includes(searchQuery?.toLowerCase());
      let createdAtMatch = ( htmlParser(item?.created_at)?.toLowerCase()?.includes(searchQuery?.toLowerCase()) || htmlParser(item?.created_at?.replace(/<sup>.*?<\/sup>/g, '').trim())?.toLowerCase()?.includes(searchQuery?.toLowerCase()) );
      
      const numericalCreatedAt = item?.created_at?.match(/\d+/)?.[0]; // Extract numerical part from created_at
        if(createdAtMatch && searchQuery?.toLowerCase() === "20" ){
          createdAtMatch = numericalCreatedAt && numericalCreatedAt.includes(formattedSearchQuery)
        }

      return headlineMatch || createdAtMatch;
    });

    setPages(Math.ceil(filteredData?.length/pageSize))
    setData(filteredData);
  }
  

  const tabsData = [
    {
      title: "Latest News Releases",
      content: "",
      type: "latest",
    },
    {
      title: "News Archive",
      content: "",
      type: "archive",
    },
  ];

  const keys = {
    created_at: "created_at",
    headline: "headline",
  };

  const headers = {
    created_at: "Date",
    headline: "Headline",
  };

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="News Releases" />
      <PageWraper>
        <TabHeader
          tabsData={tabsData}
          onTabChange={(e) => handleTabChange(e)}
        />

          <Searchbar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
           handleSearch={handleSearch}
        />
        {tableLoading ? (
          <CircularLoader />
        ) : (
            data?.length > 0  ? (
            <>
              <PaginationTable
                loading={false}
                headers={headers}
                marginTop="33px"
                dataSet={data}
                keys={keys}
                newz
                whiteSpace
              />
              <Pagination
                activePage={activePage}
                pageSize={pageSize}
                pages={pages}
                handlePageSelect={(event) => handlePageSelect(event)}
                handlePageSize={(event) => handlePageSize(event)}
                marginTop="50px"
              />
            </>
          ) : <>
              <Box margin="140px auto" width="max-content">
                <BaseHeading fontSize="2xl" color="secondary">
                  Information not available
                </BaseHeading>
              </Box>

            </>
        )}
        <ReleateLinksAndInfo
          releatedLinks={auctionNoticesRelatedLinks}
          releatedInfo={CdaOrdinanceRelatedInfo}
        />
      </PageWraper>
    </>
  );
}

export default withTranslation()(NewsReleases);
