import { Box, Image } from "@chakra-ui/react";

export default function BaseImage({
  source,
  alt,
  objectFit = "fill",
  imgStyle,
  borderRadius,
  fallbackSrc,
  ...otherprops
}) {
  return (
    <Box {...otherprops}>
      <Image
        width="100%"
        height="100%"
        borderRadius={borderRadius}
        src={source}
        objectFit={objectFit}
        alt={alt}
        fallbackSrc={fallbackSrc}
      />
    </Box>
  );
}
