import React,{useEffect} from "react";
import ReactPaginate from "react-paginate";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import "./index.css";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import BaseText from "../../Base/Text";

import { setPageChange } from "../../../reduxToolkit/slices/changePage";
import { useDispatch } from "react-redux";



function Pagination({
  pageSize,
  activePage,
  pages,
  handlePageSelect,
  handlePageSize,
  ...restProps
}) 

{


  useEffect(()=>{
    dispatch(setPageChange(activePage))
  },[activePage])

  const dispatch = useDispatch();
  return (
    <Box className="containerPagination" {...restProps}>
      <Flex alignItems="center" fontWeight="bold" fontSize="md">
        <BaseText flexShrink="0" className="showingText" fontWeight="bold">
          Showing {activePage?.toString()}
          {/* {"-"}
          {pages < pageSize
            ? pages.toString()
            : (activePage * pageSize).toString()} */}{" "}
          from{" "}
          <BaseText fontWeight="bold" as="span">
            {pages?.toString()}
          </BaseText>
        </BaseText>
      </Flex>

      <ReactPaginate
        previousLabel={
          <Flex gap="8px"  
          onClick={()=>dispatch(setPageChange(activePage-1))}
          >
            <ArrowBackIcon h="18px" w="20px" />
            Previous
          </Flex>
        }
        nextLabel={
          <Flex gap="8px" 
         onClick={()=>dispatch(setPageChange(activePage+1))}
          >
            Next
            <ArrowForwardIcon h="18px" w="20px" />
          </Flex>
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageSelect}
        containerClassName="pagination"
        activeClassName="active"
        disabledClassName="disabled"
        forcePage={activePage - 1}
      />

      <Flex alignItems="center" fontWeight="bold">
        {/* <Select
          w={"fit-content"}
          value={pageSize}
          onChange={(e) => {
            handlePageSize(e);
          }}
          marginLeft="10px"
        >
          {[10, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select> */}

        <Menu>
          <MenuButton
            as={Button}
            background="transparent"
            border="1px solid var(--chakra-colors-grey2)"
            w={"fit-content"}
            rightIcon={<ChevronDownIcon />}
          >
            {pageSize}
          </MenuButton>
          <MenuList minWidth="67px">
            {[10, 50, 100].map((pageSize) => (
              <MenuItem key={pageSize} onClick={() => {handlePageSize(pageSize)}}>
                {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}

export default Pagination;
