import { withTranslation } from "react-i18next";
import Paragraph from "../../../components/Common/Paragraph";
import PageHeader from "../../../components/Common/PageHeader";
import BreadCrumb from "../../../components/Common/Breadcrumb";
import CardSection from "../../../components/CardSection";
import PageWraper from "../../../components/Base/PageWraper";
import { Flex } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { getMemberData } from "../../../utils/API";
import { Box } from "@chakra-ui/react";
import BaseHeading from "../../../components/Base/Heading";
import BaseText from "../../../components/Base/Text";
import "./style.css";
import CircularLoader from "../../../components/Common/CircularLoader";

function BoardMembers() {
  const [chairmanData, setChairmanData] = useState([]);
  const [executiveData, setExecutiveData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [loading, setLoading] = useState(false);
  const breadcrumbs = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      link: "#",
    },
    {
      name: "Board Members",
      link: "/board-members",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const data = await getMemberData();
    const chairman = data?.filter((item) => item.order_number === "1");
    setChairmanData(chairman);
    const exective = data?.filter((item) => item.order_number === "2");
    setExecutiveData(exective);
    const member = data?.filter((item) => item.order_number === "3");
    setMemberData(member);
  };

  useEffect(() => {
    fetchData().then((res) => setLoading(false));
  }, []);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <PageHeader title="Board Members" />

      <PageWraper>
        {loading ? (
          <CircularLoader />
        ) : (
          <Box className="membersCardWrapper itemCenter">
            <CardSection title="Chairman CDA" data={chairmanData} member />
            <CardSection
              title="Executive Members"
              data={executiveData}
              member
            />
            <CardSection
              title="Non-Executive Members"
              data={memberData}
              member
            />
          </Box>
        )}
        <Flex flexDirection="column" gap="16px" marginTop="35px">
          <Paragraph content="membersPara1" />
          <Paragraph content="membersPara2" />
          <BaseHeading fontSize="md" color="secondary">
            Administration Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Responsible for the overall administration and management of the
            CDA, including human resources, procurement, and general
            administration.
          </BaseText>
          <BaseHeading fontSize="md" color="secondary">
            Engineering Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Handles all engineering-related projects and processes of the CDA,
            including infrastructure development, construction, and maintenance.
          </BaseText>
          <BaseHeading fontSize="md" color="secondary">
            Estate Management Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Deals with the management, maintenance, and development of CDA's
            property and assets, including commercial and residential
            properties.
          </BaseText>
          <BaseHeading fontSize="md" color="secondary">
            Planning & Design Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Responsible for urban planning, land use development, and design of
            infrastructure projects, ensuring sustainable and efficient
            development.
          </BaseText>
          <BaseHeading fontSize="md" color="secondary">
            Environment Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Focuses on environmental conservation, management, and ensuring
            sustainable development practices within the capital territory.
          </BaseText>
          <BaseHeading fontSize="md" color="secondary">
            Technology & Digitalization Wing
          </BaseHeading>
          <BaseText fontSize="md" color="secondary">
            Deals with the effective use of technology and digitalization for
            improving administrative processes and service delivery.
          </BaseText>
          <Paragraph content="membersPara3" />
        </Flex>
      </PageWraper>
    </>
  );
}

export default withTranslation()(BoardMembers);
