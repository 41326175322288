import { Box, GridItem, Text } from "@chakra-ui/react";
import "./index.css";
export default function GridTable({ data, scroll, ...otherprops }) {
  return (
    <Box
      borderRadius="8px"
      border=".2px solid var(--chakra-colors-grey2)"
      overflow={scroll ? "scroll" : "hidden"}
      {...otherprops}
    >
      <Box className="grid-table-main">
        {data?.map((e, i) => (
          <GridItem border=".2px solid var(--chakra-colors-grey2)" key={i}>
            <Box
              w="100%"
              bg="white"
              height="70px"
              alignItems="center"
              display="flex"
              backgroundColor={i % 2 == 0 ? " " : "grey3"}
            >
              <Box
                width={"20px"}
                height={"70px"}
                // boxSizing="border-box"
                padding={"24px"}
                borderRight=".2px solid var(--chakra-colors-grey2)"
                whiteSpace="nowrap"
                display="flex"
                justifyContent="center"
              >
                <Text fontSize="md">{i + 1}</Text>
              </Box>

              <Text fontSize={"md"} padding={"24px"}>{e}</Text>
            </Box>
          </GridItem>
        ))}
      </Box>
    </Box>
  );

  // return (
  //   <Flex>
  //     <Table
  //       display={"flex"}
  //       width={"100%"}
  //       border="1px solid black"
  //       borderRadius="8px"
  //     >
  //       <Tbody border="1px solid black">
  //         {firstColumn.map((item) => (
  //           <Tr>
  //             <Td>{item.number}</Td>
  //             <Td>{item.location}</Td>
  //           </Tr>
  //         ))}
  //       </Tbody>

  //       <Tbody border="1px solid black">
  //         {secondColumn.map((item) => (
  //           <Tr>
  //             <Td>{item.number}</Td>
  //             <Td>{item.location}</Td>
  //           </Tr>
  //         ))}
  //       </Tbody>

  //       <Tbody border="1px solid black">
  //         {thirdColumn.map((item) => (
  //           <Tr>
  //             <Td>{item.number}</Td>
  //             <Td>{item.location}</Td>
  //           </Tr>
  //         ))}
  //       </Tbody>
  //     </Table>
  //   </Flex>
  // );
}
