import React from "react";
import { Tabs, TabList, Tab } from "@chakra-ui/react";

const VerticalTabs = ({ tabsData, onTabChange, ...otherProps }) => {
  return (
    <Tabs
      colorScheme="green"
      onChange={(i) => (onTabChange ? onTabChange(i) : "")}
      orientation="vertical"
      {...otherProps}
    >
      <TabList border="1px solid var(--chakra-colors-grey3)">
        {tabsData.map((tab, index) => (
          <Tab
            key={index}
            color="secondary"
            fontSize="12px"
            width="203px"
            _selected={{
              borderLeft: "3px solid var(--chakra-colors-primary)",
              bgColor: "grey3",
            }}
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>
      {/* <TabPanels>
        {tabsData.map((tab, index) => (
          // <TabPanel key={index} color="secondary" paddingLeft="0px">
          //   {tab.content}
          // </TabPanel>
        ))}
      </TabPanels> */}
    </Tabs>
  );
};

VerticalTabs.defaultProps = {
  tabsData: [],
};

export default VerticalTabs;
