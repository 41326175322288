import { Link } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export default function BaseLink({ children, ...otherprops }) {
  return (
    <Link {...otherprops}>
      <Trans>{children}</Trans>
    </Link>
  );
}
