import { Text } from "@chakra-ui/react";
import { Trans, withTranslation } from "react-i18next";

function BaseText({ children, color, ...otherprops }) {
  return (
    <Text fontWeight="normal" color={color || "secondary"} {...otherprops}>
      <Trans>{children}</Trans>
    </Text>
  );
}

export default withTranslation()(BaseText);
